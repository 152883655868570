import {
  loadMaintenanceMode,
  loadMaintenanceModeClear,
  loadMaintenanceModeError,
  loadMaintenanceModeSuccess,
} from '@innogy/maintenance-mode/services';
import {
  createStateStatusReducer,
  getError,
  getLoading,
  getStatus,
} from '@innogy/utils-state';

export const { reducer, initialState } = createStateStatusReducer({
  updateAction: loadMaintenanceMode,
  clearAction: loadMaintenanceModeClear,
  errorAction: loadMaintenanceModeError,
  successAction: loadMaintenanceModeSuccess,
});

export type MaintenanceModeState = typeof initialState;

export const getMaintenanceModeData = (state: MaintenanceModeState) =>
  state.data;
export const isMaintenanceModeStateLoading = (state: MaintenanceModeState) =>
  getLoading(getStatus(state));
export const hasMaintenanceModeStateError = (state: MaintenanceModeState) =>
  getError(getStatus(state));
