import type {
  ImageFieldValue,
  InnogyComponentRendering,
} from '@innogy/jss-models';
import { getFieldValue, getTreelistEntries } from '@innogy/jss-utils';

export interface Topic {
  offset: {
    left: number;
    top: number;
  };
  modalContent: string;
  title: string;
  slideIndex: number;
  topicIndex: number;
}

export interface Slide {
  id: number;
  image: ImageFieldValue;
  topics: Topic[];
}

/**
 * Utility function used to cast a 'How to read bill' component rendering to the list of slides it contains.
 * @param rendering The top-leven 'How to read bill' component InnogyComponentRendering
 * @returns A formatted list of slides contained in the rendering.
 */
export function castRenderingToSlides(
  rendering: InnogyComponentRendering
): Slide[] {
  const topics = castRenderingToTopics(rendering);
  const slides = getTreelistEntries(rendering.fields, 'Slides', []);
  return slides.map((slide, index) => ({
    id: index,
    image: getFieldValue(slide, 'Image', { src: '', alt: '' }),
    topics: topics.filter((topic) => topic.slideIndex === index),
  }));
}

/**
 * Utility function used to cast a 'How to read bill' component rendering to the list of topics it contains.
 * @param rendering The top-leven 'How to read bill' component InnogyComponentRendering
 * @returns A formatted list of topics contained in the rendering.
 */
export function castRenderingToTopics(rendering: InnogyComponentRendering) {
  const slides = getTreelistEntries(rendering.fields, 'Slides', []);
  return castItemsToTopics(
    slides.map((slide) => getTreelistEntries(slide.fields, 'Topics', []))
  );
}

function castItemsToTopics(topicsMap: InnogyComponentRendering[][]): Topic[] {
  let currentItemIndex = 0;

  return topicsMap.reduce((previous, current, pageIndex) => {
    const append = current.map((item) => {
      currentItemIndex++;
      return castItemRenderingToTopic(item, pageIndex, currentItemIndex);
    });
    const next = [...previous, ...append];
    return next;
  }, [] as Topic[]);
}

function castItemRenderingToTopic(
  itemRendering: InnogyComponentRendering,
  pageIndex = 0,
  topicIndex = 0
): Topic {
  return {
    offset: {
      left: parseInt(getFieldValue(itemRendering, 'OffsetLeft', '0'), 10),
      top: parseInt(getFieldValue(itemRendering, 'OffsetTop', '0'), 10),
    },
    modalContent: getFieldValue(itemRendering, 'ModalContent', ''),
    title: getFieldValue(itemRendering, 'Title', ''),
    slideIndex: pageIndex,
    topicIndex,
  };
}
