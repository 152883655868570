import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { getMarketingModelContract } from '@innogy/eplus/temporary-core-modules';
import { envSegmentToSegmentType } from '@innogy/utils-deprecated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MarkAsSubmittedAction } from 'ngrx-forms';
import { filter, map, mergeMap } from 'rxjs/operators';

import { tariffBreakdownAddressDataSubmitAction } from './tariff-breakdown.actions';
import { tariffBreakdownComponentKey } from './tariff-breakdown.reducer';

@Injectable()
export class TariffBreakdownEffects {
  constructor(
    private readonly actions$: Actions,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {}

  public readonly submitTariffBreakdownForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tariffBreakdownAddressDataSubmitAction),
      map(() => new MarkAsSubmittedAction(tariffBreakdownComponentKey))
    )
  );

  public readonly submitTariffBreakdownFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tariffBreakdownAddressDataSubmitAction),
      filter(
        (action): action is typeof action & { campaignId: string } =>
          action.campaignId !== undefined && action.formIsValid
      ),
      mergeMap(({ addressKey, campaignId, rate, returnSupply }) => {
        return [
          getMarketingModelContract({
            payload: {
              addressKey,
              segment: envSegmentToSegmentType(this.environmentConfig.segment),
              rate,
              returnSupply,
              campaignId,
            },
          }),
        ];
      })
    )
  );
}
