import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent {
  @Input()
  formsControl?: FormState<string>;
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input()
  tooltip = '';
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  stepname?: string;
  @Input()
  fieldname?: string;
  @Input()
  hashValue?: boolean;
  @Input()
  sendValue? = false;
  @Input()
  step?: number;
  @Input()
  preventPaste = false;
  @Input()
  floatingLabel = true;
  @Input()
  skipDirtyCheck = false;

  onPaste(event: ClipboardEvent) {
    if (this.preventPaste) {
      event.preventDefault();
    }
  }
}
