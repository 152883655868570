import { CommonModule } from '@angular/common';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { CommonUiCommonRenderingsModule } from '@innogy/common-ui/common-renderings';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedSitecoreHelpersModule } from '@innogy/shared/sitecore-helpers';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';

const modules = [
  CommonModule,
  JssModule,
  UtilsModule,
  RouterModule,
  TranslateModule,
  UtilsContentsquareModule,
  FontAwesomeModule,
  CommonUiCommonRenderingsModule,
  CommonUiUtilityComponentsModule,
  CommonUiPartialsModule,
  CommonUiSharedModule,
  CoreAnalyticsModule,
  CoreExperienceEditorModule,
  SharedSitecoreHelpersModule,
];

@NgModule({
  exports: modules,
})
export class DefaultComponentModule {}
