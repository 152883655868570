import { Injectable } from '@angular/core';
import type { Gender } from '@essent/common';
import { getCustomerData } from '@innogy/account';
import {
  postLeadForm,
  postLeadFormSuccess,
} from '@innogy/emobility-lead/services';
import {
  getAccountHolderString,
  paymentDetailsFormId,
} from '@innogy/emobility-shared';
import { getHasChargeCards } from '@innogy/emobility/chargecard/store';
import { LocationService } from '@innogy/jss-seo';
import { resetProgressiveForm } from '@innogy/shared/progressive-form';
import type { FormPaymentDetails } from '@innogy/shared/progressive-form/sitecore';
import { ofValidatedSubmitAction } from '@innogy/utils-deprecated';
import { isNotNullish } from '@innogy/utils-rxjs';
import {
  Consents,
  LeadFormAddress,
  LeadFormCustomerInformation,
  LeadFormData,
  LeadFormPaymentDetails,
  OrganizationDetails,
  PersonalDetails,
  ProductSelection,
} from '@integration/emobility-api-models';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResetAction } from 'ngrx-forms';
import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import type { CustomerDetailsFormState } from './lead-form-customer-details.reducer';
import {
  customerDetailsFormId,
  CustomerType,
  CustomerTypeSegmentTypeMap,
} from './lead-form-customer-details.reducer';
import { installationAddressFormId } from './lead-form.address.reducer';
import { leadConsentsFormId } from './lead-form.consents.reducer';
import {
  getProposition,
  getPropositionType,
  selectCampaignId,
  selectCustomerDetailsValue,
  selectLeadFormAddressFormValue,
  selectLeadFormConsentsRootState,
  selectLeadFormConsentsValue,
  selectLeadFormPaymentDetailsValue,
  selectLeadFormTrackingId,
  selectProspectVerificationCode,
  selectSalesPartnerLeadData,
} from './lead-form.selectors';
import { leadFormSelectorKey } from './lead-form.state';

@Injectable()
export class LeadFormSubmitEffects {
  leadFormProposition$ = this.store$
    .select(getProposition)
    .pipe(filter(isNotNullish));
  leadFormCustomerDetails$ = this.store$.select(selectCustomerDetailsValue);
  leadFormAddress$ = this.store$.select(selectLeadFormAddressFormValue);
  leadFormPaymentDetails$ = this.store$.select(
    selectLeadFormPaymentDetailsValue
  );
  leadFormConsents$ = this.store$.select(selectLeadFormConsentsValue);
  customerData$ = this.store$.select(getCustomerData);
  propositionType$ = this.store$.select(getPropositionType);
  campaignId$ = this.store$.select(selectCampaignId);
  salesPartnerLeadData$ = this.store$.select(selectSalesPartnerLeadData);
  verificationCode$ = this.store$.select(selectProspectVerificationCode);
  e2eTrackingId$ = this.store$.select(selectLeadFormTrackingId);

  // a customer can't have charge cards if they are not logged in
  hasChargeCards$ = this.customerData$.pipe(
    mergeMap((customerData) =>
      customerData ? this.store$.select(getHasChargeCards) : of(false)
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly locationService: LocationService
  ) {}

  public readonly submitLeadForm$ = createEffect(() =>
    this.actions$.pipe(
      ofValidatedSubmitAction(leadConsentsFormId),
      concatLatestFrom(() => [
        this.leadFormProposition$,
        this.leadFormCustomerDetails$,
        this.leadFormAddress$,
        this.leadFormPaymentDetails$,
        this.leadFormConsents$,
        this.customerData$,
        this.propositionType$,
        this.hasChargeCards$,
        this.campaignId$,
        this.salesPartnerLeadData$,
        this.verificationCode$,
        this.e2eTrackingId$,
      ]),
      switchMap(
        ([
          _,
          proposition,
          customerDetails,
          address,
          paymentDetails,
          consents,
          customerData,
          propositionType,
          hasChargeCards,
          campaignId,
          salesPartnerLeadData,
          verificationCode,
          e2eTrackingId,
        ]) => [
          postLeadForm({
            payload: LeadFormData.fromPlain({
              verificationCode,
              e2eTrackingId,
              proposition,
              campaignId,
              personalDetails: PersonalDetails.fromPlain({
                salutation: customerDetails.salutation as Gender,
                initials: customerDetails.initials,
                prefix: customerDetails.prefix,
                lastName: customerDetails.lastName,
                dateofBirth: customerDetails.dateOfBirth,
                phoneNumber: getPhoneNumber(customerDetails),
                emailAddress: getEmailAddress(customerDetails),
              }),
              organizationDetails: this.getOrganizationDetails(customerDetails),
              paymentDetails: this.getPaymentDetails(
                propositionType,
                customerDetails,
                paymentDetails
              ),
              installationAddress: LeadFormAddress.fromPlain({
                postalCode: address.installationAddressState.postalCode,
                houseNumber: address.installationAddressState
                  .communicationNumber as number,
                houseNumberAddition:
                  address.installationAddressState.houseNumberAddition,
                street: address.installationAddressState.street,
                city: address.installationAddressState.city,
              }),
              consents: Consents.fromPlain({
                acceptedCallFromAdvisor: consents.acceptedCallFromAdvisor,
                acceptedPaymentConditions: consents.acceptedPaymentConditions,
                acceptedTermsAndConditions: consents.acceptedTermsAndConditions,
                acceptedTariffRateChange: consents.acceptedTariffRateChange,
                acceptedProductMail: consents.acceptedProductMail,
              }),
              correspondenceAddress: address.divergentCorrespondenceAddress
                ? LeadFormAddress.fromPlain({
                    postalCode: address.correspondenceAddressState.postalCode,
                    houseNumber: address.correspondenceAddressState
                      .communicationNumber as number,
                    houseNumberAddition:
                      address.correspondenceAddressState.houseNumberAddition,
                    street: address.correspondenceAddressState.street,
                    city: address.correspondenceAddressState.city,
                  })
                : undefined,
              customerInformation: LeadFormCustomerInformation.fromPlain({
                hasChargeCard: hasChargeCards,
                currentSegmentType: customerData?.customerSegment,
                requestedSegmentType:
                  CustomerTypeSegmentTypeMap[customerDetails.customerType],
              }),
              externalPartnerId: salesPartnerLeadData.externalPartnerId,
              requestContext: salesPartnerLeadData.data,
            }),
          }),
        ]
      )
    )
  );

  public readonly onSuccessLeadFormSubmit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postLeadFormSuccess),
        withLatestFrom(this.store$.select(selectLeadFormConsentsRootState)),
        map(([_, rootState]) => {
          if (rootState.thankYouPage?.href) {
            this.locationService.navigateGeneralLink(
              rootState.thankYouPage.href
            );
          }
        })
      ),
    { dispatch: false }
  );

  public readonly clearLeadFormOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postLeadFormSuccess),
      mergeMap(() => [
        resetProgressiveForm({ formId: leadFormSelectorKey }),
        new ResetAction(leadConsentsFormId),
        new ResetAction(customerDetailsFormId),
        new ResetAction(installationAddressFormId),
        new ResetAction(paymentDetailsFormId),
      ])
    )
  );

  private getPaymentDetails(
    proposition: ProductSelection | undefined,
    customerDetails: CustomerDetailsFormState,
    paymentDetails: FormPaymentDetails
  ) {
    if (proposition !== ProductSelection.CHARGE_CARD) {
      return undefined;
    }

    switch (customerDetails.customerType) {
      case CustomerType.SelfEmployed:
        return LeadFormPaymentDetails.fromPlain<LeadFormPaymentDetails>({
          accountHolder: customerDetails.businessDetails.organizationName,
          iban: paymentDetails.iban,
        });
      case CustomerType.Household:
        return LeadFormPaymentDetails.fromPlain<LeadFormPaymentDetails>({
          accountHolder: getAccountHolderString(
            customerDetails.initials,
            customerDetails.prefix,
            customerDetails.lastName
          ),
          iban: paymentDetails.iban,
        });
      default:
        return undefined;
    }
  }

  private getOrganizationDetails(customerDetails: CustomerDetailsFormState) {
    if (customerDetails.customerType === CustomerType.SelfEmployed) {
      return OrganizationDetails.fromPlain({
        chamberOfCommerceNumber:
          customerDetails.businessDetails.chamberOfCommerceNumber,
        organizationName: customerDetails.businessDetails.organizationName,
        interestedInReimbursement:
          customerDetails.businessDetails.interestedInReimbursement,
      });
    }

    return undefined;
  }
}

export function getEmailAddress(formState: CustomerDetailsFormState) {
  return formState.customerType === CustomerType.Household
    ? formState.emailAddress
    : formState.businessDetails.emailAddress;
}

export function getPhoneNumber(formState: CustomerDetailsFormState) {
  return formState.customerType === CustomerType.Household
    ? formState.phoneNumber
    : formState.businessDetails.phoneNumber;
}
