import type { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import type { BaseActionType } from '@essent/common';
import { SegmentType, getCustomer, getCustomerSuccess } from '@essent/customer';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { PlatformService } from '@innogy/core-platform';
import {
  getIsExperienceEditorActive,
  getRenderingData,
} from '@innogy/jss-integration';
import type { State } from '@innogy/utils-state';
import { WINDOW } from '@innogy/utils-dom';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AccountService } from '../account.service';
import { getLoginRedirectUrl, getLoginUrl } from '../account.utils';
import {
  loadCustomerInfoError,
  loadUserInfo,
  loadUserInfoError,
  loadUserInfoSuccess,
} from './account.actions';
import { getIsLoggedOut } from './account.selectors';

const smeSegmentName = 'zakelijk';

@Injectable()
export class AccountEffects {
  private readonly isLoggedOut$ = this.store$.pipe(select(getIsLoggedOut));

  private readonly isXpEditorActive$ = this.store$.pipe(
    select(getIsExperienceEditorActive)
  );

  private readonly renderingChange$ = this.store$.pipe(getRenderingData);
  public loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfo),
      exhaustMap(() => {
        return this.accountService.loadUserInfo().pipe(
          map((payload) => loadUserInfoSuccess({ payload })),
          catchError((e: unknown) =>
            of(loadUserInfoError(e as BaseActionType<HttpErrorResponse>))
          )
        );
      })
    )
  );

  public loadCustomerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfoSuccess),
      map(({ payload }) => payload.userinfo),
      filter(isNotNullish),
      map((info) =>
        getCustomer({
          payload: info.accountId,
        })
      )
    )
  );

  public redirectToCorrectSegment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getCustomerSuccess),
        map((action) => action.payload.customerSegment),
        withLatestFrom(this.renderingChange$),
        map(([segment, _route]) => this.getNavigationUrl(segment)),
        tap((redirectUrl) => {
          if (redirectUrl != null) {
            this.windowRef.location.assign(redirectUrl);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Navigate to the login page when the user is unauthenticated and should be authenticated
   * This should be a combineLatest because both a page navigation and a customerInfo change should trigger this effect
   */
  public navigateToLoginWhenUnauthenticated$ = createEffect(() =>
    combineLatest([
      this.isLoggedOut$,
      this.isXpEditorActive$,
      this.renderingChange$,
    ]).pipe(
      map(([isLoggedOut, isXpEditorActive, route]) => ({
        isLoggedOut,
        isXpEditorActive,
        route,
        loginUrl: getLoginUrl(this.injector.get(ENVIRONMENT_CONFIG)),
      })),
      filter(({ isXpEditorActive }) => !isXpEditorActive),
      filter(() => this.windowRef != null && this.platformService.isClient()),
      filter(({ isLoggedOut }) => isLoggedOut),
      tap(({ loginUrl }) => {
        this.redirectToLogin(loginUrl);
      }),
      map(() =>
        loadCustomerInfoError({
          payload: {
            name: 'Forbidden',
            message: 'Route requires authentication',
          },
        })
      )
    )
  );

  private redirectToLogin(url?: string) {
    const redirectUrl = getLoginRedirectUrl(this.windowRef.location);

    this.windowRef.location.assign(`${url}?redirect=${redirectUrl}`);
  }

  private getNavigationUrl(segment: `${SegmentType}`) {
    const config = this.injector.get(ENVIRONMENT_CONFIG);
    const urlConsumer = config.navigation.basePathConsumer;
    const urlSME = config.navigation.basePathSME;

    const siteType =
      config.segment === smeSegmentName ? SegmentType.SME : SegmentType.HH;

    const customerType = this.getWebsiteForSegment(segment);

    if (siteType !== customerType) {
      if (customerType === SegmentType.HH) {
        return urlConsumer;
      } else {
        return urlSME;
      }
    }

    return undefined;
  }

  private getWebsiteForSegment(segmentType: `${SegmentType}`) {
    switch (segmentType) {
      case SegmentType.SME:
      case SegmentType.RENT:
      case SegmentType.SPEC:
        return SegmentType.SME;
      case SegmentType.HH:
      case SegmentType.SOHO:
      default:
        return SegmentType.HH;
    }
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<State>,
    private readonly injector: Injector,
    private readonly accountService: AccountService,
    private readonly platformService: PlatformService,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {}
}
// eslint-disable-next-line max-lines
