import type { BAGRegistration } from '@essent/contract';
import type { ScLinkField } from '@innogy/jss-models';
import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const redirectToAlternateFunnelAction = createAction(
  `${BAC_ACTION_PREFIX} Redirect to alternate funnel`,
  props<{ BAGRegistration?: BAGRegistration; redirectUrl: ScLinkField }>()
);

export const markBAGRegistrationAsFaultyAction = createAction(
  `${BAC_ACTION_PREFIX} Mark BAG Registration as faulty`
);

export const resetBAGRegistrationFaultyAction = createAction(
  `${BAC_ACTION_PREFIX} Reset BAG Registration as faulty`
);
