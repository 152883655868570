import type { NavigationExtras, Params, UrlTree } from '@angular/router';
import {
  SITECORE_JSS_MEDIA_PATH,
  SITECORE_MEDIA_PATH,
} from '@innogy/core-models';

export function isAbsoluteUrl(url?: string) {
  if (url === null || url === undefined) {
    return false;
  }
  if (typeof url !== 'string') {
    throw new TypeError('Expected a string');
  }

  return /^[a-z][a-z0-9+.-]*:/.test(url);
}

export function isSitecoreMediaLink(href: string | null) {
  return href === null
    ? false
    : href.includes(SITECORE_MEDIA_PATH) ||
        href.includes(SITECORE_JSS_MEDIA_PATH);
}

/**
 * A path containing queryParams/fragments (e.g. /some/page?some=queryParam#someAnchor) can not be directly
 * passed to Angular routing functions (e.g. `this.router.navigate([path])`). Because the URL will be serialized
 * to '/some/page%3Fsome%3DqueryParam%23someAnchor'. This helper function will extract the queryParams
 * and fragment to a NavigationExtras object, and strip them away from the path.
 */
export function extractNavigationExtrasFromPath(
  pathWithExtras: string,
  queryString = ''
): [string | UrlTree, NavigationExtras | undefined] {
  if (isAbsoluteUrl(pathWithExtras as string)) {
    throw new Error(
      'An absolute URL should not be transformed to a Navigation object, the Angular router should not be used to open this URL.'
    );
  }

  let path;
  let queryParamString = '';
  let fragment;

  if (pathWithExtras.includes('?')) {
    [path, queryParamString, fragment] = pathWithExtras.split(/[?#]/);
  } else {
    [path, fragment] = pathWithExtras.split('#');
  }

  const combinedQueryParams: string[] = [];
  combinedQueryParams.push(...queryParamString.split('&'));
  combinedQueryParams.push(...queryString.split('&'));

  const queryParams: Params = combinedQueryParams.reduce((acc, param) => {
    if (param.trim() === '') {
      return acc;
    }
    const [key, value] = param.split('=');
    return { ...acc, [key]: value };
  }, {});

  const navigationExtras = {
    ...(fragment && { fragment }),
    ...(Object.keys(queryParams).length && { queryParams }),
  };

  return [
    path,
    Object.keys(navigationExtras).length ? navigationExtras : undefined,
  ];
}

export function mergeNavigationExtras(
  ...navigationExtras: (NavigationExtras | undefined)[]
): NavigationExtras | undefined {
  const navigationExtra = navigationExtras.reduce(
    (acc, nextNavigationExtra) => {
      const copy = { ...nextNavigationExtra };

      // Delete any undefined values before merging
      Object.keys(copy).forEach((key) => {
        if (!copy[key as keyof NavigationExtras]) {
          delete copy[key as keyof NavigationExtras];
        }
      });

      return {
        ...acc,
        ...copy,
        queryParams: {
          ...acc?.queryParams,
          ...copy?.queryParams,
        },
        state: {
          ...acc?.state,
          ...copy?.state,
        },
      };
    },
    {}
  );

  // If the resulting queryParams or state is an empty object, delete it
  if (
    navigationExtra?.queryParams &&
    !Object.keys(navigationExtra.queryParams).length
  ) {
    delete navigationExtra.queryParams;
  }

  if (navigationExtra?.state && !Object.keys(navigationExtra.state).length) {
    delete navigationExtra.state;
  }

  // When the resulting navigationExtra object is empty, return undefined
  if (!Object.keys(navigationExtra || {}).length) {
    return undefined;
  }

  return navigationExtra;
}
