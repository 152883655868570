import type { InnogyComponentRendering } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';

import type {
  PostalAddressFormContent,
  PostalAddressFormRendering,
} from '../model/postal-address-form.model';

export function postalAddressFormRenderingToContent(
  rendering: InnogyComponentRendering & { fields: PostalAddressFormRendering }
): PostalAddressFormContent {
  return {
    PostalCodeLabel: getFieldValue(rendering, 'PostalCodeLabel', ''),
    PostalCodePlaceholder: getFieldValue(
      rendering,
      'PostalCodePlaceholder',
      ''
    ),
    PostalCodeRequiredError: getFieldValue(
      rendering,
      'PostalCodeRequiredError',
      ''
    ),
    PostalCodeNotValidError: getFieldValue(
      rendering,
      'PostalCodeFormattingError',
      ''
    ),
    HouseNumberLabel: getFieldValue(rendering, 'HouseNumberLabel', ''),
    HouseNumberPlaceholder: getFieldValue(
      rendering,
      'HouseNumberPlaceholder',
      ''
    ),
    HouseNumberRequiredError: getFieldValue(
      rendering,
      'HouseNumberRequiredError',
      ''
    ),
    HouseNumberNotValidError: getFieldValue(
      rendering,
      'HouseNumberNotValidError',
      ''
    ),
    HouseNumberAdditionLabel: getFieldValue(
      rendering,
      'HouseNumberAdditionLabel',
      ''
    ),
    HouseNumberAdditionPlaceholder: getFieldValue(
      rendering,
      'HouseNumberAdditionPlaceholder',
      ''
    ),
    HouseNumberAdditionMaxLengthError: getFieldValue(
      rendering,
      'HouseNumberAdditionMaxLengthError',
      ''
    ),
    PoboxLabel: getFieldValue(rendering, 'PoboxLabel', ''),
    PoboxRequiredError: getFieldValue(rendering, 'PoboxRequiredError', ''),
  };
}
