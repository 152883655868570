import { Injectable } from '@angular/core';
import { concatLatestFrom, createEffect, ofType, Actions } from '@ngrx/effects';
import { map, type Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import type { HeatpumpsFunnelSettingsInterface } from '@innogy/eplus/models';

import {
  showHeatpumpsQuestionnaireNextQuestionAction,
  submitHeatpumpsQuestionnaireSingleAnswerAction,
  submitHeatpumpsQuestionnaireMultipleAnswerAction,
} from './heatpumps-questionnaire.actions';
import {
  onHeatpumpsFunnelNavigationAction,
  selectHeatpumpsFunnelSettings,
} from '../heatpumps-funnel';
import { selectHeatpumpsCurrentQuestion } from './heatpumps-questionnaire.selectors';
import type { MultipleAnswerQuestion } from './questions';

@Injectable()
export class HeatpumpsQuestionnaireEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}

  handleSingleAnswerSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitHeatpumpsQuestionnaireSingleAnswerAction),
      concatLatestFrom(() => this.store.select(selectHeatpumpsFunnelSettings)),
      map(([action, funnelSettings]) =>
        this.mapNavigateAction(action.answer.target, funnelSettings)
      )
    )
  );

  handleMultipleAnswerSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitHeatpumpsQuestionnaireMultipleAnswerAction),
      concatLatestFrom(() => [
        this.store.select(selectHeatpumpsFunnelSettings),
        this.selectMultipleAnswerQuestion$(),
      ]),
      map(([action, funnelSettings, currentQuestion]) =>
        action.answers.reduce((acc, cur) => acc + cur.value, 0) >=
        currentQuestion.valueNeeded
          ? this.mapNavigateAction(
              currentQuestion.goodAnswer.target,
              funnelSettings
            )
          : this.mapNavigateAction(
              currentQuestion.badAnswer.target,
              funnelSettings
            )
      )
    )
  );

  private mapNavigateAction(
    target: string,
    funnelSettings: HeatpumpsFunnelSettingsInterface
  ) {
    return target === 'order-form'
      ? onHeatpumpsFunnelNavigationAction({
          page: funnelSettings.orderPage,
        })
      : showHeatpumpsQuestionnaireNextQuestionAction({
          next: target,
        });
  }

  private selectMultipleAnswerQuestion$(): Observable<MultipleAnswerQuestion> {
    return this.store
      .select(selectHeatpumpsCurrentQuestion)
      .pipe(
        map((question) =>
          question.type === 'multiple-answer-question'
            ? question
            : (null as any)
        )
      );
  }
}
