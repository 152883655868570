import { Inject, Injectable, Optional } from '@angular/core';
import { API_PUBLIC_PREFIX } from '@innogy/core-config-angular';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { GetTimeslotsResponse } from '../../models/sustainability/sustainability-timeslots.interface';

@Injectable()
export class GetTimeslotsService {
  public readonly apiVersion = '1';
  public readonly endpoint = `${this.apiUrl || ''}/feh/timeslots/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(): Observable<GetTimeslotsResponse> {
    const headers = { Accept: 'application/json' };
    return this.httpClient.get<GetTimeslotsResponse>(this.endpoint, {
      headers,
    });
  }
}
