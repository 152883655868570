import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { mockTextField } from '@innogy/jss-integration';

import type {
  PostalAddressFormContent,
  PostalAddressFormRendering,
} from './model/postal-address-form.model';

export const mockPostalAddressFormRendering: ComponentFields &
  PostalAddressFormRendering = {
  PostalCodeLabel: mockTextField('goat-PostalCodeLabel'),
  PostalCodePlaceholder: mockTextField('goat-PostalCodePlaceholder'),
  PostalCodeRequiredError: mockTextField('goat-PostalCodeRequiredError'),
  PostalCodeNotValidError: mockTextField('goat-PostalCodeNotValidError'),
  PostalCodeExclusion: mockTextField('goat-PostalCodeExclusion'),
  HouseNumberLabel: mockTextField('goat-HouseNumberLabel'),
  HouseNumberPlaceholder: mockTextField('goat-HouseNumberPlaceholder'),
  HouseNumberRequiredError: mockTextField('goat-HouseNumberRequiredError'),
  HouseNumberNotValidError: mockTextField('goat-HouseNumberNotValidError'),
  HouseNumberAdditionLabel: mockTextField('goat-HouseNumberAdditionLabel'),
  HouseNumberAdditionPlaceholder: mockTextField(
    'goat-HouseNumberAdditionPlaceholder'
  ),
  HouseNumberAdditionMaxLengthError: mockTextField(
    'goat-HouseNumberAdditionMaxLengthError'
  ),
} as ComponentFields & PostalAddressFormRendering;

export const mockPostalAddressFormContent: PostalAddressFormContent = {
  PostalCodeLabel: 'goat-PostalCodeLabel',
  PostalCodePlaceholder: 'goat-PostalCodePlaceholder',
  PostalCodeRequiredError: 'goat-PostalCodeRequiredError',
  PostalCodeNotValidError: 'goat-PostalCodeNotValidError',
  PostalCodeExclusion: 'goat-PostalCodeExclusion',
  HouseNumberLabel: 'goat-HouseNumberLabel',
  HouseNumberPlaceholder: 'goat-HouseNumberPlaceholder',
  HouseNumberRequiredError: 'goat-HouseNumberRequiredError',
  HouseNumberNotValidError: 'goat-HouseNumberNotValidError',
  HouseNumberAdditionLabel: 'goat-HouseNumberAdditionLabel',
  HouseNumberAdditionPlaceholder: 'goat-HouseNumberAdditionPlaceholder',
  HouseNumberAdditionMaxLengthError: 'goat-HouseNumberAdditionMaxLengthError',
} as PostalAddressFormContent;
