import type { ActionType } from '@essent/common';
import { Status } from '@essent/common';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { CollectionState } from '@innogy/utils-state';
import {
  createInitialCollectionState,
  updateCollectionState,
} from '@innogy/utils-state';
import type { HttpErrorResponse } from '@angular/common/http';

// TODO: use ApiActions from @essent/common instead when it can be migrated to 14.1.0
export interface ApiActions<TRequest = void, TResponse = void> {
  requestAction: ActionType<TRequest>;
  successAction: ActionType<TResponse>;
  errorAction: ActionType<HttpErrorResponse>;
  clearAction: ActionType<void>;
}

export const createInitialState = <
  TApiResponse
>(): CollectionState<TApiResponse> => createInitialCollectionState();

export const unknownActionId = 'default';

const _generateReducer = <TApiRequest, TApiResponse>(
  apiActions: ApiActions<TApiRequest, TApiResponse>
) =>
  createReducer(
    createInitialState<TApiResponse>(),
    on(apiActions.requestAction, (state, action) =>
      updateCollectionState(state, action.actionId || unknownActionId, {
        status: Status.PENDING,
      })
    ),
    on(apiActions.successAction, (state, action) =>
      updateCollectionState(state, action.actionId || unknownActionId, {
        status: Status.SUCCESS,
        entry: action.payload,
      })
    ),
    on(apiActions.errorAction, (state, action) =>
      updateCollectionState(state, action.actionId || unknownActionId, {
        status: Status.ERROR,
        error: action.payload,
      })
    ),
    on(apiActions.clearAction, (state, action) =>
      updateCollectionState(state, action.actionId || unknownActionId, {
        status: Status.IDLE,
      })
    )
  );

export function generateApiReducer<TApiRequest, TApiResponse>(
  apiActions: ApiActions<TApiRequest, TApiResponse>
) {
  const reducer = _generateReducer<TApiRequest, TApiResponse>(apiActions);
  return (
    state: CollectionState<TApiResponse> = createInitialState(),
    action: Action
  ): CollectionState<TApiResponse> => {
    return reducer(state, action);
  };
}
