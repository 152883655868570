import type { InnogyComponentRendering } from '@innogy/jss-models';

export interface CustomGraphQLRendering {
  fields: {
    data: {
      datasource?: {
        fields: CustomGraphQLFieldValue[];
      };
      contextItem?: {
        fields: CustomGraphQLFieldValue[];
      };
    };
  };
}

export interface CustomGraphQLFieldValue {
  name: string;
  jss: any;
}

// Typeguard
export const isCustomGraphQLRendering = (
  rendering: InnogyComponentRendering | CustomGraphQLRendering
): rendering is CustomGraphQLRendering => {
  const data = (rendering as CustomGraphQLRendering).fields.data;
  return (
    data !== undefined &&
    (data.datasource !== undefined || data.contextItem !== undefined)
  );
};

export interface FlattenedCustomGraphQL {
  fields: {
    [key: string]: any;
  };
  context: {
    [key: string]: any;
  };
}
