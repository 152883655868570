import { createReducer, on } from '@ngrx/store';

import type { MultipleAnswerQuestion } from './questions/multiple-answer-question.interface';
import type { SingleAnswerQuestion } from './questions/single-answer-question.interface';
import { QuestionnaireStructure } from './questions/questionnaire-structure';
import {
  initializeHeatpumpsQuestionnaireAction,
  showHeatpumpsQuestionnaireNextQuestionAction,
  submitHeatpumpsQuestionnaireSingleAnswerAction,
  submitHeatpumpsQuestionnaireMultipleAnswerAction,
  showHeatpumpsQuestionnairePreviousQuestionAction,
} from './heatpumps-questionnaire.actions';

export const heatpumpsQuestionnaireKey = 'questionnaire';

export interface HeatpumpsQuestionnaireState {
  structure: {
    [key: string]: SingleAnswerQuestion | MultipleAnswerQuestion;
  };
  currentQuestion: SingleAnswerQuestion | MultipleAnswerQuestion;
  submittedAnswers: { [key: string]: string };
  history: string[];
}

// Top of the structure = the first question
const firstQuestion = Object.keys(QuestionnaireStructure)[0];

export const initialState: HeatpumpsQuestionnaireState = {
  structure: {},
  currentQuestion: QuestionnaireStructure[firstQuestion],
  submittedAnswers: {},
  history: [firstQuestion],
};

export const heatpumpsQuestionnaireReducer = createReducer(
  initialState,
  on(initializeHeatpumpsQuestionnaireAction, (state) => ({
    ...state,
    structure: QuestionnaireStructure,
    currentQuestion: QuestionnaireStructure[firstQuestion],
  })),
  on(submitHeatpumpsQuestionnaireSingleAnswerAction, (state, props) => ({
    ...state,
    submittedAnswers: {
      ...state.submittedAnswers,
      [state.currentQuestion.apiKey]: props.answer.apiValue || 'invalid',
    },
  })),
  on(submitHeatpumpsQuestionnaireMultipleAnswerAction, (state, props) => ({
    ...state,
    submittedAnswers: {
      ...state.submittedAnswers,
      [state.currentQuestion.apiKey]: props.answers
        .map((answer) => answer.apiValue || 'invalid')
        .join(', '),
    },
  })),
  on(showHeatpumpsQuestionnaireNextQuestionAction, (state, props) => ({
    ...state,
    currentQuestion: state.structure[props.next],
    history: state.history.concat([props.next]),
  })),
  on(showHeatpumpsQuestionnairePreviousQuestionAction, (state) =>
    navigateToPreviousQuestion(state)
  )
);

function navigateToPreviousQuestion(
  state: HeatpumpsQuestionnaireState
): HeatpumpsQuestionnaireState {
  if (state.history.length > 1) {
    const updatedHistory = [...state.history];
    updatedHistory.pop();
    const previousQuestion = updatedHistory[updatedHistory.length - 1];

    return {
      ...state,
      currentQuestion: state.structure[previousQuestion],
      history: updatedHistory,
    };
  } else {
    return { ...state };
  }
}
