import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import {
  isAbsoluteUrl,
  stripQuerystringFromPath,
} from '@innogy/core-routing-utils';

@Injectable({ providedIn: 'root' })
export class ActiveLinkService {
  constructor(
    public readonly router: Router,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}
  doubleSlashesRegex = /\/\//g;

  getCurrentUrlWithoutParams() {
    const currentUrl = stripQuerystringFromPath(this.router.url);
    return this.normalizeUrl(currentUrl);
  }
  normalizeUrl(url: string) {
    return `${url}/`.replace(this.doubleSlashesRegex, '/');
  }

  public isBestActiveCategory<TCategory extends { path?: string }>(
    category: TCategory,
    allCategories: TCategory[]
  ) {
    const currentUrlWithoutParams = this.getCurrentUrlWithoutParams();
    const matchingCategories = allCategories.filter((category) =>
      category.path != null
        ? currentUrlWithoutParams.includes(this.normalizeUrl(category.path))
        : false
    );
    const bestMatchingCategory = matchingCategories.reduce(
      (prev: TCategory | undefined, curr: TCategory): TCategory | undefined =>
        (prev?.path?.length ?? 0) > (curr.path?.length ?? 0) ? prev : curr,
      undefined
    );
    return bestMatchingCategory === category;
  }

  public isActiveLinkInCategory(
    categoryHref: string | undefined,
    nonNormalizedHref: string
  ) {
    const href = this.normalizeUrl(stripQuerystringFromPath(nonNormalizedHref));
    if (categoryHref == null) {
      return false;
    }
    const isRootUrl = this.normalizeUrl(this.config.basePath) === href;

    const currentUrlWithoutParams = this.getCurrentUrlWithoutParams();

    if (isAbsoluteUrl(href) || isRootUrl) {
      return href === currentUrlWithoutParams;
    }

    //strip double slashes
    const categoryPath = this.normalizeUrl(href + categoryHref);
    const matchesCategory = currentUrlWithoutParams.startsWith(categoryPath);
    const matchesCurrentPage = currentUrlWithoutParams.endsWith(href);

    return matchesCategory || matchesCurrentPage;
  }

  private isExactActiveLink(nonNormalizedHref: string) {
    const href = this.normalizeUrl(nonNormalizedHref);
    const isRootUrl = this.normalizeUrl(this.config.basePath) === href;

    const currentUrlWithoutParams = this.getCurrentUrlWithoutParams();

    if (isAbsoluteUrl(href) || isRootUrl) {
      return href === currentUrlWithoutParams;
    }

    return currentUrlWithoutParams === href;
  }

  public isActiveLink(href: string, exactMatch = false) {
    if (!exactMatch) {
      return this.isActiveLinkInCategory('', href);
    } else {
      return this.isExactActiveLink(href);
    }
  }
}
