import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { FilterEntry } from '@innogy/common-ui/utility-components';
import { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import type { StreetAddress } from '@essent/new-customer';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';

import { CommodityOfferContent } from '../commodity-offers.model';

@Component({
  selector: 'wl-mixed-commodity-offer',
  templateUrl: './mixed-commodity-offer.component.html',
  styleUrls: [
    './mixed-commodity-offer.component.ed.scss',
    './mixed-commodity-offer.component.essent.scss',
  ],
})
export class MixedCommodityOfferComponent {
  @Input() content?: CommodityOfferContent;
  @Input() showMemberGetMember = false;
  @Input() consumptionValues?: ConsumptionValuesVM;
  @Input() supplyAddress?: Partial<StreetAddress>;
  @Input()
  set offers(value: Offer[] | undefined) {
    this.segments = value?.map((offer) => ({
      id: offer.offerId,
      value: offer,
      text: offer.durationTitle ?? offer.duration,
    }));
    this.initiallySelectedSegment = this.segments?.[0];
    this.shownOffer = value?.[0];
  }

  @Output() selectOffer = new EventEmitter<Offer>();
  @Output() openMoreInfo = new EventEmitter<Offer>();
  @Output() viewTariffs = new EventEmitter<Offer>();
  @Output() adjustCalculation = new EventEmitter<void>();

  brand = inject(ENVIRONMENT_CONFIG).brand;
  shownOffer?: Offer;
  segments?: FilterEntry<Offer>[];
  initiallySelectedSegment?: FilterEntry<Offer>;

  showOffer(offer: Offer): void {
    this.shownOffer = offer;
  }
}
