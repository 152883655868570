import { Injectable } from '@angular/core';
import { ofFormSubmitAction } from '@innogy/utils-deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import type { HouseType, Residents } from '@essent/estimate-consumption';
import {
  getEstimatedConsumptionByHouseType as getEstimatedConsumptionByHouseTypeAction,
  getEstimatedConsumptionByHouseTypeSuccess,
} from '@essent/estimate-consumption';
import {
  SupplyType,
  expectedConsumptionDefinedAction,
  usageQuestionnaireCloseModalAction,
} from '@innogy/common-ui/sales';
import { filter, mergeMap, map } from 'rxjs';
import { MarkAsUnsubmittedAction } from 'ngrx-forms';

import { SOLAR_PAYBACK_USAGE_ESTIMATE_ID } from './solar-payback.state';
import { selectSolarPaybackUsageEstimateFormState } from './solar-payback.selectors';

@Injectable()
export class SolarPaybackUsageEstimateFormSubmitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  private readonly usageQuestionnaireFormValues$ = this.store$.pipe(
    select(selectSolarPaybackUsageEstimateFormState)
  );

  public readonly submitUsageQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofFormSubmitAction(SOLAR_PAYBACK_USAGE_ESTIMATE_ID),
      concatLatestFrom(() => [this.usageQuestionnaireFormValues$]),
      filter(
        ([_, questionnaireState]) =>
          questionnaireState.isValid && questionnaireState.isSubmitted
      ),
      mergeMap(([_, questionnaireState]) => [
        getEstimatedConsumptionByHouseTypeAction({
          payload: {
            residents: questionnaireState.value.residents as Residents,
            houseType: questionnaireState.value.houseType as HouseType,
          },
          actionId: SOLAR_PAYBACK_USAGE_ESTIMATE_ID,
        }),
        usageQuestionnaireCloseModalAction(),
      ])
    );
  });

  public readonly markUsageQuestionnaireAsUnsubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(SOLAR_PAYBACK_USAGE_ESTIMATE_ID),
      map(() => new MarkAsUnsubmittedAction(SOLAR_PAYBACK_USAGE_ESTIMATE_ID))
    )
  );

  public readonly handleEstimatedConsumptionByHouseTypeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getEstimatedConsumptionByHouseTypeSuccess),
        filter((action) => action.actionId === SOLAR_PAYBACK_USAGE_ESTIMATE_ID),
        map(({ payload: expectedConsumption }) =>
          expectedConsumptionDefinedAction({
            formId: SOLAR_PAYBACK_USAGE_ESTIMATE_ID,
            expectedConsumption,
            supplyType: SupplyType.E_ONLY,
          })
        )
      )
  );
}
