import type { OnDestroy } from '@angular/core';
import { Directive, HostListener, Input } from '@angular/core';
import { getFieldValue } from '@innogy/jss-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TrackFeedbackInfo } from './track-feedback.model';
import { TrackFeedbackService } from './track-feedback.service';

@Directive({
  selector: '[wlTrackFeedback]',
})
export class TrackFeedbackDirective implements OnDestroy {
  @Input()
  trackFeedbackInfo: TrackFeedbackInfo = {};

  private readonly onDestroy$ = new Subject();

  @HostListener('click') onClick() {
    this.triggerAngularTics();
  }

  private triggerAngularTics() {
    const rendering = this.trackFeedbackInfo.rendering;
    if (rendering == null) {
      return;
    }
    const componentName = getFieldValue<string>(
      rendering,
      'componentName',
      'unknown'
    );
    this.trackFeedbackService
      .trackFeedback(componentName, this.trackFeedbackInfo)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackFeedbackService: TrackFeedbackService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
