import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import type { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [
    './checkbox.component.ed.scss',
    './checkbox.component.essent.scss',
  ],
})
export class CheckboxComponent {
  @Input()
  formsControl?: FormState<string> | FormState<boolean>;
  @Input()
  label = '';
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  stepname?: string;
  @Input()
  fieldname?: string;
  @Input()
  tooltip?: string;
  @Input()
  hashValue?: boolean;
  @Input()
  sendValue? = true;
  @Input()
  step?: number;
  @Input()
  showValidation = true;
}
