import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { getSiteContext } from '@innogy/core/analytics';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { Store } from '@ngrx/store';
import { WINDOW } from '@innogy/utils-dom';

@Component({
  selector: 'wl-footer-container[rendering]',
  templateUrl: './footer-container.component.html',
  styleUrls: ['./footer-container.component.scss'],
})
export class FooterContainerComponent implements OnInit {
  @Input() rendering!: InnogyComponentRendering;

  placement$ = getSiteContext(this.store$, this.environmentConfig);
  isEw = false;

  constructor(
    private readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {
    this.isEw = this.environmentConfig.app === 'energiewonen';
  }

  ngOnInit() {
    if (!this.rendering.fields) {
      throw new Error(
        `The Footer on page "${this.windowRef.location.pathname}" is missing a DataSource!`
      );
    }
  }
}
