import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_PREFIX, ConfigModule } from '@innogy/core-config-angular';
import createClient from 'openapi-fetch';

import { API_CLIENT } from './injection-tokens';

const providers = [
  {
    provide: API_CLIENT,
    useFactory: (baseUrl: string) =>
      createClient({
        baseUrl,
        headers: {
          Accept: 'application/json',
        },
      }),
    deps: [API_PREFIX],
  },
];

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers,
})
export class ApiClientModule {}
