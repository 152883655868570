import { Component, HostBinding, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { toScrollAnchorId } from '@innogy/utils-deprecated';

@Component({
  selector: 'wl-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: [
    './rich-text.component.essent.scss',
    './rich-text.component.ed.scss',
    './rich-text.component.energiewonen.scss',
  ],
})
export class RichTextComponent {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  @HostBinding('attr.id') get id() {
    if (this.rendering?.uid) {
      return toScrollAnchorId(this.rendering.uid);
    }
    return '';
  }
}
