import { Status } from '@essent/common';
import type { JssNavItem } from '@innogy/jss-models';
import { loginUrl } from '@innogy/core-settings';
import { select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { getSitecoreContextWithStatus } from './jss.selectors';

/**
 * Solution based on: https://github.com/ngrx/platform/blob/master/docs/store/selectors.md#solution-extracting-a-pipeable-operator
 */
export const getNavSection = (name: string) =>
  pipe(
    getSitecoreContextWithStatus,
    map(({ context, status }) => {
      const navPresent = (context?.mainnavigation?.length ?? 0) > 0;
      const requestPending =
        status === Status.PENDING || status === Status.IDLE;
      const isNavigationAvailable =
        (requestPending || navPresent) && status !== Status.ERROR;

      let navItems: JssNavItem[];
      if (isNavigationAvailable && context.mainnavigation) {
        const section = context.mainnavigation.find(
          (nav) =>
            nav.name != null &&
            nav.name.toLocaleLowerCase() === name.toLowerCase()
        );
        const children =
          section == null || section.children == null ? [] : section.children;
        navItems = children;
      } else {
        navItems = [];
      }

      return {
        isNavigationAvailable,
        navItems,
      };
    })
  );

export const getMainNavigation = getNavSection('home');

export const getTopNavigation = getNavSection('topnavigation');

export const getServiceNavigation = getNavSection('servicenavigation');

export const getServiceNavigationItemsWithCategories = pipe(
  getServiceNavigation,
  map((navigation) => navigation.navItems),
  filter((items) => items != null)
);

export const getServiceNavigationRootName = pipe(
  select(loginUrl),
  map((loginUrl) => loginUrl?.label)
);
