import { Inject, Injectable } from '@angular/core';
import { getBAGRegistration } from '@essent/contract';
import {
  getInstallationAddresses as getInstallationAddressesAction,
  getInstallationAddressesError,
  getInstallationAddressesSuccess,
} from '@essent/installation-address';
import { KEY_PRESS_DEBOUNCE_TIME } from '@innogy/become-a-customer/shared/testing';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { ClientEnvironmentConfig } from '@innogy/core-config-models';
import { ofControlId } from '@innogy/utils-deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  DisableAction,
  EnableAction,
  FocusAction,
  MarkAsDirtyAction,
  SetValueAction,
} from 'ngrx-forms';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import {
  BAC_QUESTIONNAIRE_ID,
  SupplyType,
  expectedConsumptionDefinedAction,
} from '@innogy/common-ui/sales';

import { resetBAGRegistrationFaultyAction } from '../bac/bag-registration';
import {
  calculateFormLoadedAction,
  clearCalculateFormUpdatedAfterAddressCheckAction,
  resetHousenumberExtensionAction,
} from './calculate.actions';
import {
  doubleMeterFormControlId,
  electricityUsageFormControlId,
  electricityUsageNormalFormControlId,
  electricityUsageOffPeakFormControlId,
  gasUsageFormControlId,
  houseNumberAdditionFormControlId,
  houseNumberFormControlId,
  postalCodeFormControlId,
} from './calculate.reducer';
import {
  getCalculateFormFormState,
  getCalculateFormIsDoubleMeter,
} from './calculate.selectors';

@Injectable()
export class CalculateFormEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: ClientEnvironmentConfig
  ) {}

  private readonly formValues$ = this.store$.pipe(
    select(getCalculateFormFormState)
  );

  private readonly isDoubleMeter$ = this.store$.pipe(
    select(getCalculateFormIsDoubleMeter)
  );

  /**
   * When the calculate-form loads the values might be already filled in. This may happen if the user navigates from the offer-overview
   * back to the calcualte page. In this case the address check needs to be performed make the form valid and to fetch all existing
   * house number extensions.
   */
  public getInstallationAddressWhenCalculateFormStateFilled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calculateFormLoadedAction),
        concatLatestFrom(() => [this.formValues$]),
        filter(([_, formState]) => {
          const formControls = formState.controls;

          return (
            formControls.postalCode.isValid &&
            !!formControls.houseNumber &&
            formControls.houseNumber.isValid
          );
        }),
        map(([_, formState]) => {
          const formValues = formState.value;

          return getInstallationAddressesAction({
            payload: {
              postcode: formValues.postalCode || '',
              houseNumber: formValues.houseNumber?.toString() || '',
            },
          });
        })
      )
  );

  /**
   * Split or join usage values based on doubleMeter bool value
   */
  public fillElectricityControlsSingleMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<number>>(SetValueAction.TYPE),
      ofControlId(doubleMeterFormControlId),
      concatLatestFrom(() => [this.isDoubleMeter$]),
      filter(([, isDoubleMeter]) => !isDoubleMeter),
      concatLatestFrom(() => [this.formValues$]),
      mergeMap(([_, state]) => {
        const usageNormal = state.value.electricityUsageNormal as number;
        const usageOffPeak = state.value.electricityUsageOffPeak as number;
        const total = usageNormal + usageOffPeak;
        return [
          new SetValueAction(
            electricityUsageFormControlId,
            Math.floor(total === 0 ? NaN : total)
          ),
        ];
      })
    )
  );

  public fillElectricityControlsDoubleMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<number>>(SetValueAction.TYPE),
      ofControlId(doubleMeterFormControlId),
      concatLatestFrom(() => [this.isDoubleMeter$]),
      filter(([, isDoubleMeter]) => isDoubleMeter),
      concatLatestFrom(() => [this.formValues$]),
      mergeMap(([_, state]) => {
        const usage = state.value.electricityUsage as number;
        // Is usage is invalid, do not set normal and offpeak usage
        if (state.controls.electricityUsage?.isInvalid || usage === 0) {
          return [];
        }

        const normal = usage / 2;
        const offPeak = usage - normal;
        return [
          new SetValueAction(
            electricityUsageNormalFormControlId,
            Math.ceil(normal)
          ),
          new SetValueAction(
            electricityUsageOffPeakFormControlId,
            Math.floor(offPeak)
          ),
        ];
      })
    )
  );

  /**
   * Fetch installation address when address fields are valid en lost focus
   */
  public readonly getBAGRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<any>>(SetValueAction.TYPE),
      filter(() => this.environmentConfig.segment === 'zakelijk'),
      filter(({ controlId }) =>
        [
          postalCodeFormControlId,
          houseNumberFormControlId,
          houseNumberAdditionFormControlId,
        ].includes(controlId)
      ),
      debounceTime(KEY_PRESS_DEBOUNCE_TIME),
      concatLatestFrom(() => [this.formValues$]),
      filter(([, { controls, value }]) => {
        const isPostalValid =
          !!controls.postalCode?.isValid && !!value.postalCode;
        const isHouseNumberValid =
          !!controls.houseNumber?.isValid && !!value.houseNumber;
        const isAdditionValid = controls.houseNumberAddition.isValid;
        return isPostalValid && isHouseNumberValid && isAdditionValid;
      }),

      mergeMap(([_, state]) => {
        return [
          resetBAGRegistrationFaultyAction(),
          getBAGRegistration({
            payload: {
              postcode: state.value.postalCode,
              houseNumber: state.value.houseNumber?.toString() ?? '',
              houseNumberExtension: state.value.houseNumberAddition,
            },
          }),
        ];
      })
    )
  );

  /**
   * Fetch installation address when address fields are valid en lost focus
   */
  public readonly fetchInstallationAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<any>>(SetValueAction.TYPE),
      filter(
        (action) =>
          action.controlId === postalCodeFormControlId ||
          action.controlId === houseNumberFormControlId
      ),
      debounceTime(KEY_PRESS_DEBOUNCE_TIME),
      concatLatestFrom(() => [this.formValues$]),
      filter(([, { controls, value }]) => {
        const isPostalValid =
          !!controls.postalCode?.isValid && !!value.postalCode;
        const isHouseNumberValid =
          !!controls.houseNumber?.isValid && !!value.houseNumber;
        return isPostalValid && isHouseNumberValid;
      }),

      mergeMap(([_, state]) => {
        return [
          getInstallationAddressesAction({
            payload: {
              postcode: state.value.postalCode,
              houseNumber: state.value.houseNumber?.toString() ?? '',
            },
          }),
          clearCalculateFormUpdatedAfterAddressCheckAction(),
          resetHousenumberExtensionAction(),
        ];
      })
    )
  );

  /**
   * Disable extension field when checking address with API
   */
  public disableHouseNumberExtensionField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstallationAddressesAction),
      map(() => new DisableAction(houseNumberAdditionFormControlId))
    )
  );

  /**
   * Enable extension field when API call has a response
   */
  public enableHouseNumberExtensionField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstallationAddressesSuccess, getInstallationAddressesError),
      map(() => new EnableAction(houseNumberAdditionFormControlId))
    )
  );

  public applySingleMeterEstimateToCalculateForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expectedConsumptionDefinedAction),
      filter(
        (expectedConsumption) =>
          expectedConsumption.formId === BAC_QUESTIONNAIRE_ID
      ),
      concatLatestFrom(() => [this.isDoubleMeter$]),
      filter(([, doubleMeter]) => !doubleMeter),
      mergeMap(([expectedConsumption]) => {
        const { supplyType, expectedConsumption: estimate } =
          expectedConsumption;
        return [
          new SetValueAction(
            electricityUsageFormControlId,
            hasElectricityConnection(supplyType)
              ? estimate.electricity.total
              : NaN
          ),
          new SetValueAction(
            gasUsageFormControlId,
            hasGasConnection(supplyType) ? estimate.gas : NaN
          ),
          new FocusAction(electricityUsageFormControlId),
          new FocusAction(gasUsageFormControlId),
          new MarkAsDirtyAction(electricityUsageFormControlId),
          new MarkAsDirtyAction(gasUsageFormControlId),
        ];
      })
    )
  );

  public applyDoubleMeterEstimateToCalculateForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expectedConsumptionDefinedAction),
      filter(
        (expectedConsumption) =>
          expectedConsumption.formId === BAC_QUESTIONNAIRE_ID
      ),
      concatLatestFrom(() => [this.isDoubleMeter$]),
      filter(([, doubleMeter]) => doubleMeter),
      mergeMap(([expectedConsumption]) => {
        const { supplyType, expectedConsumption: estimate } =
          expectedConsumption;
        return [
          new SetValueAction(
            electricityUsageNormalFormControlId,
            hasElectricityConnection(supplyType)
              ? Math.ceil(estimate.electricity.high)
              : NaN
          ),
          new SetValueAction(
            electricityUsageOffPeakFormControlId,
            hasElectricityConnection(supplyType)
              ? Math.floor(estimate.electricity.low)
              : NaN
          ),
          new SetValueAction(
            gasUsageFormControlId,
            hasGasConnection(supplyType) ? estimate.gas : NaN
          ),
          new FocusAction(electricityUsageNormalFormControlId),
          new FocusAction(electricityUsageOffPeakFormControlId),
          new FocusAction(gasUsageFormControlId),
          new MarkAsDirtyAction(electricityUsageNormalFormControlId),
          new MarkAsDirtyAction(electricityUsageOffPeakFormControlId),
          new MarkAsDirtyAction(gasUsageFormControlId),
        ];
      })
    )
  );
}

const hasGasConnection = (supplyType?: SupplyType) =>
  supplyType === SupplyType.E_AND_G || supplyType === SupplyType.G_ONLY;
const hasElectricityConnection = (supplyType?: SupplyType) =>
  supplyType === SupplyType.E_AND_G || supplyType === SupplyType.E_ONLY;
