import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import type { JssNavItem } from '@innogy/jss-models';
import { Setting } from '@innogy/core-settings';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

import { DrilldownNavComponent } from '../shared/drilldown-nav/drilldown-nav.component';

@Component({
  selector: 'wl-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: [
    './sticky-header.component.essent.scss',
    './sticky-header.component.ed.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyHeaderComponent {
  @Input()
  language?: string;

  @Input()
  isLoggedIn?: boolean;

  @Input()
  currentNavItems: JssNavItem[] = [];

  @Input()
  originalNavItems: JssNavItem[] = [];

  @Input()
  isMainNavigationAvailable?: boolean;

  @Input()
  isTopNavigationAvailable?: boolean;

  @Input()
  topNavigationItems: JssNavItem[] = [];

  @Input()
  openNavigation?: Setting<LinkField>;

  @Input()
  isServiceNavigationAvailable?: boolean;

  @Input()
  serviceNavigationTitle?: string;

  @Input()
  serviceNavigationLink?: Setting<LinkField>;

  @Input()
  brandLogoLinkUrl?: string;

  @Input()
  loginUrl?: string;

  @Input()
  loginUrlLabel?: string;

  @Input()
  mainNavigationTitle?: string;

  @Input()
  showCategories?: boolean;

  @ViewChild(DrilldownNavComponent)
  drillDown!: DrilldownNavComponent;

  isServiceNavigationOpen = false;
  isNavigationOpen = false;

  inSubNavigation = false;

  public selectedItem?: JssNavItem = undefined;

  public get showMainNavigationTitle(): boolean {
    return (
      this.isServiceNavigationAvailable === true &&
      this.isTopNavigationAvailable === true &&
      this.isMainNavigationAvailable === true
    );
  }

  selectedNavItem(event: { navItem: JssNavItem; depth: number }) {
    if (!event.navItem.children?.length) {
      this.isNavigationOpen = false;
    }

    this.serviceNavigationTitle = event.navItem.name;
    this.inSubNavigation = true;
  }

  previousNavItem() {
    this.serviceNavigationTitle = undefined;
    this.inSubNavigation = false;
    this.drillDown?.pop();
  }

  onOpenChange(isOpened: boolean) {
    if (isOpened) {
      this.drillDown?.reset();
      this.serviceNavigationTitle = undefined;
      this.inSubNavigation = false;
    }
  }
}
