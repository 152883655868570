import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import type {
  Offer,
  OfferContentIncentive,
} from '@innogy/become-a-customer/shared/interfaces';
import { FunnelSettingsAddOn } from '@innogy/become-a-customer/shared/interfaces';
import type { InnogyComponentRendering } from '@innogy/jss-models';
import type { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wl-horizontal-offer-multiple-cards-carousel',
  templateUrl: './horizontal-offer-multiple-cards-carousel.component.html',
  styleUrls: [
    './horizontal-offer-multiple-cards-carousel.component.essent.scss',
    './horizontal-offer-multiple-cards-carousel.component.ed.scss',
  ],
})
export class HorizontalOfferMultipleCardsCarouselComponent {
  @Input()
  public offers: Offer[] = [];
  @Input()
  public rendering?: InnogyComponentRendering | any;
  @Input()
  public isAddOnChecked?: boolean;
  @Input()
  public addOn?: FunnelSettingsAddOn;
  @Input()
  public hasMemberGetMember = false;
  @Input()
  public moreInfoButtonClick?: (offer: Offer) => () => unknown;

  @Output()
  public addOnToggle = new EventEmitter<boolean>();
  @Output()
  public submitSelectedProposition = new EventEmitter<Offer>();
  @Output()
  public openAomModal = new EventEmitter<Offer>();
  @Output()
  public showMoreIncentiveInfo = new EventEmitter<OfferContentIncentive>();

  private currentSlide = 0;

  @ViewChild('ngbCarousel', { read: NgbCarousel }) carousel?: NgbCarousel;

  trackByOffers(_index: number, item: Offer) {
    return item.offerId;
  }

  selectProposition(offer: Offer) {
    this.submitSelectedProposition.emit(offer);
  }

  setCurrentSlide(slideEvent: NgbSlideEvent) {
    this.currentSlide = Number(slideEvent.current);
  }

  onAddOnToggle(value: boolean) {
    this.addOnToggle.emit(value);
  }

  get isLastOffer() {
    return this.currentSlide === this.offers.length - 1;
  }

  get isFirstOffer() {
    return this.currentSlide === 0;
  }
}
