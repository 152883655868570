import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { JssNavItem } from '@innogy/jss-models';
import { JssRouteBuilderService } from '@innogy/jss-integration';
import type { JssRouteData } from '@innogy/jss-utils';

@Pipe({ name: 'navItemToJssRoute' })
export class NavItemToJssRoutePipe implements PipeTransform {
  constructor(private readonly urlBuilder: JssRouteBuilderService) {}

  transform(navItem: JssNavItem, language?: string): JssRouteData {
    return this.urlBuilder.createRouteData(navItem, language);
  }
}
