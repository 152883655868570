import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  setValue,
  updateGroup,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import type { FormAddress } from '@innogy/common-ui/forms';
import {
  convertCorrespondenceAddressToFormAddress,
  createAddressFormState,
  getAddressValidator,
} from '@innogy/common-ui/forms';
import type { PrefillAction } from '@innogy/shared/forms';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { validateSequential } from '@innogy/utils-deprecated';
import { getCustomerSuccess } from '@essent/customer';
import { getProspectApiActions } from './lead-form.actions';

export const installationAddressFormId = 'installation-address';
export const installationAddressGroupId = `${installationAddressFormId}.installationAddressState`;
export const correspondenceAddressGroupId = `${installationAddressFormId}.correspondenceAddressState`;

export interface LeadAddressState {
  installationAddressState: FormAddress;
  divergentCorrespondenceAddress: boolean;
  correspondenceAddressState: FormAddress;
}

export interface LeadAddressFormState {
  formState: FormGroupState<LeadAddressState>;
}

export interface AddressReducerOptions {
  prefillOnAction?: PrefillAction<FormAddress>;
  submitPrefilledFormIfValid?: boolean;
}

export const getInitialLeadFormAddressState = (
  installationAddressFormState: ReturnType<typeof createAddressFormState>,
  correspondenceAddressState: ReturnType<typeof createAddressFormState>
): LeadAddressFormState => ({
  formState: createFormGroupState(installationAddressFormId, {
    installationAddressState: installationAddressFormState.initialAddressState,
    divergentCorrespondenceAddress: false,
    correspondenceAddressState: correspondenceAddressState.initialAddressState,
  }),
});

export const validateLeadFormAddressForm = (state: LeadAddressFormState) =>
  updateGroup<LeadAddressState>({
    installationAddressState: updateGroup<FormAddress>(
      getAddressValidator(state.formState.controls.installationAddressState)
    ),
    correspondenceAddressState: updateGroup<FormAddress>(
      state.formState.value.divergentCorrespondenceAddress
        ? getAddressValidator(
            state.formState.controls.correspondenceAddressState
          )
        : {
            postalCode: validateSequential(),
            addressType: validateSequential(),
            city: validateSequential(),
            communicationNumber: validateSequential(),
            countryCode: validateSequential(),
            houseNumberAddition: validateSequential(),
            street: validateSequential(),
          }
    ),
  })(state.formState);

const _reducer = (
  installationAddressFormState: ReturnType<typeof createAddressFormState>,
  correspondenceAddressState: ReturnType<typeof createAddressFormState>
) =>
  createReducer(
    getInitialLeadFormAddressState(
      installationAddressFormState,
      correspondenceAddressState
    ),
    onNgrxForms(),
    on(getCustomerSuccess, (state, action) => ({
      ...state,
      formState: setValue<LeadAddressState>({
        ...state.formState.value,
        installationAddressState: {
          ...state.formState.value.installationAddressState,
          ...convertCorrespondenceAddressToFormAddress(
            action.payload.correspondence?.address,
            false
          ),
        },
      })(state.formState),
    })),
    on(getProspectApiActions.successAction, (state, action) => ({
      ...state,
      formState: setValue<LeadAddressState>({
        ...state.formState.value,
        installationAddressState: {
          ...state.formState.value.installationAddressState,
          ...convertCorrespondenceAddressToFormAddress(
            {
              street: action.payload.installationAddress?.street ?? '',
              city: action.payload.installationAddress?.city ?? '',
              postcode: action.payload.installationAddress?.postalCode ?? '',
              houseNumber:
                action.payload.installationAddress?.houseNumber ?? '',
              houseNumberExtension:
                action.payload.installationAddress?.houseNumberAddition ?? '',
              countryCode: '',
            },
            false
          ),
        },
      })(state.formState),
    })),
    installationAddressFormState.onAddressFormAction(
      (state) => state.formState.controls.installationAddressState
    ),
    correspondenceAddressState.onAddressFormAction(
      (state) => state.formState.controls.correspondenceAddressState
    )
  );

const wrappedReducer = (
  installationAddressFormState: ReturnType<typeof createAddressFormState>,
  correspondenceAddressState: ReturnType<typeof createAddressFormState>
) =>
  wrapReducerWithFormStateUpdate(
    _reducer(installationAddressFormState, correspondenceAddressState),
    (state) => state.formState,
    (_, state) => validateLeadFormAddressForm(state)
  );

export function createLeadFormAddressReducer(formId: string) {
  const installationAddressFormState = createAddressFormState(formId);
  const correspondenceAddressFormState = createAddressFormState(formId);

  const _wrappedReducer = wrappedReducer(
    installationAddressFormState,
    correspondenceAddressFormState
  );

  return (
    state = getInitialLeadFormAddressState(
      installationAddressFormState,
      correspondenceAddressFormState
    ),
    action: Action
  ): LeadAddressFormState => {
    return _wrappedReducer(state, action);
  };
}
