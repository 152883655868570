import { Status } from '@essent/common';
import type { PricingBreakdownComponentCommodity } from '@innogy/common-ui/products';
import { analyticsHash } from '@innogy/core/analytics';
import { getFieldValue } from '@innogy/jss-utils';
import type {
  CommodityBreakdown,
  TariffBreakdownComponentRendering,
  TariffBreakdownComponentViewModel,
} from '@innogy/shared/tariff-breakdown/models';
import type { GetTariffsResponse } from '@integration/offerstore-api-models';
import { createSelector } from '@ngrx/store';
import {
  TariffType,
  TariffZoneElectricity,
  TariffZoneGas,
} from '@innogy/become-a-customer/shared/interfaces';

import { tariffBreakdownComponentKey } from './tariff-breakdown.reducer';
import { tariffBreakdownFeature } from './tariff-breakdown.state';
import {
  getCommodityByCode,
  GAS_PRODUCT_CODE,
  ELECTRICITY_PRODUCT_CODE,
  getMonthlyPriceById,
  FIXED_DELIVERY_COSTS_ID,
  castPriceGroups,
} from './tariff-breakdown.helpers';

const selectTariffBreakdownState = createSelector(
  tariffBreakdownFeature,
  (state) => state[tariffBreakdownComponentKey]
);

export const selectTariffBreakdownViewModel = (
  rendering: TariffBreakdownComponentRendering
) =>
  createSelector(
    selectTariffBreakdownState,
    ({ formState, tariffBreakdown }) =>
      ({
        formState,
        isLoading: tariffBreakdown.status === Status.PENDING,
        tariffBreakdown: tariffBreakdownToVM(tariffBreakdown.data, rendering),
        error: tariffBreakdown.error,
      } as TariffBreakdownComponentViewModel)
  );

export const selectTariffBreakdownFormState = createSelector(
  selectTariffBreakdownState,
  (state) => state.formState
);

export const selectTariffBreakdownAnalyticsPayload = createSelector(
  selectTariffBreakdownFormState,
  (formState) => ({
    postalCode: analyticsHash(formState.value.postalCode),
  })
);

function tariffBreakdownToVM(
  data: GetTariffsResponse | undefined,
  rendering: TariffBreakdownComponentRendering
): CommodityBreakdown[] | undefined {
  // If no input is defined, return undefined
  if (!data) {
    return undefined;
  }

  const { products } = data;
  const gas = getCommodityByCode(products, GAS_PRODUCT_CODE);
  const electricity = getCommodityByCode(products, ELECTRICITY_PRODUCT_CODE);

  // If no gas or electricity commodity can be found, return undefined
  if (!products || !gas || !electricity) {
    return undefined;
  }

  /**
   * We have to extract part of the provided Electricity response as it is split into multiple groups of usage.
   * Only the consumption price of the first two zones are required. After that, we cast these to a VM compliant
   * value and add the delivery fees in both cases.
   */
  const getElectricityCommodityBreakdown = () => {
    const usageTariffsE: PricingBreakdownComponentCommodity[] = [];

    electricity.consumptionPrices
      .filter(
        (price) =>
          price.description.includes(TariffZoneElectricity.Zone1) ||
          price.description.includes(TariffZoneElectricity.Zone2)
      )
      .forEach((price) => {
        let label;
        const taxZone = price.description.match(/\((.*?)\)/);

        if (price.description.includes(TariffType.Normal)) {
          label = getFieldValue(
            rendering,
            'ElectricityCommodityDefaultTitle',
            ''
          );
        } else if (price.description.includes(TariffType.Low)) {
          label = getFieldValue(rendering, 'ElectricityCommodityLowTitle', '');
        } else {
          label = getFieldValue(rendering, 'ElectricityCommodityTitle', '');
        }

        usageTariffsE.push({
          icon: 'plug',
          label,
          price: price.amount,
          suffix: 'per kWh',
          emphasize: true,
          // The taxzone regex matches both the text including the parentheses (first match) and the text
          // between the parentheses (second match)
          description: !taxZone ? '' : taxZone[1],
        });
      });

    const deliveryTariffE: PricingBreakdownComponentCommodity = {
      label: getFieldValue(rendering, 'DeliveryFees', ''),
      price: getMonthlyPriceById(
        electricity.priceGroups[0].prices,
        FIXED_DELIVERY_COSTS_ID
      ),
      suffix: 'per mnd',
      emphasize: false,
    };

    return [...usageTariffsE, deliveryTariffE];
  };

  // VM Breakdown for the 'electricity' category
  const electricityCommodityBreakdown: CommodityBreakdown = {
    commodityType: 'electricity',
    mainBreakdown: getElectricityCommodityBreakdown(),
    priceGroups: castPriceGroups(electricity.priceGroups),
  };

  // VM Breakdown for the 'gas' category
  const gasCommodityBreakdown: CommodityBreakdown = {
    commodityType: 'gas',
    mainBreakdown: [
      {
        icon: 'fire-flame',
        label: getFieldValue(rendering, 'GasCommodityTitle', ''),
        description: `${TariffZoneGas.Zone1} m3`,
        price: gas.consumptionPrices[0].amount,
        suffix: 'per m³',
        emphasize: true,
      },
      {
        icon: 'fire-flame',
        label: getFieldValue(rendering, 'GasCommodityTitle', ''),
        description: `${TariffZoneGas.Zone2} m3`,
        price: gas.consumptionPrices[1].amount,
        suffix: 'per m³',
        emphasize: true,
      },
      {
        label: getFieldValue(rendering, 'DeliveryFees', ''),
        price: getMonthlyPriceById(
          gas.priceGroups[0].prices,
          FIXED_DELIVERY_COSTS_ID
        ),
        suffix: 'per mnd',
        emphasize: false,
      },
    ],
    priceGroups: castPriceGroups(gas.priceGroups),
  };

  // VM breakdown for the 'misc' category
  const miscCommodityBreakdown: CommodityBreakdown = {
    commodityType: 'misc',
    mainBreakdown: [
      {
        icon: 'file',
        label: getFieldValue(rendering, 'MiscCommodityTitle', ''),
        price: 0,
        emphasize: true,
      },
      {
        label: getFieldValue(rendering, 'MiscGeneralLabel', ''),
        price: 0,
        emphasize: false,
      },
    ],
    priceGroups: rendering.fields.MiscCommodities.map((miscCommodity) => ({
      description: getFieldValue(miscCommodity.fields, 'GroupName', ''),
      id: 'mock',
      totalPrice: {
        expectedMonthlyAmount: 0,
        expectedYearlyAmount: 0,
      },
      prices: miscCommodity.fields.Prices.map((price) => ({
        description: getFieldValue(price.fields, 'Label', ''),
        unitPrice: getFieldValue(price.fields, 'Price', 0),
        expectedMonthlyAmount: getFieldValue(price.fields, 'Price', 0),
        expectedYearlyAmount: getFieldValue(price.fields, 'Price', 0) * 12,
        id: 'mock',
        unit: getFieldValue(price.fields, 'Unit', ''),
      })),
    })),
  };

  return [
    electricityCommodityBreakdown,
    gasCommodityBreakdown,
    miscCommodityBreakdown,
  ];
}
