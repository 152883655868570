import { ErrorHandler, Inject, Injectable, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotFound, ServerError } from '@innogy/core-models';
import { bufferIfOp } from '@innogy/utils-rxjs';
import { selectCurrentRoute } from '@innogy/utils-state';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { BehaviorSubject, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import type { JssState } from '../jss-state.model';
import { JssService } from '../jss/jss.service';
import * as actions from './jss-route.actions';
import { getServerRoute } from './jss.selectors';

@Injectable()
export class JssRouteEffects {
  private readonly isFirstNavEndedSubject$ = new BehaviorSubject<boolean>(
    false
  );

  currentRoute$ = this.store.select(selectCurrentRoute);
  routeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.JssStateChangeRoute>(
        actions.JssStateActionTypes.CHANGE_ROUTE
      ),
      withLatestFrom(this.store.select(getServerRoute)),
      switchMap(
        ([
          {
            payload: { route, language, extraQueryParams },
          },
          currentRoute,
        ]) => {
          return this.jssService
            .changeRoute(route, language, extraQueryParams, currentRoute)
            .pipe(
              map((jssState) => {
                if (jssState.routeFetchError || !jssState?.sitecore?.route) {
                  return new actions.JssStateActiveErrorRoute({
                    route: this.getNotFoundUrl(jssState),
                    statusCode: 404,
                    language,
                  });
                }
                return new actions.JssStateUpdate(jssState);
              }),
              catchError((unknownError: unknown) => {
                const error = unknownError as any;
                const errorRoute =
                  error.status >= 400 && error.status < 500
                    ? this.getNotFoundUrl(error.data)
                    : ServerError;

                return of(
                  new actions.JssStateError(error),
                  new actions.JssStateActiveErrorRoute({
                    route: errorRoute,
                    statusCode: error.status,
                    language,
                  })
                );
              })
            );
        }
      )
    )
  );

  updateHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.JssStateChangeRoute>(
        actions.JssStateActionTypes.CHANGE_ROUTE
      ),
      concatLatestFrom(() => this.currentRoute$),
      filter(([_, route]) => route?.queryParams != null),
      map(([_, route]) => new actions.JssStateUpdateHistory(route.queryParams))
    )
  );

  /**
   * This is effect and action is intended for error routes (not found, server error etc). skipLocationChange
   * makes it so the Error or NotFound component replaces the error route component without changing the URL.
   * Without this you would go from Valid Page -> Error Page -> Error message page. Navigating back would bring
   * you to the Error Page which in turn sends you to the error message page etc.
   */
  navigateToErrorRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.JssStateActiveErrorRoute>(
          actions.JssStateActionTypes.ACTIVATE_ERROR_ROUTE
        ),
        bufferIfOp(this.isFirstNavEndedSubject$.pipe(map((x) => !x))),
        tap((action) => {
          if (this.response) {
            this.response.status(action.payload.statusCode);
          }
        }),
        tap((action) => {
          return this.router.navigate([action.payload.route], {
            skipLocationChange: true,
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  fatalError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.JssStateFatalError>(
          actions.JssStateActionTypes.FATAL_ERROR
        ),
        tap(() => {
          if (this.response) {
            this.response.status(500);
          }
        }),
        tap((action) => {
          this.errorHandler.handleError(action.payload.error);

          const errorRoute = action.payload.route || ServerError;
          this.router.navigate([errorRoute], {
            skipLocationChange: true,
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  /**
   * Get the not found url from sitecore jss state, if not given use default
   * @param jssState
   */
  private getNotFoundUrl(jssState?: JssState | null): string {
    return jssState?.sitecore?.context?.redirectUrl || NotFound;
  }

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly jssService: JssService,
    private readonly errorHandler: ErrorHandler,
    private readonly store: Store<any>,
    @Optional() @Inject(RESPONSE) protected response: Response
  ) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        first()
      )
      .subscribe((_) => this.isFirstNavEndedSubject$.next(true));
  }
}
