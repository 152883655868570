import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@innogy/core-ngrx';
import { Store } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';
import { of, type Observable } from 'rxjs';

import { getProspect } from './lead-form.actions';
import { selectIsLoadingProspect } from './lead-form.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProspectGuard extends BaseGuard {
  protected isStateLoading(input: GuardInput): Observable<boolean> {
    const verificationCode =
      input.activatedRoute.queryParams?.['verification-code'];
    if (!verificationCode) {
      return of(false);
    }

    return this.store$.select(selectIsLoadingProspect);
  }

  protected getDispatchActions(input: GuardInput) {
    const verificationCode =
      input.activatedRoute.queryParams?.['verification-code'];
    if (verificationCode) {
      return getProspect({
        verificationCode,
      });
    }
    return [];
  }

  constructor(
    private readonly store$: Store<any>,
    protected readonly injector: Injector
  ) {
    super(injector);
  }
}
