/* eslint-disable @typescript-eslint/member-ordering */
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { JssStateMetaTitleUpdated } from '@innogy/jss-integration';
import type { LinkFieldValue } from '@innogy/jss-models';
import { getSettingsValue } from '@innogy/core-settings';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wl-not-found-container',
  templateUrl: './not-found-container.component.html',
  styleUrls: [],
})
export class NotFoundContainerComponent implements OnInit, OnDestroy {
  constructor(
    private readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {}

  private readonly errorCodeNotFoundSelector = () =>
    getSettingsValue<LinkFieldValue>('errorCodes', '404');
  private readonly errorCodeNotFound$ = this.store$.pipe(
    select(this.errorCodeNotFoundSelector())
  );
  private readonly onDestroy$ = new Subject();

  ngOnInit() {
    this.errorCodeNotFound$
      .pipe(
        filter((val) => val !== null),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (errorName) =>
          errorName &&
          this.store$.dispatch(
            new JssStateMetaTitleUpdated(errorName.toString())
          )
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get brand() {
    return this.environmentConfig.brand;
  }
}
