import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { snakeCase, toHttpParams } from '@essent/common';
import { API_PREFIX } from '@innogy/core-config-angular';
import type { Observable } from 'rxjs';

import type {
  GetProductsRequestQuery,
  GetProductsRequestResponse,
} from '../../models';

@Injectable()
export class GetStoreProductsService
  implements BaseService<GetProductsRequestQuery, GetProductsRequestResponse>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${this.apiUrl || ''}/eplus/store/products/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: GetProductsRequestQuery
  ): Observable<GetProductsRequestResponse> {
    const headers = { Accept: 'application/json' };
    const params = toHttpParams(snakeCase(payload));

    return this.httpClient.get<GetProductsRequestResponse>(this.endpoint, {
      headers,
      params,
    });
  }
}
