import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Status } from '@essent/common';
import { EnergyType } from '@essent/new-customer';
import {
  getMockOffer,
  getMockOfferOverview,
  mockConsumptionValues,
} from '@innogy/become-a-customer/offer';
import type {
  ConsumptionValuesVM,
  Offer,
  OfferOverview,
} from '@innogy/become-a-customer/shared/interfaces';
import {
  getConsumptionValuesVM,
  getElectricityOverview,
  getGasOverview,
  getPropositionDuration,
  getPropositionOffer,
  getPropositionOfferStatus,
  initOrderAction,
  setBACFunnelStepAction,
  showAomModalAction,
} from '@innogy/become-a-customer/state';
import { getIsExperienceEditorActive } from '@innogy/jss-integration';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';
import { Store } from '@ngrx/store';
import { concatMap, filter } from 'rxjs/operators';

@Component({
  selector: 'wl-order-container',
  templateUrl: './order-container.component.html',
  styleUrls: [
    './order-container.component.essent.scss',
    './order-container.component.ed.scss',
  ],
})
export class OrderContainerComponent implements OnInit {
  @Input()
  rendering?: InnogyComponentRendering;

  offerStatus$ = this.store$.select(getPropositionOfferStatus);
  duration$ = this.store$.select(getPropositionDuration);
  consumptionValuesVM$ = this.store$.select(getConsumptionValuesVM);
  electricityOverview$ = this.store$.select(getElectricityOverview);
  gasOverview$ = this.store$.select(getGasOverview);
  xpEditorActive$ = this.store$.select(getIsExperienceEditorActive);

  outputs = {
    showTarifs: ($event: Offer) => this.openAomModal($event),
  };

  constructor(private readonly store$: Store) {}

  get xpMockOrder(): {
    offer: Offer;
    consumptionValuesVM: ConsumptionValuesVM;
    durationTitle: string;
    electricityOverview: OfferOverview;
    gasOverview: OfferOverview;
  } {
    return {
      offer: getMockOffer(),
      consumptionValuesVM: mockConsumptionValues,
      durationTitle: 'Maandelijks opzegbaar',
      electricityOverview: getMockOfferOverview(EnergyType.ELECTRICITY),
      gasOverview: getMockOfferOverview(EnergyType.GAS),
    };
  }

  get offer$() {
    return this.offerStatus$.pipe(
      filter((status) => status === Status.SUCCESS),
      concatMap((_) => this.store$.select(getPropositionOffer))
    );
  }

  get orderOverviewBottomMobile() {
    return getFieldValue<boolean>(
      this.rendering,
      'OrderOverviewBottomMobile',
      false
    );
  }

  get orderOverviewSticky() {
    return getFieldValue<boolean>(this.rendering, 'OrderOverviewSticky', false);
  }

  ngOnInit(): void {
    this.dispatchSetFunnelStep();
    this.store$.dispatch(initOrderAction());
  }

  openAomModal(offer: Offer) {
    this.store$.dispatch(
      showAomModalAction({
        offer,
        showFooter: false,
        rendering: this.rendering,
      })
    );
  }

  private dispatchSetFunnelStep() {
    this.store$.dispatch(setBACFunnelStepAction({ step: 'Order' }));
  }
}
