<div class="row">
  <div class="col-sm-12">
    <ng-container *ngIf="errorItem && rendering">
      <ng-container *ngIf="errorItem.fields as fields">
        <ng-container
          *ngIf="
            errorItem.fields.ErrorComponentStyle?.fields.value?.value ===
              'information-block';
            else infoCard
          "
        >
          <wl-information-block
            [wlAutomationId]="automationId + '-functional-error'"
            class="mb-3 d-block"
            [message]="fields.ErrorMessage"
            [title]="fields.ErrorTitle"
            [rendering]="rendering"
            [linedTitle]="false"
            wlOpenModal
            [modalRendering]="errorItem"
            [enabled]="!!errorItem?.fields?.ModalMessage?.value"
            [wlTrackError]="{
              rendering: rendering,
              description: fields.ErrorTitle,
              type: AnalyticsActionTypes.SYSTEM_ERROR
            }"
          >
          </wl-information-block>
        </ng-container>
        <ng-template #infoCard>
          <wl-info-card
            [wlAutomationId]="automationId + '-functional-error'"
            class="mb-3 d-block"
            [backgroundGrey]="backgroundGrey"
            [shadow]="shadow"
            [message]="fields.ErrorMessage"
            [title]="fields.ErrorTitle"
            [type]="fields.ErrorIcon?.fields?.value?.value"
            [buttonType]="fields.ButtonStyle?.fields?.value?.value"
            [buttonLink]="fields.ErrorButtonLink"
            [rendering]="rendering"
            wlOpenModal
            [modalRendering]="errorItem"
            [enabled]="!!errorItem?.fields?.ModalMessage?.value"
            [wlTrackError]="{
              rendering: rendering,
              description: fields.ErrorTitle,
              type: AnalyticsActionTypes.SYSTEM_ERROR
            }"
            [trackImpressionName]="analyticsImpressionName"
          >
          </wl-info-card>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
