import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { InnogyComponentRendering } from '@innogy/jss-models';
import { createAction, props } from '@ngrx/store';

import { BAC_MODAL_PREFIX } from '../constants';

export const showAomModalAction = createAction(
  `${BAC_MODAL_PREFIX} Show AOM modal`,
  props<{
    offer: Offer;
    showFooter: boolean;
    rendering?: InnogyComponentRendering;
  }>()
);
