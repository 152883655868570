import { Inject, Injectable } from '@angular/core';
import { JSSConfig } from '@innogy/core-config-models';
import { JSS_CONFIG } from '@innogy/core-config-angular';
import type { JssNavItem, JssRouteData } from '@innogy/jss-models';
import { JssRoute } from '@innogy/jss-models';
import { isAbsoluteUrl } from '@innogy/core-routing-utils';

@Injectable()
export class JssRouteBuilderService {
  constructor(@Inject(JSS_CONFIG) private readonly config: JSSConfig) {}

  buildRouteUrl(route: JssRoute) {
    let url = route.serverRoute || this.config.defaultServerRoute || '/';

    if (!route.language) {
      route.language = this.config.defaultLanguage;
    }

    if (!isAbsoluteUrl(url) && !url.startsWith('/')) {
      url = `/${url}`;
    }

    // We don't want to use the language in the url for now
    // Otherwise `/${route.language}${route.serverRoute}` should be returned
    return url;
  }

  createRouteData(navItem: JssNavItem, language?: string): JssRouteData {
    const jssRoute = new JssRoute();
    jssRoute.language = language;
    jssRoute.serverRoute = navItem.path;
    const url = this.buildRouteUrl(jssRoute);

    return {
      text: navItem.name != null ? navItem.name : '',
      href: url,
    };
  }

  /**
   * Parses the language and server route from the given url.
   *
   * @example
   * const route = new JssRouteBuilderService().parseRouteUrl(["en", "Saving-Energy"]);
   *
   * @param {string[]} url The url segments from {@link UrlSegment}.
   * @returns {JssRoute} Contains all the information concerning the route.
   */
  parseRouteUrl(url: string[]): JssRoute {
    const route = new JssRoute();
    route.language = this.config.defaultLanguage;
    route.serverRoute = this.config.defaultServerRoute;

    if (url.length === 0) {
      return route;
    }

    const languageRegex = /^[a-zA-Z]{2}(-[a-zA-Z]{2})?$/;
    const languageSegment = url[0].toString();

    if (languageSegment.match(languageRegex)) {
      route.language = languageSegment;
      route.serverRoute =
        url.length > 1
          ? url.slice(1).join('/')
          : this.config.defaultServerRoute;
    } else {
      route.serverRoute = url.join('/');
    }
    if (!route.serverRoute || !route.serverRoute.startsWith('/')) {
      route.serverRoute = '/' + route.serverRoute;
    }

    return route;
  }
}
