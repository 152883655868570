import { Component, Input } from '@angular/core';
import type { SimpleProgressiveFormCardContent } from '@innogy/common-ui/progressive-forms';
import type { InnogyComponentRendering } from '@innogy/jss-models';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import type { KeyValue } from 'ngrx-forms';

@Component({
  selector: 'wl-orderfulfillment-step',
  template: '',
})
export class OrderFulfillmentStepComponent<TFormValues extends KeyValue> {
  @Input()
  progressiveFormState!: ProgressiveFormGroupState<TFormValues> | null;
  @Input() rendering?: InnogyComponentRendering | any;
  @Input() stepNumber?: number;

  progressiveFormCardContent?: SimpleProgressiveFormCardContent;

  public get formState() {
    return this.progressiveFormState?.formState;
  }

  public get fields() {
    return this.rendering?.fields;
  }
}
