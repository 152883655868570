import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { ImageSettings } from '@innogy/common-ui/utility-components';
import { InnogyComponentRendering } from '@innogy/jss-models';
import type { ButtonTheme } from '@innogy/common-ui/partials';

import type { BasicContentVariant } from '../../basic-content-settings.model';
import {
  BasicContentValues,
  BasicContentSettings,
} from '../../basic-content-settings.model';
import { ComposableCardConfig } from '../../../composables/card/card.model';

@Component({
  selector: 'wl-basic-content-variant-media-card',
  templateUrl: './basic-content-variant-media-card.component.html',
  styleUrls: ['./basic-content-variant-media-card.component.scss'],
})
export class BasicContentVariantMediaCardComponent implements OnInit {
  @Input() content?: BasicContentValues;
  @Input() settings?: BasicContentSettings;
  @Input() context?: ColumnContext;
  @Input() image?: ImageSettings;
  @Input() rendering?: InnogyComponentRendering;
  @Input() cardConfig?: ComposableCardConfig;

  primaryButtonTheme: ButtonTheme = 'secondary';

  public displayImage(variant: BasicContentVariant, image?: ImageSettings) {
    return variant !== 'minimal' && !!image?.image?.src;
  }

  private setPrimaryButtonTheme() {
    const buttonStyleString = this.content?.buttons?.primary?.style;
    const style = buttonStyleString?.includes('primary')
      ? 'primary'
      : 'secondary';
    this.primaryButtonTheme = style;
  }

  ngOnInit() {
    this.setPrimaryButtonTheme();
  }
}
