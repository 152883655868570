import type { AfterViewInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { IAdvizeService } from '@innogy/core/chatting';
import { InnogyComponentRendering } from '@innogy/jss-models';

@Component({
  selector: 'wl-chatbot-iadvize-start-screen',
  templateUrl: './chatbot-iadvize-start-screen.component.html',
})
export class ChatbotIadvizeStartScreenComponent implements AfterViewInit {
  @Input() rendering?: InnogyComponentRendering;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig,
    private readonly iAdvizeService: IAdvizeService
  ) {}

  ngAfterViewInit() {
    // This call is a workaround to ensure that the current element can be picked up
    // by iAdvize, even if the chatbot element not present at page-load.
    // iAdvize only scans for 'Fixed Buttons' on pageload, this call ensures our element will be discovered
    // as we trigger a re-scan of the page.
    // see: https://docs.iadvize.dev/use-cases/visitor-experience/integrating-custom-buttons-into-your-site
    if (this.isEnabled) {
      this.iAdvizeService.scanCurrentPage();
    }
  }

  get isEnabled() {
    return this.config.iAdvizeChatbot.enabled;
  }
}
