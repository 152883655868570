import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { shouldShowPricesIncludingVAT } from '@innogy/account';
import type { CostPerUnitVM } from '@innogy/become-a-customer/shared/interfaces';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering, LinkFieldValue } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

import { getOfferCostsPerUnit } from '../../helpers/offer-helpers';

@Component({
  selector: 'wl-aom-modal',
  templateUrl: './aom-modal.component.html',
  styleUrls: [
    './aom-modal.component.essent.scss',
    './aom-modal.component.ed.scss',
  ],
})
export class AomModalComponent implements OnInit {
  @Input()
  public offer?: Offer;
  @Input()
  public consumptionValuesVM?: any;
  @Input()
  public showFooter = true;
  @Input() orderPage?: LinkFieldValue;

  /**
   * The rendering of the initiating component (e.g. HorizontalOffer) used for tracking.
   */
  @Input() rendering?: InnogyComponentRendering;

  /**
   * The AOM specific rendering containing the fields needed to render the content of the AOM modal
   */
  @Input() aomContent?: InnogyComponentRendering;

  public costPerUnitVM?: CostPerUnitVM;

  public isVATIncluded$ = this.store$.select(shouldShowPricesIncludingVAT);

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store$: Store
  ) {}

  ngOnInit(): void {
    this.costPerUnitVM = getOfferCostsPerUnit(this.offer);
  }

  get fields(): ComponentFields | undefined {
    return this.aomContent?.fields;
  }

  get modalTitle() {
    const aomModalTitle = getFieldValue(this.rendering, 'AomModalTitle', '');

    return `${aomModalTitle} ${this.offer?.productTitle}`;
  }

  closeModal() {
    this.activeModal.close();
  }
}
