import { Injectable } from '@angular/core';
import type { HouseType, Residents } from '@essent/estimate-consumption';
import { getEstimatedConsumptionByHouseType as getEstimatedConsumptionByHouseTypeAction } from '@essent/estimate-consumption';
import { estimateBusinessConsumption } from '@innogy/become-a-customer/shared';
import type { SupplyType } from '@innogy/common-ui/sales';
import {
  BAC_QUESTIONNAIRE_ID,
  expectedConsumptionDefinedAction,
  usageQuestionnaireCloseModalAction,
} from '@innogy/common-ui/sales';
import { ofFormSubmitAction } from '@innogy/utils-deprecated';
import { Actions, concatLatestFrom, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MarkAsUnsubmittedAction } from 'ngrx-forms';
import { filter, mergeMap } from 'rxjs/operators';

import {
  getUsageQuestionnaireFormState,
  getUsageQuestionnaireSMEFormState,
  UsageQuestionnaireSME,
} from './';

@Injectable()
export class UsageQuestionnaireFormSubmitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}
  private readonly usageQuestionnaireFormValues$ = this.store$.pipe(
    select(getUsageQuestionnaireFormState)
  );

  private readonly UsageQuestionnaireSMEFormValues$ = this.store$.pipe(
    select(getUsageQuestionnaireSMEFormState)
  );

  public readonly submitUsageQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(BAC_QUESTIONNAIRE_ID),
      concatLatestFrom(() => [this.usageQuestionnaireFormValues$]),
      filter(
        ([_, questionnaireState]) =>
          questionnaireState.isValid && questionnaireState.isSubmitted
      ),
      mergeMap(([_, questionnaireState]) => {
        return [
          getEstimatedConsumptionByHouseTypeAction({
            payload: {
              residents: questionnaireState.value.residents as Residents,
              houseType: questionnaireState.value.houseType as HouseType,
            },
            actionId: BAC_QUESTIONNAIRE_ID,
          }),
          usageQuestionnaireCloseModalAction(),
        ];
      })
    )
  );

  public readonly markUsageQuestionnaireAsUnsubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(BAC_QUESTIONNAIRE_ID),
      mergeMap(() => [new MarkAsUnsubmittedAction(BAC_QUESTIONNAIRE_ID)])
    )
  );

  public readonly submitUsageQuestionnaireSME$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(UsageQuestionnaireSME.USAGE_QUESTIONNAIRE_SME_FORM_ID),
      concatLatestFrom(() => [this.UsageQuestionnaireSMEFormValues$]),
      filter(
        ([_, questionnaireState]) =>
          questionnaireState.isValid && questionnaireState.isSubmitted
      ),
      mergeMap(([_, questionnaireState]) => {
        const expectedConsumption = estimateBusinessConsumption(
          questionnaireState.value.businessType
        );

        return [
          expectedConsumptionDefinedAction({
            formId: BAC_QUESTIONNAIRE_ID,
            expectedConsumption,
            supplyType: questionnaireState.value.supplyType as SupplyType,
          }),
          usageQuestionnaireCloseModalAction(),
        ];
      })
    )
  );

  public readonly markUsageQuestionnaireSMEAsUnsubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(UsageQuestionnaireSME.USAGE_QUESTIONNAIRE_SME_FORM_ID),
      mergeMap(() => [
        new MarkAsUnsubmittedAction(
          UsageQuestionnaireSME.USAGE_QUESTIONNAIRE_SME_FORM_ID
        ),
      ])
    )
  );
}
