import type { EnvironmentConfig } from '@innogy/core-config-models';

/**
 * The id of the script element that contains the environment config.
 */
export const APP_STATE_ELEMENT_ID = 'innogy-app-state';

function getConfigFromAppState() {
  let envConfig: EnvironmentConfig | undefined = undefined;
  // eslint-disable-next-line no-restricted-globals
  const appStateElement = document.getElementById(APP_STATE_ELEMENT_ID);

  if (!appStateElement) {
    return undefined;
  }

  try {
    envConfig = JSON.parse(appStateElement.innerHTML)
      .config as EnvironmentConfig;
  } catch (error) {
    console.error(`Error parsing environment.json config: ${error}`);
  }

  return envConfig;
}

async function getConfigFromEnvironmentJson(deployUrl: string) {
  let config: EnvironmentConfig | undefined = undefined;
  try {
    const response = await fetch(`${deployUrl}/environment.json`);
    config = await response.json();
  } catch (error) {
    console.error(`Error fetching environment.json config: ${error}`);
  }
  return config;
}

export async function getEnvironmentConfig(deployUrl: string) {
  // First get the config from the app state if it exists.
  let config = getConfigFromAppState();

  // Test if the config contains the sentry key.
  if (!config?.sentry) {
    config = await getConfigFromEnvironmentJson(deployUrl);
  }

  // If the config or sentry key is still not found, log an error.
  if (!config?.sentry) {
    console.error(`Error fetching Sentry environmentConfig config`);
  }

  // Return the config or undefined.
  return config;
}
