import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  NgModule,
  Optional,
  PLATFORM_ID,
  SkipSelf,
} from '@angular/core';
import {
  AppConfigService,
  ConfigModule,
  ENVIRONMENT_CONFIG,
} from '@innogy/core-config-angular';
import type { EnvironmentConfig } from '@innogy/core-config-models';
import type { JssDictionaryResult } from '@innogy/jss-models';
import { IS_SITECORE_SERVER, JSS_DICTIONARY } from '@innogy/jss-models';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { UtilsModule } from '@innogy/utils-deprecated';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { JssModule as JssModuleSitecore } from '@sitecore-jss/sitecore-jss-angular';
import type { RestLayoutServiceConfig } from '@sitecore-jss/sitecore-jss/layout';

import { jssSelectorKey, reducers } from './+state';
import { JssPlaceholderEffects } from './+state/jss-placeholder.effects';
import { JssStateExperienceEditorServerActive } from './+state/jss-route.actions';
import { JssRouteEffects } from './+state/jss-route.effects';
import { JssTranslationLoaderService } from './dictionary/jss-translation-loader.service';
import { JssRouteComponent } from './jss-route/jss-route.component';
import { CustomRestLayoutService } from './jss/custom-rest-layout-service';
import { JssDataFetcherService } from './jss/jss-data-fetcher.service';
import { JssRouteBuilderService } from './jss/jss-route-builder.service';
import { JssRouteResolver } from './jss/jss-route-resolver.service';
import { JssService } from './jss/jss.service';

export function setSitecoreServerActive(
  store$: Store<any>,
  isSitecoreServer: boolean
) {
  return () => {
    store$.dispatch(new JssStateExperienceEditorServerActive(isSitecoreServer));
  };
}

export function getApiHostUrl(
  platformId: string,
  config: EnvironmentConfig,
  document?: Document
) {
  if (isPlatformBrowser(platformId) && document != null) {
    const basePath = config.basePath === '/' ? '' : config.basePath;
    return document.location.origin + basePath;
  }

  return config.sitecore.url;
}

export function layoutServiceFactory(
  config: EnvironmentConfig,
  dataFetcher: JssDataFetcherService,
  platformId: string,
  document?: Document
) {
  const layoutConfig: RestLayoutServiceConfig = {
    apiHost: getApiHostUrl(platformId, config, document),
    apiKey: config.sitecore.apiKey,
    siteName: config.app,
    configurationName: config.app,
    dataFetcherResolver: () => (url, data) => dataFetcher.fetch(url, data),
  };

  return new CustomRestLayoutService(layoutConfig);
}

@NgModule({
  imports: [
    ConfigModule,
    CommonModule,
    JssModuleSitecore,
    UtilsModule,
    HttpClientModule,
    StoreModule.forFeature(jssSelectorKey, reducers),
    EffectsModule.forFeature([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (
          config: EnvironmentConfig,
          http: HttpClient,
          dictionary: JssDictionaryResult,
          appConfig: AppConfigService
        ) =>
          new JssTranslationLoaderService(config, http, appConfig, dictionary),
        deps: [
          ENVIRONMENT_CONFIG,
          HttpClient,
          [new Optional(), JSS_DICTIONARY],
          AppConfigService,
        ],
      },
    }),
  ],
  declarations: [JssRouteComponent],
  exports: [JssModuleSitecore, JssRouteComponent, TranslateModule],
  providers: [
    /*
     * We need this to prevent the effects from initializing before the APP_INITIALIZER of
     * the ConfigModule.
     * See: https://github.com/ngrx/platform/issues/931
     */
    provideBootstrapEffects([JssRouteEffects, JssPlaceholderEffects]),
    JssService,
    JssRouteResolver,
    JssRouteBuilderService,
    JssDataFetcherService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Store, IS_SITECORE_SERVER],
      useFactory: setSitecoreServerActive,
    },
    {
      provide: CustomRestLayoutService,
      deps: [
        ENVIRONMENT_CONFIG,
        JssDataFetcherService,
        PLATFORM_ID,
        [Optional(), DOCUMENT],
      ],
      useFactory: layoutServiceFactory,
    },
  ],
})
export class JssModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule?: JssModule
  ) {
    if (parentModule) {
      throw new Error(
        'JssModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
