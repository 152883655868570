/* eslint-disable max-classes-per-file */
import type { ClassProvider, FactoryProvider } from '@angular/core';
import { Injectable, InjectionToken } from '@angular/core';
import { PlatformService } from '@innogy/core-platform';

/**
 * Taken from http://brianflove.com/2018/01/11/angular-window-provider/
 */

/* Create a new injection token for injecting the window into a component. */
export const WINDOW = new InjectionToken<Window>('WindowToken');

/* Define abstract class for obtaining reference to the global window object. */
export abstract class WindowRef {
  abstract get nativeWindow(): Window | null;
}

/* Define class that implements the abstract class and returns the native window object. */
@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  get nativeWindow(): Window | null {
    // eslint-disable-next-line no-restricted-globals
    return window;
  }
}

/* Create an factory function that returns the native window object. */
export function windowFactory(
  browserWindowRef: BrowserWindowRef,
  platformService: PlatformService
): Window | null {
  if (platformService.isClient()) {
    return browserWindowRef.nativeWindow;
  }
  return null;
}

/* Create a injectable provider for the WindowRef token that uses the BrowserWindowRef class. */
const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef,
};

/* Create an injectable provider that uses the windowFactory function for returning the native window object. */
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PlatformService],
};

/* Create an array of providers. */
export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
