import { Injectable } from '@angular/core';
import { createEffect, ofType, concatLatestFrom, Actions } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  postHeatpumpsLead,
  postHeatpumpsLeadSuccess,
  type CreateLeadHeatpumpRequest,
  type HeatDistributionSystem,
  type HeatpumpsBuildingType,
} from '@innogy/eplus/temporary-core-modules';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';

import { heatpumpsOrderConfirmationFormId } from './heatpumps-order-form.reducer';
import {
  selectHeatpumpsOrderFormCheckboxMapping,
  selectHeatpumpsOrderFormViewModel,
} from './heatpumps-order-form.selectors';
import { selectHeatpumpsAnswers } from '../heatpumps-questionnaire';
import {
  onHeatpumpsFunnelNavigationAction,
  selectHeatpumpsFunnelSettings,
} from '../heatpumps-funnel';

@Injectable()
export class HeatpumpsOrderFormEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<any>
  ) {}

  public readonly onSubmitHeatpumpsOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === heatpumpsOrderConfirmationFormId),
      concatLatestFrom(() => [
        this.store.select(selectHeatpumpsFunnelSettings),
        this.store.select(selectHeatpumpsOrderFormViewModel),
        this.store.select(selectHeatpumpsAnswers),
        this.store.select(selectHeatpumpsOrderFormCheckboxMapping),
      ]),
      map(
        ([
          _action,
          funnelSettings,
          formData,
          submittedAnswers,
          checkboxMapping,
        ]) => {
          const controls = {
            ...formData.addressProgressiveFormState.formState.controls,
            ...formData.confirmationProgressiveFormState.formState.controls,
            ...formData.personalDetailsProgressiveFormState.formState.controls,
          };
          const dataUsageKey = checkboxMapping['Option1'] || 'allow_data_usage';
          const promotionalUpdatesKey =
            checkboxMapping['Option2'] || 'allow_promotional_updates';

          const payload: CreateLeadHeatpumpRequest = {
            request_context: {
              request_type: 'Advies',
              channel: 'Online',
              sub_channel: 'Essent',
            },
            lead: {
              title: controls.salutation?.value || 'male',
              initials: controls.initials.value,
              last_name: controls.lastName.value,
              email: controls.emailAddress.value,
              phone: controls.phoneNumber.value,
              allow_data_usage: controls.checkboxes.value[dataUsageKey],
              allow_promotional_updates:
                controls.checkboxes.value[promotionalUpdatesKey],
            },
            offered_product: {
              category: 'Warmtepomp',
            },
            temperature: funnelSettings.leadTemperature || 1,
            created_on: new Date().toISOString(),
            building_type: submittedAnswers[
              'building_type'
            ] as HeatpumpsBuildingType,
            construction_year: submittedAnswers['construction_year'],
            has_solar_panels: submittedAnswers['has_solar_panels'] === 'true',
            boiler_ownership: submittedAnswers['boiler_ownership'],
            boiler_production_year: submittedAnswers['boiler_production_year'],
            heat_distribution_system: submittedAnswers[
              'heat_distribution_system'
            ] as HeatDistributionSystem,
            home_ownership: submittedAnswers['home_ownership'],
          };
          if (submittedAnswers['vent_housebuilt']) {
            payload.vent_housebuilt = submittedAnswers['vent_housebuilt'];
          }
          if (submittedAnswers['installed_insulation']) {
            payload.installed_insulation =
              submittedAnswers['installed_insulation'];
          }
          return postHeatpumpsLead({ payload });
        }
      )
    )
  );

  public readonly onPostHeatpumpsLeadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postHeatpumpsLeadSuccess),
      concatLatestFrom(() => this.store.select(selectHeatpumpsFunnelSettings)),
      filter(([, funnelSettings]) => !!funnelSettings.successPage),
      mergeMap(([, funnelSettings]) => [
        onHeatpumpsFunnelNavigationAction({
          page: funnelSettings.successPage,
        }),
      ])
    )
  );
}
