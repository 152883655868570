import { Component, Input, ViewContainerRef } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent {
  @Input()
  formsControl?: FormState<string>;
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input()
  tooltip = '';
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  stepname?: string;
  @Input()
  fieldname?: string;
  @Input()
  hashValue?: boolean;
  @Input()
  sendValue? = false;
  @Input()
  step?: number;
  @Input()
  floatingLabel = true;

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  public onFocus() {
    if (this.formsControl != null) {
      this.dispatchNativeEvent('focus');
    }
  }

  private dispatchNativeEvent(eventName: string) {
    this.viewContainerRef.element.nativeElement.dispatchEvent(
      new Event(eventName)
    );
  }
}
