import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { GenericProductData } from '@innogy/common-ui/models';
import { PlatformService } from '@innogy/core-platform';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import type {
  CommerceMapperContext,
  ParsedProductTrackingConfig,
  ProductEventType,
} from '../..';
import { TrackProductService } from '../..';
import { InViewportConfig } from '../track-impression/in-viewport-config';
import { InViewportService } from '../track-impression/in-viewport.service';

@Directive({
  selector: '[wlTrackProduct]',
  providers: [InViewportService],
})
export class TrackProductDirective<
  TProduct extends GenericProductData,
  TCommerce extends Record<string, any>
> implements AfterViewInit, OnDestroy
{
  private readonly MIN_TIME_IN_VIEWPORT_MS = 300;
  private readonly config: InViewportConfig = new InViewportConfig();
  private readonly subscription: Subscription = new Subscription();
  private hasTrackedImpression = false;

  @Input('wlTrackProduct')
  trackProduct!: {
    track: TProduct | TProduct[];
    context?: CommerceMapperContext;
    trackingConfig: ParsedProductTrackingConfig<TProduct, TCommerce>;
    trackMode: Exclude<ProductEventType, 'product-click'>;
  };

  @Output()
  public readonly trackProductAction: EventEmitter<
    Exclude<ProductEventType, 'product-click'>
  > = new EventEmitter();

  constructor(
    private readonly platformService: PlatformService,
    private readonly elementRef: ElementRef,
    private readonly inViewport: InViewportService,
    private readonly trackProductService: TrackProductService
  ) {}

  public ngAfterViewInit() {
    if (this.platformService.isClient()) {
      this.inViewport.register(this.elementRef.nativeElement, this.config);
      this.observeIntersection();
    }
  }

  private observeIntersection() {
    this.subscription.add(
      this.inViewport.trigger$
        .pipe(
          filter((entry) => this.shouldTrack(entry)),
          debounceTime(this.MIN_TIME_IN_VIEWPORT_MS)
        )
        .subscribe(this.trackProductEvent.bind(this))
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.platformService.isClient()) {
      this.inViewport.unregister(this.elementRef.nativeElement);
    }
  }

  private shouldTrack(entry: IntersectionObserverEntry) {
    const shouldTrack =
      entry &&
      entry.target === this.elementRef.nativeElement &&
      !this.hasTrackedImpression;

    return shouldTrack;
  }

  private trackProductEvent() {
    const {
      trackMode = 'product-impression',
      trackingConfig,
      context = { index: 0, numberOfProducts: 1, listName: '' },
      track,
    } = this.trackProduct;

    this.hasTrackedImpression = true;
    this.trackProductAction.emit(trackMode);

    const mapped = Array.isArray(track)
      ? track.map((product, index) =>
          trackingConfig.mapToCommerce(product, {
            index,
            listName: context.listName,
            numberOfProducts: track.length,
          })
        )
      : trackingConfig.mapToCommerce(track, context);

    if (trackMode === 'product-impression') {
      this.trackProductService.trackImpression(mapped);
    } else {
      this.trackProductService.trackDetail(mapped);
    }
  }
}
