import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { type BaseService } from '@essent/common';
import type { Observable } from 'rxjs';
import { API_PUBLIC_PREFIX } from '@innogy/core-config-angular';

import type {
  PostCreateLeadRequest,
  PostCreateLeadResponse,
} from '../../models/sustainability/sustainability-contact-forms.interface';

@Injectable()
export class PostCreateLeadService
  implements BaseService<PostCreateLeadRequest, PostCreateLeadResponse>
{
  public readonly apiVersion = '3';
  public readonly endpoint = `${this.apiUrl || ''}/eplus/leads/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: PostCreateLeadRequest
  ): Observable<PostCreateLeadResponse> {
    const headers = { Accept: 'application/json', Brand: 'essent' };
    return this.httpClient.post<PostCreateLeadResponse>(
      this.endpoint,
      payload,
      {
        headers,
      }
    );
  }
}
