import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccountModule } from '@innogy/account';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { DefaultComponentModule } from '@innogy/core/modules/components';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import {
  NgbCollapseModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { FooterContainerComponent } from './footer/footer-container.component';
import { FooterEwoComponent } from './footer/footer-ewo/footer-ewo.component';
import { FooterBrandComponent } from './footer/footer-lower/footer-brand/footer-brand.component';
import { FooterLowerComponent } from './footer/footer-lower/footer-lower.component';
import { FooterSocialsComponent } from './footer/footer-upper/footer-socials/footer-socials.component';
import { FooterUpperComponent } from './footer/footer-upper/footer-upper.component';
import { DrilldownNavComponent } from './shared/drilldown-nav/drilldown-nav.component';
import { EcmpHeaderImageComponent } from './shared/ecmp-header-image/ecmp-header-image.component';
import { HeaderToolsContainerComponent } from './shared/header-tools-container/header-tools-container.component';
import { HeaderToolsComponent } from './shared/header-tools/header-tools.component';
import {
  MinimalHeaderComponent,
  MinimalHeaderContainerComponent,
} from './shared/minimal-header';
import { NavbarDropdownComponent } from './shared/navbar-dropdown/navbar-dropdown.component';
import { NavItemToJssRoutePipe } from './shared/navbar-item/nav-item-to-jss-route.pipe';
import { NavbarItemComponent } from './shared/navbar-item/navbar-item.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { CollapseDirectionPipe } from './shared/off-canvas/collapse-direction.pipe';
import { OffCanvasComponent } from './shared/off-canvas/off-canvas.component';
import { SidebarNavContainerComponent } from './sidebar-nav/sidebar-nav-container/sidebar-nav-container.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';

@NgModule({
  imports: [
    DefaultComponentModule,
    AccountModule,
    LazyLoadImageModule,
    NgbCollapseModule,
    NgbDropdownModule,
    CommonUiPartialsModule,
    UtilsEnvironmentModule,
  ],
  declarations: [
    SidebarNavComponent,
    FooterContainerComponent,
    FooterUpperComponent,
    FooterLowerComponent,
    FooterBrandComponent,
    FooterSocialsComponent,
    FooterEwoComponent,
    NavbarComponent,
    NavbarItemComponent,
    HeaderToolsComponent,
    HeaderToolsContainerComponent,
    SidebarNavContainerComponent,
    OffCanvasComponent,
    NavItemToJssRoutePipe,
    CollapseDirectionPipe,
    NavbarDropdownComponent,
    MinimalHeaderContainerComponent,
    MinimalHeaderComponent,
    EcmpHeaderImageComponent,
    DrilldownNavComponent,
  ],
  exports: [
    RouterModule,
    CommonModule,
    UtilsModule,
    JssModule,
    TranslateModule,
    CoreAnalyticsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    FontAwesomeModule,
    CommonUiPartialsModule,
    SidebarNavComponent,
    FooterContainerComponent,
    FooterBrandComponent,
    FooterSocialsComponent,
    NavbarComponent,
    NavbarItemComponent,
    HeaderToolsComponent,
    HeaderToolsContainerComponent,
    SidebarNavContainerComponent,
    OffCanvasComponent,
    NavItemToJssRoutePipe,
    CollapseDirectionPipe,
    NavbarDropdownComponent,
    CommonUiUtilityComponentsModule,
    EcmpHeaderImageComponent,
    DrilldownNavComponent,
    UtilsEnvironmentModule,
  ],
})
export class NavigationSharedModule {}
