import type { ElementRef } from '@angular/core';
import {
  Component,
  forwardRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import type { FormViewAdapter } from 'ngrx-forms';
import { NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';

import type { CheckboxItem } from './checkbox-set.model';

@Component({
  selector: 'wl-checkbox-set',
  templateUrl: './checkbox-set.component.html',
  styleUrls: ['./checkbox-set.component.scss'],
  providers: [
    {
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => CheckboxSetComponent),
      multi: true,
    },
  ],
})
export class CheckboxSetComponent implements FormViewAdapter {
  @Input()
  checkboxItems: CheckboxItem[] = [];
  @Input()
  inline = false;
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  fieldname?: string;
  @Input()
  stepname?: string;
  @Input()
  sendValue = true;
  @Input()
  hashValue = false;
  @Input()
  step?: number;
  @Input()
  label?: string;
  @Input()
  name?: string;

  value: string[] = [];

  @ViewChildren('input')
  public inputs!: QueryList<ElementRef<HTMLInputElement>>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_value: any): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  setViewValue(value: string[]): void {
    this.value = value;
  }
  setOnChangeCallback(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  setOnTouchedCallback(fn: () => void): void {
    this.onTouched = fn;
  }

  onInputChanged(): void {
    const value = this.inputs
      .filter((input) => input.nativeElement.checked)
      .map((input) => input.nativeElement.value);

    this.value = value;
    this.onChange(value);
  }
}
