import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { PlatformService } from '@innogy/core-platform';
import { isAbsoluteUrl } from '@innogy/core-routing-utils';
import { WINDOW } from '@innogy/utils-dom';
import type {
  Field,
  LinkField,
  RenderingField,
} from '@sitecore-jss/sitecore-jss-angular';
import urlParser from 'url-parse';

export const CONSENT_PARAM = 'prompt-cookie-consent';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly injector: Injector,
    @Inject(WINDOW) private readonly windowRef: Window,
    @Optional()
    @Inject('JSS_SERVER_VIEWBAG')
    private readonly viewbag: { location?: string } | undefined,
    private readonly platformService: PlatformService,
    private readonly router: Router
  ) {}

  public get origin(): string {
    const config = this.injector.get(ENVIRONMENT_CONFIG);
    const baseUrl = config.seo?.canonicalBaseUrl;

    return !baseUrl || baseUrl === '' ? this.documentOrigin : baseUrl;
  }

  private get documentOrigin(): string {
    // Server side rendering
    if (this.viewbag) {
      if (this.viewbag.location == null) {
        return '';
      }
      // Remove trailing slash
      return this.viewbag.location.replace(/\/$/, '');
    }

    return urlParser(this.document.URL).origin;
  }

  /*
   * Represents the URL path, but is sanitized in order to
   * remove application specific parameters.
   */
  public get sanitizedPath(): string {
    // Server side rendering
    if (this.viewbag) {
      return this.document.URL;
    } else {
      const url = urlParser(this.document.URL);
      const params = new URLSearchParams(url.query);
      // Remove cookie-consent related params from the canonical URL.
      params.delete(CONSENT_PARAM);
      const query = params.toString();
      const path = `${query.length > 0 ? `?${query}` : query}`;
      // Reconstruct the path, without the cookie params.
      return `${url.pathname}${path}`;
    }
  }

  public getAbsoluteURL(): string {
    return `${this.origin}${this.sanitizedPath}`;
  }

  public getCanonicalUrl(fields: { [name: string]: Field | RenderingField }) {
    const override = this.getCanonicalOverride(fields);

    // Page has a canonical overwrite set
    if (override) {
      if (isAbsoluteUrl(override)) {
        return override;
      } else {
        return this.origin + override;
      }
    }

    return this.origin + this.sanitizedPath.toLowerCase();
  }

  private getCanonicalOverride(fields: {
    [name: string]: Field | RenderingField;
  }) {
    const canonical = fields['canonicalOverride'];
    if (this.hasOverride(canonical)) {
      return canonical.value.href;
    }

    return undefined;
  }

  private hasOverride(
    field: Field | RenderingField
  ): field is { value: { href: string } } {
    if (field == null) {
      return false;
    }

    const value = field.value as LinkField;
    return value != null && !!value.href;
  }

  public navigateGeneralLink(href: string) {
    if (this.platformService.isClient()) {
      if (isAbsoluteUrl(href)) {
        this.windowRef.location.assign(href);
      } else {
        this.router.navigateByUrl(href);
      }
    }
  }

  // Fixme: importing `ScLinkField` causes a circular import with '@innogy/jss`
  // discuss a better structure to prevent circulars with common models and extract them
  // in our codebase.
  public navigateScLink(
    scLinkField?: LinkField & { linktype?: '' | 'internal' | 'external' },
    fallbackHref?: string
  ) {
    if (this.platformService.isServer()) {
      return;
    }

    if (scLinkField?.href) {
      if (scLinkField.linktype === 'internal') {
        this.router.navigateByUrl(scLinkField.href);
        return;
      } else if (scLinkField.linktype === 'external') {
        this.windowRef.location.assign(scLinkField.href);
        return;
      }
    }

    if (fallbackHref) {
      return this.navigateGeneralLink(fallbackHref);
    }
  }
}
