import type { OnInit } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PlatformService } from '@innogy/core-platform';
import type { JssNavItem } from '@innogy/jss-models';

@Component({
  selector: 'wl-drilldown-nav[items]',
  templateUrl: './drilldown-nav.component.html',
  styleUrls: [
    './drilldown-nav.component.ed.scss',
    './drilldown-nav.component.essent.scss',
  ],
})
export class DrilldownNavComponent implements OnInit {
  @Input() items!: JssNavItem[];

  @Output() itemSelected = new EventEmitter<{
    navItem: JssNavItem;
    depth: number;
  }>();

  currentTreeDepth = 1;

  private readonly ANIMATION_DURATION = 300;

  // We facade navigation layers to encapsulate side-effects.
  private _navigationLayers: JssNavItem[][] = [];

  get navigationLayers(): JssNavItem[][] {
    return this._navigationLayers;
  }
  set navigationLayers(value: JssNavItem[][]) {
    this._navigationLayers = value;
    this.currentTreeDepth = value.length;
    this.setScrollPosition(value);
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cd: ChangeDetectorRef,
    private readonly platformService: PlatformService
  ) {}

  ngOnInit() {
    if (this.platformService.isClient()) {
      this.navigationLayers = [this.items];
      this.setScrollDuration(this.ANIMATION_DURATION);
    }
  }

  public reset() {
    // We set duration to 0 in order to 'snap' back to the initial view on reset.
    this.setScrollDuration(0);
    this.navigationLayers = [this.items];
    // After snapping back, we restore the original scroll duration.
    setTimeout(
      () => this.setScrollDuration(this.ANIMATION_DURATION),
      this.ANIMATION_DURATION
    );
  }

  public pop() {
    const newLayers = [
      ...this.navigationLayers.slice(0, this.navigationLayers.length - 1),
    ];

    this.setScrollPosition(newLayers);

    setTimeout(() => {
      this.navigationLayers = newLayers;
      this.cd.markForCheck();
    }, this.ANIMATION_DURATION);
  }

  tabIndexForLayer(layerDepth: number, layerCount: number) {
    return layerDepth === layerCount - 1 ? 0 : -1;
  }

  navItemSelected(clickedItem: JssNavItem, clickedItemDepth: number) {
    this.itemSelected.emit({ navItem: clickedItem, depth: clickedItemDepth });
    if (clickedItem.children?.length) {
      this.setChildrenAtDepth(clickedItem, clickedItemDepth);
    }
  }

  private setChildrenAtDepth(
    clickedItem: JssNavItem,
    clickedItemDepth: number
  ) {
    if (!clickedItem.children) {
      throw new Error(
        `Tried to navigate into a menu item without children: ${clickedItem}`
      );
    }
    const retainedNavigationLayers = this.navigationLayers.slice(
      0,
      clickedItemDepth + 1
    );
    this.navigationLayers = [...retainedNavigationLayers, clickedItem.children];
  }

  private setScrollDuration(durationInMs = this.ANIMATION_DURATION) {
    this.elementRef.nativeElement.style.setProperty(
      '--navigation-transition-duration',
      durationInMs.toString() + 'ms'
    );
  }

  private setScrollPosition(navigationLayers: JssNavItem[][]) {
    this.elementRef.nativeElement.style.setProperty(
      '--navigation-depth',
      (navigationLayers.length - 1).toString()
    );
  }
}
