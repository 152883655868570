import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
} from '@angular/core';
import { PlatformService } from '@innogy/core-platform';
import { WINDOW } from '@innogy/utils-dom';

import { SkeletonLoaderMask } from './svg-skeleton-loader.model';

@Component({
  selector: 'wl-svg-skeleton-loader',
  templateUrl: './svg-skeleton-loader.component.html',
  styleUrls: ['./svg-skeleton-loader.component.scss'],
})
export class SvgSkeletonLoaderComponent {
  @Input() mask?: SkeletonLoaderMask;

  get style() {
    // The SSR is not able to render base64 encoded background images
    if (this.platformService.isServer()) {
      return {};
    }

    return {
      width: '100%',
      height: this.mask ? this.computeHeight(this.mask) : '0px',
      backgroundImage: this.maskImage,
      backgroundSize: 'cover',
    };
  }

  get maskImage() {
    const base64Mask = this.window.btoa(this.mask?.mask || '');
    return `url("data:image/svg+xml;base64,${base64Mask}")`;
  }

  @HostBinding('class') classes = 'skeleton';
  @HostBinding('attr.aria-busy') ariaBusy = true;
  @HostBinding('attr.data-automation-id') automationId = 'skeleton';

  constructor(
    private readonly element: ElementRef<HTMLElement>,
    private readonly platformService: PlatformService,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  private computeHeight(mask: SkeletonLoaderMask) {
    const { nativeElement } = this.element;
    const width = mask.naturalWidth;
    const height = mask.naturalHeight;
    const elemWidth = nativeElement?.clientWidth;
    return `${(elemWidth / width) * height}px`;
  }
}
