import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { getFieldValue, getTreeListValues } from '@innogy/jss-utils';
import { InnogyComponentRendering } from '@innogy/jss-models';
import {
  addChoices,
  removeChoices,
  trackStep,
} from '@innogy/common-ui/service-components/store';
import { AnalyticsActionTypes } from '@innogy/core/analytics';
import type {
  LinkField,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'wl-how-to-questionnaire-question',
  templateUrl: './how-to-questionnaire-question.component.html',
  styleUrls: [
    './how-to-questionnaire-question.component.ed.scss',
    './how-to-questionnaire-question.component.essent.scss',
    './how-to-questionnaire-question.component.ewo.scss',
  ],
})
export class HowToQuestionnaireQuestionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() rendering?: InnogyComponentRendering;
  @Input() containerRendering?: InnogyComponentRendering;

  /**
   * "questions" are passed as a separate array of rendering objects because the current GraphQL implementation
   * retrieving data from sitecore can only go 6 layers deep. e.g. question -> options -> question -> options.
   * The 3rd question would not have any objects anymore when implemented recursively. The way it's implemented
   * now will never go deeper than 2 levels, we take 1 question, read the options on that question. But when that
   * option is clicked the question will be search for by ID in this list.
   */
  @Input() questions?: InnogyComponentRendering[];

  /**
   * This step property tracks the depth of questions, this is not the same as the trackingStep set in sitecore.
   * e.g. when step = 2, the trackingStep could be 2.1 or 2.2 because there are 2 possible second steps (depending
   * on what option was chosen).
   */
  @Input() step = 0;
  @Input() backToPreviousButtonText?: string;
  @Input() backToBeginningButtonText?: string;

  @Output() backClicked: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('currentQuestionAnchor') currentQuestionAnchor?: ElementRef;
  @ViewChild('nextQuestionAnchor') nextQuestionAnchor?: ElementRef;

  selectedOption?: number;
  nextQuestion?: InnogyComponentRendering;

  constructor(
    private readonly store$: Store<any>,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.trackStep();
  }

  ngOnChanges({ rendering }: SimpleChanges) {
    if (rendering) {
      this.reset();
    }
  }

  get title(): string | undefined {
    return getFieldValue<string>(this.rendering, 'Title');
  }

  get description(): RichTextField | undefined {
    return this.rendering?.fields?.Description as RichTextField;
  }

  get options(): InnogyComponentRendering[] {
    return getTreeListValues<InnogyComponentRendering>(
      this.rendering?.fields,
      'Options'
    );
  }

  get isFinalStep(): boolean {
    return getFieldValue<boolean>(this.rendering, 'IsFinalStep', false);
  }

  get showOptionsBelowDescription() {
    return getFieldValue<boolean>(
      this.rendering,
      'ShowOptionsBelowDescription',
      false
    );
  }

  get ctaLink(): LinkField | undefined {
    /*
     * NOTE:
     * The LinkField type is a lie, but is needed for the *wlGenericLink directive. The actual type of ctaLink is:
     * type GenericLinkField = {
     *   value: {
     *     anchor?: string
     *     class?: string
     *     linktype: 'internal' | 'external' | 'mailto' | 'anchor' | 'javascript' | 'media'
     *     queryString?: string
     *     href?: string
     *     text?: string
     *     title?: string
     *     url?: string
     *     id?: string
     *     target?: '_blank' | '_self' | '_parent' | '_top' | string
     *   } | ''
     * }
     */
    return this.rendering?.fields?.CtaLink as LinkField;
  }

  get ctaTxt(): string | undefined {
    return getFieldValue<string>(this.rendering, 'CtaTxt');
  }

  get trackingStep(): number | undefined {
    const step = getFieldValue<string>(this.rendering, 'Step');
    return step && !isNaN(+step) ? +step : undefined;
  }

  get trackingStepName(): string | undefined {
    return getFieldValue<string>(this.rendering, 'StepName');
  }

  private reset() {
    this.selectedOption = undefined;
    this.nextQuestion = undefined;
    this.removeChoiceForFinalTracking();
  }

  private scrollTo(anchor: ElementRef | undefined) {
    this.detectChanges();
    anchor?.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  private detectChanges() {
    try {
      /**
       * changeDetector.detectChanges will throw an error when there is a runtime error in ngOnInit or ngOnChanges
       * in one of the child-components. This could happen if an icon can't be loaded properly (e.g. when the
       * fa-icon chosen in sitecore does not exist or is not imported properly). This try-catch block will
       * make sure the code after detectChanges will still run even though an icon was not loaded properly.
       */
      this.changeDetector.detectChanges();
    } catch {
      /* ignore */
    }
  }

  onOptionSelected(itemId: string | undefined, index: number) {
    // When a new option is selected
    if (this.selectedOption !== index) {
      // If there was another option selected before
      if (this.selectedOption !== undefined) {
        // This will make sure ngOnDestroy is called in the nextQuestion before loading the new one.
        this.reset();
        this.detectChanges();
      }
      this.selectedOption = index;
      this.nextQuestion = this.getQuestionByItemId(itemId);
      this.addChoiceForFinalTracking();
    }
    this.scrollTo(this.nextQuestionAnchor);
  }

  onBackClicked(cascade = false) {
    this.reset();

    if (cascade) {
      this.backClicked.emit(this.step > 2);
    }
    if (!cascade || this.step === 1) {
      this.scrollTo(this.currentQuestionAnchor);
    }
  }

  private getQuestionByItemId(
    itemId: string | undefined
  ): InnogyComponentRendering | undefined {
    return (
      (this.questions as (InnogyComponentRendering & { id: string })[]) || []
    ).find(({ id }) => id === itemId);
  }

  private trackStep() {
    if (this.trackingStep && this.trackingStepName) {
      const { LAST_STEP, STEP } = AnalyticsActionTypes;
      const trackingValues = {
        rendering: this.containerRendering,
        type: this.isFinalStep ? LAST_STEP : STEP,
        step: this.trackingStep,
        stepname: this.trackingStepName,
      };
      this.store$.dispatch(
        trackStep({
          trackingValues,
        })
      );
    }
  }

  private addChoiceForFinalTracking() {
    if (typeof this.trackingStep !== 'number') {
      return;
    }

    const choices = this.options.reduce((acc, option, index) => {
      const optionName = getFieldValue(option, 'OptionName');
      if (typeof optionName === 'string') {
        return {
          ...acc,
          [optionName]: this.selectedOption === index ? 'ja' : 'nee',
        };
      }
      return acc;
    }, {});

    this.store$.dispatch(
      addChoices({
        choices: {
          [this.trackingStep]: choices,
        },
      })
    );
  }

  private removeChoiceForFinalTracking() {
    if (typeof this.trackingStep === 'number') {
      this.store$.dispatch(
        removeChoices({
          step: this.trackingStep,
        })
      );
    }
  }

  ngOnDestroy() {
    this.reset();
  }
}
