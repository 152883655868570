import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { snakeCase } from '@essent/common';
import type {
  GetOfferPayload,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { API_PUBLIC_PREFIX } from '@innogy/core-config-angular';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { Observable } from 'rxjs';

@Injectable()
export class GetOfferService implements BaseService<any, any> {
  readonly apiVersion: string = '1';
  readonly endpoint: string = `${this.apiUrl || ''}/cplusactivation/offer/v${
    this.apiVersion
  }`;

  public constructor(
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(payload?: GetOfferPayload): Observable<Offer> {
    const headers = { Accept: 'application/json' };
    let params = {
      // eslint-disable-next-line @typescript-eslint/ban-types
      ...snakeCase<object>({ ...payload }),
    };
    params = omitBy(params, isNil);

    return this.httpClient.get<Offer>(this.endpoint, {
      headers,
      params,
    });
  }
}
