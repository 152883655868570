import { NgModule, Optional, SkipSelf } from '@angular/core';
import { combineEffectsWithProviders } from '@essent/common';
import { EffectsModule } from '@ngrx/effects';

import { getInsulationAddressCheckApi } from './get-insulation-address-check/get-insulation-address-check.config';
import { getInsulationProductsApi } from './get-insulation-products/get-insulation-products.config';
import { getMarketingModelContractApi } from './get-marketing-model-contract';
import { getSolarPanelsAddressCheckApi } from './get-solar-panels-address-check/get-solar-panels-address-check.config';
import { getSolarPanelsProductsApi } from './get-solar-panels-products/get-solar-panels-products.config';
import { getStoreProductsApi } from './get-store-products';
import { postGenericFormSubmitApi } from './post-generic-form-submit';
import { postInsulationConfirmationApi } from './post-insulation-confirmation/post-insulation-confirmation.config';
import { postSolarPanelsConfirmationApi } from './post-solar-panels-confirmation/post-solar-panels-confirmation.config';
import { postStoreOrderApi } from './post-store-order';
import { postCreateLeadApi } from './post-create-lead';
import { getSolarPaybackApi } from './get-solar-payback';
import { getTimeslotsApi } from './get-timeslots';
import { postHeatpumpsLeadApi } from './post-heatpumps-lead';

const { EFFECTS, PROVIDERS } = combineEffectsWithProviders(
  getSolarPanelsAddressCheckApi,
  getSolarPanelsProductsApi,
  postSolarPanelsConfirmationApi,
  getInsulationAddressCheckApi,
  getInsulationProductsApi,
  postInsulationConfirmationApi,
  getStoreProductsApi,
  postStoreOrderApi,
  postGenericFormSubmitApi,
  getMarketingModelContractApi,
  postCreateLeadApi,
  getSolarPaybackApi,
  postCreateLeadApi,
  postHeatpumpsLeadApi,
  getTimeslotsApi
);

@NgModule({
  imports: [EffectsModule.forFeature(EFFECTS)],
  providers: [...PROVIDERS],
})
export class TemporaryCoreModulesModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: TemporaryCoreModulesModule
  ) {
    if (parentModule) {
      throw new Error('TemporaryCoreModulesModule is imported more than once.');
    }
  }
}
