import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type {
  SimpleProgressiveFormCardContent,
  SimpleProgressiveFormSubmitContext,
} from '@innogy/common-ui/progressive-forms';
import { TrackToolStep } from '@innogy/core/analytics';
import type { ScMultiListField } from '@innogy/jss-models';
import {
  getFieldValue,
  getItemLinkFieldValue,
  getTreeListValues,
} from '@innogy/jss-utils';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import type { ScCheckboxFormInput } from '@innogy/sitecore-forms/models';
import { Store } from '@ngrx/store';
import { validate } from 'ngrx-forms';
import { requiredTrue } from 'ngrx-forms/validation';

import { addCheckboxFormControlAction } from './state/eplus-confirmation-form.actions';
import type { FormConfirmation } from './state/eplus-confirmation-form.reducer';
import { EplusConfirmationRendering } from './state/eplus-confirmation-form.rendering';

@Component({
  selector:
    'wl-eplus-progressive-confirmation-form[progressiveFormState][rendering][trackStep]',
  templateUrl: './eplus-progressive-confirmation-form.component.html',
})
export class EplusProgressiveConfirmationFormComponent implements OnInit {
  @Input() trackStep!: TrackToolStep;
  @Input() rendering!: EplusConfirmationRendering;

  @Input()
  progressiveFormState!: ProgressiveFormGroupState<FormConfirmation> | null;

  @Output() submitted = new EventEmitter<SimpleProgressiveFormSubmitContext>();

  progressiveFormCardContent?: SimpleProgressiveFormCardContent;

  constructor(private readonly store$: Store) {}

  get confirmationCheckboxes(): ScMultiListField<ScCheckboxFormInput> {
    return getTreeListValues(this.rendering.fields, 'ConfirmationCheckboxes');
  }
  ngOnInit(): void {
    this.progressiveFormCardContent = {
      title: getFieldValue(this.rendering.fields, 'ConfirmationStepTitle', ''),
      subtitle: getFieldValue(
        this.rendering.fields,
        'ConfirmationStepSubTitle',
        ''
      ),
      submitButtonText: getFieldValue(
        this.rendering.fields,
        'ConfirmationStepSubmitButtonText',
        ''
      ),
      submitButtonIcon: getItemLinkFieldValue(
        this.rendering.fields,
        'ConfirmationStepSubmitButtonIcon'
      ) as IconProp,
      stepNumber: this.trackStep.step,
    };

    this.confirmationCheckboxes.forEach((checkbox) => {
      const name = getFieldValue<string>(checkbox.fields, 'Label');
      const required = !!getFieldValue<boolean>(checkbox.fields, 'Required');

      if (name) {
        // Add the checkbox formControls & validators dynamically
        this.store$.dispatch(
          addCheckboxFormControlAction({
            name,
            ...(required && { validator: validate(requiredTrue) }),
          })
        );
      }
    });
  }

  getControlByLabel(label: string | undefined) {
    if (!label || !this.progressiveFormState) {
      return undefined;
    }

    const control =
      this.progressiveFormState.formState.controls.checkboxes.controls[label];

    return control;
  }
}
