import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { PlatformService } from '@innogy/core-platform';
import { LayoutModule } from '@angular/cdk/layout';

import { MediaObserverService } from './mediaObserver/media-observer.service';

export {
  DeviceInfo,
  MediaObserverService,
} from './mediaObserver/media-observer.service';

@NgModule({
  providers: [MediaObserverService],
  imports: [LayoutModule],
})
export class SharedResponsiveToolsModule {
  static forRoot(): ModuleWithProviders<SharedResponsiveToolsModule> {
    return {
      ngModule: SharedResponsiveToolsModule,
    };
  }

  constructor(readonly platformService: PlatformService) {}
}
