import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';

@Component({
  selector: 'wl-card-content-block',
  templateUrl: './card-content-block.component.html',
})
export class CardContentBlockComponent {
  @Input()
  rendering?: InnogyComponentRendering;
}
