import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ScrollService } from '@innogy/utils-deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, map, tap } from 'rxjs/operators';

import {
  confirmInsulationProductSelectionAction,
  onInsulationFunnelNavigationAction,
  selectInsulationFunnelSettings,
} from '../insulation-funnel';
import { showInsulationProductOverviewAction } from './insulation-product-overview.actions';
import { insulationProductOverviewKey } from './insulation-product-overview.reducer';

@Injectable()
export class InsulationProductOverviewEffects {
  funnelSettings$ = this.store$.select(selectInsulationFunnelSettings);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly scrollService: ScrollService
  ) {}

  public readonly showInsulationProductOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onInsulationFunnelNavigationAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([action, funnelSettings]) =>
          action.page === funnelSettings.productOverviewPage
      ),
      map(showInsulationProductOverviewAction)
    )
  );

  public readonly scrollToProductOverview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showInsulationProductOverviewAction),
        delay(250),
        tap(() => {
          const productOverviewElement = this.document.getElementById(
            insulationProductOverviewKey
          );

          this.scrollService.scrollToElementAnimated(
            this.scrollService.getWrappingDynamicRow(productOverviewElement)
          );
        })
      ),
    { dispatch: false }
  );

  public readonly navigateToOrderPageOnSelectProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmInsulationProductSelectionAction),
      concatLatestFrom(() => this.funnelSettings$),
      map(([_, { orderPage }]) =>
        onInsulationFunnelNavigationAction({ page: orderPage })
      )
    )
  );
}
