import { Component, Input } from '@angular/core';
import type {
  PriceItem,
  PriceGroup,
} from '@innogy/become-a-customer/shared/interfaces';
import { OfferOverview } from '@innogy/become-a-customer/shared/interfaces';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { InnogyComponentRendering } from '@innogy/jss-models';

@Component({
  selector: 'wl-price-overview-table',
  templateUrl: './price-overview-table.component.html',
})
export class PriceOverviewTableComponent {
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  public priceOverview?: OfferOverview;
  @Input()
  public title?: string;
  @Input()
  public faIcon?: IconProp;

  calculateYearlyUnitAmount(price: PriceItem) {
    if (price.unitPrice === 0 || price.expectedPeriodAmount === undefined) {
      return 0;
    }

    return Math.round(price.expectedPeriodAmount / price.unitPrice);
  }

  trackByPricegroup(_: number, item: PriceGroup) {
    return item.id;
  }

  trackByPrice(_: number, item: PriceItem) {
    return item.id;
  }
}
