import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AddressCoreModule } from '@essent/address';
import { API as contractAPI, ContractCoreModule } from '@essent/contract';
import { API as customerAPI, CustomerCoreModule } from '@essent/customer';
import {
  API as estimatedConsumptionAPI,
  EstimateConsumptionCoreModule,
} from '@essent/estimate-consumption';
import { API as fehAPI, FehModule } from '@essent/feh';
import {
  API as installationAddressAPI,
  InstallationAddressCoreModule,
} from '@essent/installation-address';
import {
  API as newCustomerAPI,
  NewCustomerCoreModule,
} from '@essent/new-customer';
import { BecomeACustomerModule } from '@innogy/become-a-customer';
import { becomeACustomerLazyImports } from '@innogy/become-a-customer/lazy';
import { chatbotLazyImports } from '@innogy/chatbot/iadvize/lazy';
import { CommonUiServiceComponentsModule } from '@innogy/common-ui/service-components';
import {
  API_PUBLIC_PREFIX,
  apiPublicBasePath,
} from '@innogy/core-config-angular';
import { contractingLazyImports } from '@innogy/contracting/lazy';
import { ContractingStoreModule } from '@innogy/contracting/store';
import { ChattingStoreModule } from '@innogy/core/chatting';
import { createOnlineAccountLazyImports } from '@innogy/create-online-account/lazy';
import { CreateOnlineAccountStoreModule } from '@innogy/create-online-account/store';
import { emobilityLeadLazyImports } from '@innogy/emobility-lead/lazy';
import { EmobilityLeadStoreModule } from '@innogy/emobility-lead/store';
import { eplusLazyImports } from '@innogy/eplus/lazy';
import { EplusStoreModule } from '@innogy/eplus/store';
import { knowledgeBaseLazyImports } from '@innogy/knowledge-base/lazy';
import { marketingHeadingLazyImports } from '@innogy/marketing-heading/lazy';
import { reviewsLazyImports } from '@innogy/reviews/lazy';
import { memberGetMemberLazyImports } from '@innogy/shared/member-get-member/lazy';
import { tariffBreakdownLazyImports } from '@innogy/shared/tariff-breakdown/lazy';
import { SharedTariffBreakdownStoreModule } from '@innogy/shared/tariff-breakdown/store';
import { sitecoreFormsLazyImports } from '@innogy/sitecore-forms/lazy';
import { SitecoreFormsStoreModule } from '@innogy/sitecore-forms/store';
import { RecaptchaModule } from 'ng-recaptcha';
import { tariffsInOpenLazyImports } from '@innogy/tariffs/lazy';
import { TariffsCoreModule } from '@essent/tariffs';
import { TariffsStoreModule } from '@innogy/tariffs/store';
import { cookieSettingsLazyImports } from '@innogy/cookie-settings-lazy';

@NgModule({
  imports: [
    BecomeACustomerModule,
    ContractingStoreModule,
    CommonUiServiceComponentsModule,
    InstallationAddressCoreModule.forRoot(),
    NewCustomerCoreModule.forRoot(),
    ContractCoreModule.forRoot(),
    EstimateConsumptionCoreModule.forRoot(),
    CustomerCoreModule.forRoot(),
    AddressCoreModule.forRoot({ api: apiPublicBasePath }),
    FehModule.forRoot(),
    TariffsCoreModule.forRoot(),
    EmobilityLeadStoreModule,
    EplusStoreModule,
    CreateOnlineAccountStoreModule,
    ChattingStoreModule,
    SitecoreFormsStoreModule,
    SharedTariffBreakdownStoreModule,
    RecaptchaModule,
    TariffsStoreModule,
  ],
  providers: [
    {
      provide: installationAddressAPI,
      useExisting: API_PUBLIC_PREFIX,
    },
    {
      provide: contractAPI,
      useExisting: API_PUBLIC_PREFIX,
    },
    {
      provide: newCustomerAPI,
      useExisting: API_PUBLIC_PREFIX,
    },
    {
      provide: estimatedConsumptionAPI,
      useExisting: API_PUBLIC_PREFIX,
    },
    { provide: customerAPI, useExisting: API_PUBLIC_PREFIX },
    { provide: fehAPI, useExisting: API_PUBLIC_PREFIX },
  ],
})
export class CoreModulesOpenModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModulesOpenModule
  ) {
    if (parentModule) {
      throw new Error('CoreModulesOpenModule is imported more than once.');
    }
  }
}

export const OPEN_JSS_IMPORTS = [];
export const OPEN_JSS_LAZY_IMPORTS = [
  ...knowledgeBaseLazyImports,
  ...becomeACustomerLazyImports,
  ...contractingLazyImports,
  ...marketingHeadingLazyImports,
  ...chatbotLazyImports,
  ...reviewsLazyImports,
  ...emobilityLeadLazyImports,
  ...eplusLazyImports,
  ...createOnlineAccountLazyImports,
  ...sitecoreFormsLazyImports,
  ...memberGetMemberLazyImports,
  ...tariffBreakdownLazyImports,
  ...tariffsInOpenLazyImports,
  ...cookieSettingsLazyImports,
];
