import type {
  InnogyComponentRendering,
  JssFieldWithValue,
} from '@innogy/jss-models';

import type {
  CustomGraphQLFieldValue,
  CustomGraphQLRendering,
  FlattenedCustomGraphQL,
} from './custom-graphql-rendering.model';
import { isCustomGraphQLRendering } from './custom-graphql-rendering.model';

export const getFieldsFromCustomGraphQL = (
  rendering: InnogyComponentRendering | CustomGraphQLRendering
): FlattenedCustomGraphQL | undefined => {
  if (!isCustomGraphQLRendering(rendering)) {
    return undefined;
  }

  const data = rendering.fields.data;
  const dataSourceFields = data.datasource?.fields || [];
  const contextItemFields = data.contextItem?.fields || [];
  const contextItemPredefinedFields =
    getContextItemPredefinedFields(data.contextItem) || [];
  const fields = {
    ...mapCustomGraphQLFields(dataSourceFields),
    ...mapCustomGraphQLFields(contextItemFields),
    ...mapCustomGraphQLFields(contextItemPredefinedFields),
  };

  return {
    fields,
    context: { ...data.contextItem },
  };
};

const mapCustomGraphQLFields = (
  fields: CustomGraphQLFieldValue[]
): { [key: string]: any } => {
  const res: { [key: string]: any } = {};

  if (fields) {
    fields.forEach((field: any) => {
      res[field.name] = { value: field.jss.value };
    });
  }

  return res;
};

export const getContextItemPredefinedFields = (contextItem?: {
  [key: string]: any;
}): CustomGraphQLFieldValue[] | undefined => {
  if (contextItem) {
    return Object.keys(contextItem)
      .filter((key) => isJssFieldWithValue(contextItem[key]))
      .map((key) => ({ name: key, jss: contextItem[key].jss }));
  }
  return undefined;
};

const isJssFieldWithValue = (field: any): field is JssFieldWithValue => {
  return (field as JssFieldWithValue)?.jss?.value !== undefined;
};
