import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PUBLIC_PREFIX } from '@innogy/core-config-angular';
import type { Observable } from 'rxjs';
import type {
  PutInitiateFlowPayload,
  PutInitiateFlowResponse,
} from '@innogy/become-a-customer/shared/interfaces';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { snakeCase, toCamelcase } from '@essent/core-utils';

@Injectable()
export class PutInitiateFlowService implements BaseService<any, any> {
  readonly apiVersion: string = '';
  readonly endpoint: string = `${this.apiUrl || ''}/bac/initiate-flow`;

  public constructor(
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload?: PutInitiateFlowPayload
  ): Observable<PutInitiateFlowResponse> {
    const headers = { Accept: 'application/json' };
    let params = {
      // eslint-disable-next-line @typescript-eslint/ban-types
      ...snakeCase<object>({ ...(payload || {}) }),
    };
    params = omitBy(params, isNil);
    return this.httpClient
      .put(this.endpoint, {
        headers,
        params,
      })
      .pipe(toCamelcase<PutInitiateFlowResponse>());
  }
}
