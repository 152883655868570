import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { Angulartics2 } from 'angulartics2';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import type { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { PlatformService } from '@innogy/core-platform';

import { formatLinkUrl } from '../shared/utils';
import {
  DEFAULT_IMPRESSION_THRESHOLD,
  InViewportConfig,
  InViewportConfigOptions,
} from './in-viewport-config';
import { InViewportService } from './in-viewport.service';
import type { TrackImpressionEvent } from './track-impression.model';
import { InViewportMetadata } from './track-impression.model';
import { WINDOW } from '@innogy/utils-dom';

export type TrackImpressionMode = 'component' | 'button' | 'popup' | 'any';
export type TrackImpressionFrequency = 'once' | 'always';

export const defaultTrackImpressionMode = 'component';
export const defaultTrackImpressionFrequency = 'once';

@Directive({
  selector: '[wlTrackImpression]',
  providers: [InViewportService],
})
export class TrackImpressionDirective implements AfterViewInit, OnDestroy {
  private readonly MIN_TIME_IN_VIEWPORT_MS = 300;
  private config: InViewportConfig = new InViewportConfig();
  private readonly subscription: Subscription = new Subscription();
  private hasTrackedImpression = false;
  private pendingImpressionTimerId?: number;

  @Input('wlTrackImpression')
  trackImpression = 'unknown-impression';

  /**
    The track impression info can be used to either send Sitecore component data or
    any other data which is added to the analytics properties.

    When {@link ComponentRendering} is provided the rendering properties are wrapped in
    with an extra property, so {@link TrackImpressionEnhancer} is able to provide all
    additional data.
  */
  @Input()
  trackImpressionInfo!: ComponentRendering | any;

  @Input()
  trackImpressionContext?: ColumnContext | any;

  @Input()
  trackImpressionMode: TrackImpressionMode = defaultTrackImpressionMode;

  @Input()
  trackImpressionLinkurl?: string;

  @Input()
  trackImpressionName?: string;

  @Input()
  trackImpressionFrequency: TrackImpressionFrequency =
    defaultTrackImpressionFrequency;

  @Input()
  set trackImpressionOptions(value: InViewportConfigOptions) {
    this.config = new InViewportConfig(value);
  }

  @Output()
  public readonly trackImpressionAction: EventEmitter<TrackImpressionEvent> =
    new EventEmitter();

  constructor(
    private readonly platformService: PlatformService,
    private readonly elementRef: ElementRef,
    private readonly inViewport: InViewportService,
    private readonly angulartics: Angulartics2,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public ngAfterViewInit() {
    if (this.platformService.isClient()) {
      this.inViewport.register(this.elementRef.nativeElement, this.config);
      this.subscription.add(
        this.inViewport.trigger$
          .pipe(
            filter(
              (entry) => entry && entry.target === this.elementRef.nativeElement
            ),
            map(this.mapToImpressionEvent.bind(this))
          )
          .subscribe(this.trackImpressionAngulartics.bind(this))
      );
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.platformService.isClient()) {
      this.inViewport.unregister(this.elementRef.nativeElement);
    }
  }

  private mapToImpressionEvent(
    entry: IntersectionObserverEntry
  ): TrackImpressionEvent {
    const visible = entry.isIntersecting;

    return {
      [InViewportMetadata]: { entry },
      target: this.elementRef.nativeElement,
      visible,
      isIntersectingThreshold: entry
        ? entry.intersectionRatio >= DEFAULT_IMPRESSION_THRESHOLD
        : false,
    };
  }

  private getImpressionEventProperties() {
    if (this.trackImpressionMode === 'component') {
      return {
        name: this.trackImpressionName,
        rendering: this.trackImpressionInfo,
        context: this.trackImpressionContext,
        isComponentImpression: true,
      };
    }
    if (this.trackImpressionMode === 'button') {
      return {
        name: this.trackImpressionName,
        rendering: this.trackImpressionInfo,
        context: this.trackImpressionContext,
        linkurl: formatLinkUrl(this.trackImpressionLinkurl, this.document),
        isButtonImpression: true,
      };
    }
    return this.trackImpressionInfo;
  }

  private trackImpressionAngulartics(event: TrackImpressionEvent) {
    const shouldTrack =
      (event.visible && this.trackImpressionFrequency === 'always') ||
      (event.visible &&
        this.trackImpressionFrequency === 'once' &&
        !this.hasTrackedImpression);
    const wasSeenByUser = event.isIntersectingThreshold;

    if (shouldTrack && wasSeenByUser) {
      clearTimeout(this.pendingImpressionTimerId);

      this.pendingImpressionTimerId = this.window.setTimeout(() => {
        this.hasTrackedImpression = true;
        this.trackImpressionAction.emit(event);

        this.angulartics.eventTrack.next({
          action: this.trackImpression,
          properties: this.getImpressionEventProperties(),
        });
      }, this.MIN_TIME_IN_VIEWPORT_MS);
    } else if (!event.visible) {
      clearTimeout(this.pendingImpressionTimerId);
    }
  }
}
