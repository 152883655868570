import { ViewportScroller } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageInfoService } from '@innogy/core/analytics';
import { PlatformService } from '@innogy/core-platform';
import { ScrollService } from '@innogy/utils-deprecated';
import type { Subscription } from 'rxjs';
import { Subject, timer } from 'rxjs';
import { audit, debounce, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wl-setup-anchor-scrolling',
  template: '',
})
export class SetupAnchorScrollingComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject();
  subscription: Subscription | undefined;

  constructor(
    readonly platform: PlatformService,
    readonly pageInfoService: PageInfoService,
    readonly viewportScroller: ViewportScroller,
    readonly router: Router,
    readonly route: ActivatedRoute,
    readonly scrollService: ScrollService
  ) {}

  ngOnInit() {
    if (this.platform.isClient()) {
      this.subscription = this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          audit(() => this.pageInfoService.allTopLevelPlaceholdersLoaded$),
          /**
           * There is a 250ms delay before scrolling for 2 reasons:
           *   1. When the (loaded) event is emitted from <sc-placeholder>, it is only
           *      markedForCheck in the ChangeDetector, it still has to run.
           *   2. There could also be items in the placeholder that need to lazy-load,
           *      there is currently no way to wait for that.
           */
          debounce(() => timer(250)),
          map((_, index) => {
            const { fragment } = this.route.snapshot;
            if (fragment) {
              if (index === 0) {
                // If the initial page-load has a fragment, it should scroll immediately without animation
                this.viewportScroller.scrollToAnchor(fragment);
              } else {
                // Any internal navigation afterward should scroll to anchors with animation
                this.scrollService.scrollToAnchorAnimated(fragment);
              }
            }
          }),
          takeUntil(this.onDestroy$)
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
