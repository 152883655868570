import {
  Component,
  inject,
  type AfterViewInit,
  type OnDestroy,
} from '@angular/core';
import {
  NgbModal,
  NgbModalModule,
  type NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, filter, tap } from 'rxjs/operators';

import { ConsentAnalyticsService } from '../consent-analytics.service';
import { ConsentService, type CONSENT_REASON_VALUES } from '../consent.service';
import { CookiewallVariantBComponent } from '../cookiewall-variant-b/cookiewall-variant-b.component';

@Component({
  selector: 'wl-cookiewall-container',
  standalone: true,
  template: ``,
  styles: [``],
  imports: [NgbModalModule],
})
export class CookiewallContainerComponent implements AfterViewInit, OnDestroy {
  readonly onDestroy$ = new Subject();

  readonly #modalService = inject(NgbModal);
  readonly #consentService = inject(ConsentService);
  readonly #consentAnalytics = inject(ConsentAnalyticsService);
  readonly #navigationEvents = inject(Router).events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd)
  );
  #modalRef: NgbModalRef | null = null;

  async ngAfterViewInit() {
    await this.#doConsentCheck();

    if (this.#consentService.shouldShowCookiewall()) {
      !this.#modalRef && this.#summonCookiewall();
    } else {
      this.#listenForCookiewallDisplayConditions();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  /**
   * Checks if a consent prompt is required,
   * if so: instructs the consent service to mark consent as required.
   */
  async #doConsentCheck() {
    if (await this.#consentService.isCookieConsentPromptRequired()) {
      // This is needed to wait for the angular navigation to be stable.
      // using setTimeout forces a macroTask, which ensures that the angular routing is stable.
      await new Promise((resolve) => {
        setTimeout(() => resolve(true), 0);
      });
      this.#consentService.markCookieConsentIsRequired();
    }
  }

  #summonCookiewall() {
    this.#modalRef = this.#modalService.open(CookiewallVariantBComponent, {
      beforeDismiss: () => false,
      scrollable: true,
      size: 'lg',
    });
    // Only send an impression beacon when we actually show a cookie wall.
    this.#consentAnalytics.sendImpressionBeacon();

    this.#modalRef.result.then((result: CONSENT_REASON_VALUES) => {
      this.#consentService.setConsentLevel(result);
      this.#consentAnalytics.sendSubmissionBeacon(result);
      this.#modalRef = null;
    });
  }

  #listenForCookiewallDisplayConditions() {
    this.#navigationEvents
      .pipe(
        tap(async () => await this.#doConsentCheck()),
        filter(
          () => this.#consentService.shouldShowCookiewall() && !this.#modalRef
        ),
        tap(() => {
          this.#summonCookiewall();
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }
}
