import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GenericErrorModalModule } from '@innogy/common-ui/modals';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { StructuredDataModule } from '@innogy/core-structured-data';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import {
  NgbAccordionModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { AccordionContainerComponent } from './accordion/accordion-container.component';
import { AnchorComponent } from './anchor/anchor.component';
import { AppButtonsComponent } from './app-buttons/app-buttons.component';
import { basicContentComponents } from './basic-content';
import { BasicContentComponent } from './basic-content/basic-content.component';
import { BasicContentVariantMediaCardComponent } from './basic-content/variants/basic-content-variant-media-card/basic-content-variant-media-card.component';
import { BasicContentVariantMediaSectionComponent } from './basic-content/variants/basic-content-variant-media-section/basic-content-variant-media-section.component';
import { BreadcrumbWrapperComponent } from './breadcrumb/breadcrumb-wrapper/breadcrumb-wrapper.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CardContentBlockComponent } from './card-content-block/card-content-block.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ComposablesModule } from './composables/composables.module';
import { EqualHeightComponent } from './equal-height/equal-height.component';
import { FunctionalErrorComponent } from './functional-error/functional-error.component';
import { HorizontalLinksListComponent } from './horizontal-links-list/horizontal-links-list.component';
import { IconListItemComponent } from './icon-list/icon-list-item/icon-list-item.component';
import { IconListComponent } from './icon-list/icon-list.component';
import { ImageWrapperComponent } from './image-wrapper/image-wrapper.component';
import { InfoCardContainerComponent } from './info-card/info-card-container.component';
import { InformationBarComponent } from './information-bar/information-bar.component';
import { JumbotronContainerComponent } from './jumbotron/jumbotron-container.component';
import { listComponents } from './list';
import { ListContainerComponent } from './list/list-container.component';
import { MultipleLinksListContainerComponent } from './multiple-links-list/multiple-links-list-container/multiple-links-list-container.component';
import { MultipleLinksListComponent } from './multiple-links-list/multiple-links-list.component';
import { ReceiptBreakdownRowComponent } from './receipt/receipt-breakdown-row/receipt-breakdown-row.component';
import { ReceiptBreakdownDirective } from './receipt/receipt-breakdown.directive';
import { ReceiptConclusionDirective } from './receipt/receipt-conclusion.directive';
import { ReceiptComponent } from './receipt/receipt.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { StickyWrapperComponent } from './sticky-wrapper/sticky-wrapper.component';
import { TimerComponent } from './timer/timer.component';
import { TimerWrapperComponent } from './timer/timer-wrapper/timer-wrapper.component';
import { TimerWidgetComponent } from './timer/timer-widget/timer-widget.component';
import { TimerWidgetWrapperComponent } from './timer/timer-widget/timer-widget-wrapper/timer-widget-wrapper.component';
import { VideoContainerComponent } from './video/video-container/video-container.component';
import { VideoComponent } from './video/video.component';
import { VideoFallbackComponent } from './video/video-fallback/video-fallback.component';

const exportedComponents = [
  VideoContainerComponent,
  MultipleLinksListContainerComponent,
  HorizontalLinksListComponent,
  AccordionContainerComponent,
  RichTextComponent,
  EqualHeightComponent,
  AnchorComponent,
  CarouselComponent,
  InfoCardContainerComponent,
  BreadcrumbComponent,
  BreadcrumbWrapperComponent,
  JumbotronContainerComponent,
  BasicContentComponent,
  ListContainerComponent,
  FunctionalErrorComponent,
  ReceiptComponent,
  ReceiptBreakdownDirective,
  ReceiptConclusionDirective,
  ReceiptBreakdownRowComponent,
  StickyWrapperComponent,
  CardContentBlockComponent,
  TimerComponent,
  TimerWrapperComponent,
  TimerWidgetComponent,
  TimerWidgetWrapperComponent,
];

@NgModule({
  imports: [
    CommonModule,
    JssModule,
    GenericErrorModalModule,
    CommonUiUtilityComponentsModule,
    CommonUiPartialsModule,
    CoreExperienceEditorModule,
    CoreAnalyticsModule,
    NgbCollapseModule,
    NgbAccordionModule,
    FontAwesomeModule,
    UtilsModule,
    StructuredDataModule,
    CommonUiSharedModule,
    ComposablesModule,
    UtilsContentsquareModule,
    UtilsEnvironmentModule,
  ],
  declarations: [
    ...exportedComponents,
    ...basicContentComponents,
    ...listComponents,
    VideoComponent,
    MultipleLinksListComponent,
    IconListComponent,
    IconListItemComponent,
    ImageWrapperComponent,
    ListContainerComponent,
    AppButtonsComponent,
    BasicContentVariantMediaSectionComponent,
    BasicContentVariantMediaCardComponent,
    InformationBarComponent,
    VideoFallbackComponent,
  ],
  exports: [...exportedComponents, AppButtonsComponent],
})
export class CommonUiCommonRenderingsModule {}
