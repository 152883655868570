import { createSelector } from '@ngrx/store';
import { getSettingsValue } from '@innogy/core-settings';

import {
  selectElectricityDynamicPricesAvailabilityVM,
  selectIsDynamicPricesAvailabilityError,
  selectIsDynamicPricesAvailabilitySuccess,
} from './dynamic-prices-availability.selectors';
import type { DynamicPricesModel, SelectedHourInfo } from '../models';
import {
  selectGetDynamicPricesPayload,
  selectIsDynamicPricesError,
  selectIsDynamicPricesLoading,
  selectIsNewDynamicPricesEntitySuccess,
  selectSelectedDynamicPricesActionId,
  selectSelectedDynamicPricesEntry,
  selectSelectedEnergyType,
  selectSelectedHour,
} from './dynamic-prices.selectors';

export const selectSelectedHourInfo = createSelector(
  selectSelectedHour,
  selectSelectedDynamicPricesEntry,
  selectSelectedEnergyType,
  (hour, entry, selectedEnergyType): SelectedHourInfo => ({
    selectedHour: hour,
    selectedTariff: entry?.tariffs[hour],
    unitOfMeasurement: entry?.unitOfMeasurement,
    minTariff: entry?.minAmount,
    maxTariff: entry?.maxAmount,
    selectedEnergyType,
  })
);

export const selectIsInitialLoadSuccessfull = createSelector(
  selectIsNewDynamicPricesEntitySuccess,
  selectIsDynamicPricesAvailabilitySuccess,
  (pricesSuccess, availabilitySuccess) => pricesSuccess && availabilitySuccess
);

export const selectEnableDynamicTariffBreakdownFeatureFlagEnabled =
  getSettingsValue<boolean>('featureFlags', 'enableDynamicTariffBreakdown');

export const selectDynamicPricesVM = createSelector(
  selectSelectedDynamicPricesEntry,
  selectGetDynamicPricesPayload,
  selectElectricityDynamicPricesAvailabilityVM,
  selectIsDynamicPricesLoading,
  selectSelectedDynamicPricesActionId,
  selectIsDynamicPricesError,
  selectIsDynamicPricesAvailabilityError,
  selectEnableDynamicTariffBreakdownFeatureFlagEnabled,
  (
    entity,
    currentPayload,
    availability,
    isLoading,
    lastLoadedDate,
    isDateError,
    isAvailabilityError,
    showBreakdown
  ): DynamicPricesModel => ({
    entity,
    currentPayload,
    availability,
    isLoading,
    lastLoadedDate,
    isDateError,
    isAvailabilityError,
    showBreakdown,
  })
);
