import type { MultipleAnswerQuestion } from './multiple-answer-question.interface';
import type { SingleAnswerQuestion } from './single-answer-question.interface';

// Need to define some repeating consts to keep SonarQube happy
const singleAnswerQuestion = 'single-answer-question';
const typeGebouw = 'type-gebouw';
const weetIkNiet = 'Weet ik niet';
const orderForm = 'order-form';

/*
  This structure is used by the reducer and effects to navigate from question to question.
  Each answer has a 'target' that either refers to another question or a completely different webpage.
  The target mapping is implemented in the questionnaire effects.
 */
export const QuestionnaireStructure: {
  [key: string]: SingleAnswerQuestion | MultipleAnswerQuestion;
} = {
  huiseigenaar: {
    type: singleAnswerQuestion,
    question: 'Ben je eigenaar van de woning?',
    apiKey: 'home_ownership',
    options: [
      {
        label: 'Ja, ik ben eigenaar',
        target: typeGebouw,
        apiValue: 'yes',
      },
      {
        label:
          'Nee, ik huur de woning maar ik heb wel toestemming van de verhuurder',
        target: typeGebouw,
        apiValue: 'no',
      },
      {
        label:
          'Nee, ik huur de woning en ik heb geen toestemming van de verhuurder',
        target: '/ongeschikt/toestemming',
        apiValue: null,
      },
    ],
    percentage: 10,
  },
  'type-gebouw': {
    question: 'Wat voor type woning heb je?',
    type: singleAnswerQuestion,
    apiKey: 'building_type',
    options: [
      {
        label: 'Appartement',
        target: 'mechanische-ventilatie',
        apiValue: 'Appartement',
      },
      {
        label: 'Tussenwoning',
        target: 'mechanische-ventilatie',
        apiValue: 'Tussenwoning',
      },
      {
        label: 'Hoekwoning',
        target: 'bouwjaar',
        apiValue: 'Hoekwoning',
      },
      {
        label: 'Twee-onder-één-kap woning',
        target: 'bouwjaar',
        apiValue: 'Twee-onder-een-kap woning',
      },
      {
        label: 'Vrijstaande woning',
        target: 'bouwjaar',
        apiValue: 'Vrijstaande woning',
      },
    ],
    percentage: 30,
  },
  'mechanische-ventilatie': {
    type: singleAnswerQuestion,
    question: 'Heeft je woning mechanische ventilatie?',
    apiKey: 'vent_housebuilt',
    options: [
      {
        label: 'Ja, mijn woning heeft mechanische ventilatie',
        target: 'bouwjaar',
        apiValue: 'yes',
      },
      {
        label: 'Nee, mijn woning heeft geen mechanische ventilatie',
        target: '/ongeschikt/appartement',
        apiValue: null,
      },
    ],
    percentage: 40,
    info: {
      label: 'Uitleg over mechanische ventilatie',
      target: '/info/mechanische-ventilatie',
    },
  },
  bouwjaar: {
    type: singleAnswerQuestion,
    question: 'Wat is het bouwjaar van je woning?',
    apiKey: 'construction_year',
    options: [
      {
        label: 'Tot 1930',
        target: 'isolatie',
        apiValue: 'Tot 1930',
      },
      {
        label: '1931 - 1994',
        target: 'isolatie',
        apiValue: '1931 - 1994',
      },
      {
        label: 'Vanaf 1995',
        target: 'zonnepanelen',
        apiValue: 'Vanaf 1995',
      },
    ],
    percentage: 50,
  },
  isolatie: {
    type: 'multiple-answer-question',
    question: 'Over welke isolatiemaatregelen beschikt je woning?',
    subtitle: 'Meerdere opties zijn hier mogelijk',
    apiKey: 'installed_insulation',
    options: [
      {
        label: 'Spouwmuurisolatie',
        value: 1,
        apiValue: 'Spouwmuurisolatie',
      },
      {
        label: 'Vloerisolatie',
        value: 1,
        apiValue: 'Vloerisolatie',
      },
      {
        label: 'Dakisolatie',
        value: 1,
        apiValue: 'Dakisolatie',
      },
      {
        label: 'Dubbel/HR++ glas',
        value: 0,
        apiValue: 'Dubbel/HR++ glas',
      },
      {
        label: weetIkNiet,
        value: 2,
        apiValue: null,
      },
    ],
    valueNeeded: 2,
    goodAnswer: {
      target: 'zonnepanelen',
    },
    badAnswer: {
      target: '/ongeschikt/isolatie',
    },
    percentage: 55,
  },
  zonnepanelen: {
    type: singleAnswerQuestion,
    question: 'Heb je zonnepanelen?',
    apiKey: 'has_solar_panels',
    options: [
      {
        label: 'Ja, ik heb zonnepanelen',
        target: 'cv-ketel',
        apiValue: 'true',
      },
      {
        label: 'Nee, ik heb geen zonnepanelen',
        target: 'cv-ketel',
        apiValue: 'false',
      },
    ],
    percentage: 60,
  },
  'cv-ketel': {
    type: singleAnswerQuestion,
    question: 'Heb je een cv-ketel?',
    subtitle:
      'We leggen je graag uit waarom het van belang is dat je een cv-ketel hebt en wie onze service partners zijn.',
    apiKey: 'boiler_ownership',
    info: {
      label: 'Uitleg over cv-ketels en servicepartners',
      target: '/info/cv-ketels',
    },
    options: [
      {
        label: 'Ja, ik heb zelf een cv-ketel gekocht',
        target: 'leeftijd',
        apiValue: 'my_property',
      },
      {
        label: 'Ja, ik huur een cv-ketel van één van de servicepartners',
        target: 'leeftijd',
        apiValue: 'rented',
      },
      {
        label: 'Ja, ik huur een cv-ketel van iemand anders',
        target: '/info/cv-ketels',
        apiValue: null,
      },
      {
        label: 'Nee, ik heb geen cv-ketel',
        target: '/ongeschikt/cv-ketels',
        apiValue: null,
      },
      {
        label: weetIkNiet,
        target: 'leeftijd',
        apiValue: 'unknown',
      },
    ],
    percentage: 70,
  },
  leeftijd: {
    type: singleAnswerQuestion,
    question: 'Wat is de leeftijd van je cv-ketel?',
    apiKey: 'boiler_production_year',
    options: [
      {
        label: 'Jonger dan 12 jaar',
        target: 'verwarming',
        apiValue: 'Jonger dan 12 jaar',
      },
      {
        label: 'Ouder dan 12 jaar',
        target: 'verwarming',
        apiValue: 'Ouder dan 12 jaar',
      },
      {
        label: weetIkNiet,
        target: 'verwarming',
        apiValue: weetIkNiet,
      },
    ],
    percentage: 80,
  },
  verwarming: {
    type: singleAnswerQuestion,
    question: 'Hoe wordt je woning verwarmd?',
    subtitle:
      'Je woning kan op verschillende manieren verwarmd worden. We leggen het graag uit.',
    apiKey: 'heat_distribution_system',
    info: {
      label: 'Uitleg over verwarming',
      target: '/info/verwarming',
    },
    options: [
      {
        label: 'Vloer- of lage temperatuur verwarming',
        target: orderForm,
        apiValue: 'Lage temperatuur verwarming',
      },
      {
        label: 'Radiatoren en vloerverwarming',
        target: orderForm,
        apiValue: 'Radiatoren en lage temperatuur verwarming',
      },
      {
        label: 'Radiatoren',
        target: orderForm,
        apiValue: 'Radiator',
      },
      {
        label: 'Luchtverwarming',
        target: '/ongeschikt/verwarming',
        apiValue: null,
      },
      {
        label: 'Convectoren',
        target: '/ongeschikt/verwarming',
        apiValue: null,
      },
      {
        label: weetIkNiet,
        target: orderForm,
        apiValue: 'Onbekend',
      },
    ],
    percentage: 90,
  },
};
