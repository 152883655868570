import { Injectable } from '@angular/core';
import { getPaymentDetailsSuccess } from '@essent/financial';
import { prefillEmobilityPaymentDetailsStateAction } from '@innogy/emobility-shared';
import { ProgressiveFormService } from '@innogy/shared/progressive-form';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  initializeLeadForm,
  setEmobilityLeadFormType,
} from './lead-form.actions';
import {
  getPropositionType,
  isEmobilityChargeCardTypeEnabled,
} from './lead-form.selectors';
import { getLeadFormSteps, leadFormSelectorKey } from './lead-form.state';

@Injectable()
export class LeadFormEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly progressiveFormService: ProgressiveFormService,
    private readonly store$: Store<any>
  ) {}

  public readonly prefillPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentDetailsSuccess),
      mergeMap((action) => [
        prefillEmobilityPaymentDetailsStateAction({
          payload: {
            iban: action.payload.paymentDetails.iban,
          },
        }),
      ])
    )
  );

  public readonly setLeadFormSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeLeadForm),
      withLatestFrom(
        this.store$.select(getPropositionType).pipe(filter(isNotNullish)),
        this.store$.select(isEmobilityChargeCardTypeEnabled),
        (_, proposition, isChargeCardTypeEnabled) => ({
          proposition,
          isChargeCardTypeEnabled,
        })
      ),
      mergeMap(({ proposition, isChargeCardTypeEnabled }) => [
        setEmobilityLeadFormType({ proposition }),
        this.progressiveFormService.setFormConfigAction({
          progressOnValidSubmit: true,
          formId: leadFormSelectorKey,
          formSteps: getLeadFormSteps(proposition, isChargeCardTypeEnabled),
          scrollToInitialStep: false,
        }),
        this.progressiveFormService.initProgressiveForm({
          formId: leadFormSelectorKey,
        }),
      ])
    )
  );
}
