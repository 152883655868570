import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScLinkField } from '@innogy/jss-models';
import { EcmpHeader } from '@innogy/shared/channel-recognition';

@Component({
  selector: 'wl-minimal-header',
  template: '',
})
export class MinimalHeaderComponent {
  @Input() showHelp?: boolean;
  @Input() brandLogoLink?: ScLinkField;
  @Input() ecmpHeader?: EcmpHeader;
  @Output() helpRequested = new EventEmitter();
  @Output() logoClicked = new EventEmitter();

  onLogoClicked() {
    this.logoClicked.emit();
  }

  requestHelp() {
    this.helpRequested.emit();
  }
}
