import { CommonModule, DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import type { Routes, UrlSegment } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ConfigLoaderService } from '@innogy/core-config-angular';
import { NotFound, ServerError } from '@innogy/core-models';
import {
  JssRouteComponent,
  jssRouteMatcherFactory,
  JssRouteResolver,
} from '@innogy/jss-integration';
import { UtilsModule } from '@innogy/utils-deprecated';
import { PlatformService } from '@innogy/core-platform';
import { StoreModule } from '@ngrx/store';

import { NotFoundContainerComponent } from './not-found-container/not-found-container.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';

export function jssRouteMatcher(url: UrlSegment[]) {
  // DO NOT USE THE "GLOBAL_CONFIG" ANYWHERE ELSE. ITS MEANT ONLY FOR THIS SPECIFIC PART OF THE WHOLE APPLICATION.
  return jssRouteMatcherFactory(
    url,
    ConfigLoaderService.GLOBAL_CONFIG.sitecore || { apiKey: '' }
  );
}

export const routes: Routes = [
  { path: NotFound, component: NotFoundContainerComponent },
  { path: ServerError, component: ServerErrorComponent },
  {
    // matcher is effectively a catch-all route, see docs https://angular.io/api/router/UrlMatcher
    matcher: jssRouteMatcher,
    component: JssRouteComponent,
    resolve: {
      jssState: JssRouteResolver,
    },
    runGuardsAndResolvers: 'always',
  },
];

export function contentReadyEvent(
  document: Document,
  platformService: PlatformService
): () => void {
  return () => {
    if (platformService.isClient()) {
      // This event is needed for analytics (and for the cookie banner)
      document.dispatchEvent(new Event('ContentReady'));
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    /**
     * NOTE: 'anchorScrolling' in 'RouterModule' is not enabled, we handle this manually in <wl-setup-anchor-scrolling>
     *       This way we can:
     *         1. Wait for some Sitecore placeholders to load before starting to scroll
     *         2. Have different VERTICAL_SCROLL_OFFSET's per app
     *         3. Decide when to scroll immediately (initial page load) or when to animate the scrolling (internal navigation)
     **/
    RouterModule.forRoot(routes, {
      /**
       * DISCLAIMER for anyone in the future that wants to change the onSameUrlNavigation to 'reload'...
       *            in the past this broke the /kennisbank pages because they get stuck in an infinite redirect loop
       */
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled',
    }),
    StoreModule,
    UtilsModule,
  ],
  exports: [RouterModule, ServerErrorComponent, NotFoundContainerComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: contentReadyEvent,
      deps: [DOCUMENT, PlatformService],
      multi: true,
    },
  ],
  declarations: [
    ServerErrorComponent,
    NotFoundComponent,
    NotFoundContainerComponent,
  ],
})
export class CoreRoutingModule {}
