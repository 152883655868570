import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { snakeCase, toHttpParams } from '@essent/common';
import { API_PUBLIC_PREFIX } from '@innogy/core-config-angular';
import type { NonSerializable } from '@integration/base-models';
import type { GetModelContractQueryV2 } from '@integration/marketing-api-models';
import type { GetTariffsResponse } from '@integration/offerstore-api-models';
import type { Observable } from 'rxjs';

type GetModelContractPayload = NonSerializable<GetModelContractQueryV2>;

@Injectable()
export class GetMarketingModelContractService
  implements BaseService<GetModelContractPayload, GetTariffsResponse>
{
  public readonly apiVersion = '2';
  public readonly endpoint = `${this.apiUrl || ''}/marketing/model-contract/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: GetModelContractPayload
  ): Observable<GetTariffsResponse> {
    const headers = { Accept: 'application/json' };
    const params = toHttpParams(snakeCase(payload));

    return this.httpClient.get<GetTariffsResponse>(this.endpoint, {
      headers,
      params,
    });
  }
}
