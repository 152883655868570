import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import type { EnvConfigBrand } from '@innogy/core-config-models';
import { EnvironmentConfig } from '@innogy/core-config-models';

import { CommodityOfferAlignment } from '../commodity-offers.model';

@Component({
  selector: 'wl-commodity-offer-header',
  templateUrl: './commodity-offer-header.component.html',
  styleUrls: [
    './commodity-offer-header.component.ed.scss',
    './commodity-offer-header.component.essent.scss',
  ],
})
export class CommodityOfferHeaderComponent implements OnInit {
  brand?: EnvConfigBrand;

  @Input() headerTitle?: string;
  @Input() alignment!: CommodityOfferAlignment;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {}

  ngOnInit(): void {
    this.brand = this.envConfig.brand;
  }
}
