import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import {
  getMGMSharedValue,
  getYearlyCostsLabel,
} from '@innogy/become-a-customer/shared';
import { Store } from '@ngrx/store';
import { hasMemberGetMember } from '@innogy/become-a-customer/state';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

import { BaseOrderOverviewComponent } from '../base-order-overview/base-order-overview.component';

@Component({
  selector: 'ed-order-overview',
  templateUrl: './order-overview-ed.component.html',
  styleUrls: ['./order-overview-ed.component.scss'],
})
export class OrderOverviewEdComponent extends BaseOrderOverviewComponent {
  hasMemberGetMember$ = this.store$.select(hasMemberGetMember);
  @Input()
  memberGetMemberSharedValue = '';

  constructor(
    protected readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {
    super();
  }

  get fields(): ComponentFields | any {
    return this.rendering?.fields;
  }

  displayMGM(hasMGM: boolean): boolean {
    return hasMGM && !!this.mgmSharedValue;
  }

  get mgmSharedValue() {
    return getMGMSharedValue(this.offer, this.environmentConfig.brand);
  }

  get yearlyCostLabel() {
    const yearlyCostsTotalsWithIncentive =
      this.fields['CostsPerYearLabelWithIncentive']?.value ?? '';
    const yearlyCostsTotalsWithoutIncentive =
      this.fields['CostsPerYearLabel']?.value ?? '';
    const YearlyCostsTotalsMultiYearWithIncentive =
      this.fields['CostsMultiYearLabelWithIncentive']?.value ?? '';
    const YearlyCostsTotalsMultiYearWithoutIncentive =
      this.fields['CostsMultiYearLabel']?.value ?? '';

    return getYearlyCostsLabel(
      yearlyCostsTotalsWithIncentive,
      yearlyCostsTotalsWithoutIncentive,
      YearlyCostsTotalsMultiYearWithIncentive,
      YearlyCostsTotalsMultiYearWithoutIncentive,
      this.offer
    );
  }
}
