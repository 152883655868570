import type { Option, UsageQuestionnaireProps } from '@innogy/common-ui/sales';
import type { UsageQuestionnaire } from '@innogy/eplus/models';
import type { DropdownField } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';

function toOption(field: DropdownField): Option {
  return {
    label: '' + field?.fields?.label?.value,
    value: '' + field?.fields?.value?.value,
  };
}

export function mapUsageQuestionnaire(
  data?: UsageQuestionnaire
): UsageQuestionnaireProps {
  return {
    submitLabel: getFieldValue(data, 'SubmitLabel', ''),
    supplyTypeOptionsLabel: getFieldValue(data, 'supplyTypeOptionsLabel', ''),
    errorMessage: getFieldValue(data, 'errorMessage', ''),
    residentsOptions: data?.residentsOptions?.map(toOption) || [],
    subtitle: getFieldValue(data, 'Subtitle', ''),
    supplyTypeOptionsPlaceHolder: getFieldValue(
      data,
      'supplyTypeOptionsPlaceHolder',
      ''
    ),
    housingTypeOptions: data?.housingTypeOptions?.map(toOption) || [],
    residentsOptionsLabel: getFieldValue(data, 'residentsOptionsLabel', ''),
    supplyTypeOptions: data?.supplyTypeOptions?.map(toOption) || [],
    title: getFieldValue(data, 'Title', ''),
    housingTypeOptionsLabel: getFieldValue(data, 'housingTypeOptionsLabel', ''),
    residentsOptionsPlaceHolder: getFieldValue(
      data,
      'residentsOptionsPlaceHolder',
      ''
    ),
    housingTypeOptionsPlaceHolder: getFieldValue(
      data,
      'housingTypeOptionsPlaceHolder',
      ''
    ),
    componentName: getFieldValue(data, 'componentName', ''),
  };
}
