import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
  determineECMPHeader,
  determinePartnerId,
  extractPartnerIdFromConfig,
} from './channel-recognition-utils';
import type { GetPartnerIdQuery, PartnerIdConfig } from './interfaces';
import { Channel } from './interfaces';

@Injectable({ providedIn: 'root' })
export class ChannelRecognitionService {
  constructor(
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly env: EnvironmentConfig
  ) {}

  private _config$?: Observable<PartnerIdConfig>;

  get config$() {
    if (!this._config$) {
      this._config$ = this.requestConfig$().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
    return this._config$;
  }

  /**
   * checks the ecmp query, path and referrer against a set of conditions upon a true condition,
   * passes the returned flowId to a long-term storage and returns the value.
   */
  public getPartnerId$(
    payload: GetPartnerIdQuery
  ): Observable<string | undefined> {
    return this.config$.pipe(
      map((config) => determinePartnerId(payload, config))
    );
  }

  public getDefaultPartnerId$() {
    return this.config$.pipe(
      map((config) =>
        extractPartnerIdFromConfig(config, Channel.BrandWebsiteDefault)
      )
    );
  }

  public getECMPHeader$(ecmp: string) {
    return this.config$.pipe(
      map((config) => determineECMPHeader(config, ecmp))
    );
  }

  private requestConfig$() {
    return this.http.get<PartnerIdConfig>(this.assetLocation);
  }

  private get assetLocation() {
    return `dist/${this.env.app}/browser/assets/channel-recognition/channel-recognition-config.json`;
  }
}
