import { getBackLink } from '@innogy/jss-integration';
import { createSelector } from '@ngrx/store';

import type {
  ChangeCommunicationAddressWithInfo,
  ChangeContactPersonWithInfo,
  ChangePhoneNumberWithInfo,
} from '../customer-details.model';
import {
  getChangeCommunicationAddressFormState,
  getChangeCommunicationAddressTechnicalError,
  isFetchingAddressCheck,
} from './change-communication-address.selectors';
import {
  getChangeContactPersonFormState,
  getChangeContactPersonTechnicalError,
} from './change-contact-person.selectors';
import {
  getChangePhoneNumberFormState,
  getChangePhoneNumberTechnicalError,
} from './change-phone-number-form.selectors';
import {
  getCorrespondenceDetailsAndStates,
  getPrimaryPhoneNumber,
} from './communication-address.selectors';
import { getUpdateCommunicationAddressWithStates } from './update-communication-address.selectors';

export const getChangePhoneNumberWithInfo = createSelector(
  getUpdateCommunicationAddressWithStates,
  getChangePhoneNumberFormState,
  getChangePhoneNumberTechnicalError,
  getPrimaryPhoneNumber,
  getBackLink,
  (
    updatePhoneNumber,
    formState,
    technicalError,
    primaryPhoneNumber,
    backLink
  ): ChangePhoneNumberWithInfo => {
    return {
      updatePhoneNumber,
      formState,
      primaryPhoneNumber,
      technicalError,
      backLink,
    };
  }
);

export const getChangeCommunicationAddressWithInfo = createSelector(
  getUpdateCommunicationAddressWithStates,
  getChangeCommunicationAddressFormState,
  getCorrespondenceDetailsAndStates,
  getBackLink,
  getChangeCommunicationAddressTechnicalError,
  isFetchingAddressCheck,
  (
    status,
    formState,
    correspondenceDetails,
    backLink,
    technicalError,
    isFetchingAddressCheckResult
  ): ChangeCommunicationAddressWithInfo => {
    return {
      status,
      formState,
      correspondenceDetails: correspondenceDetails.correspondenceDetails,
      backLink,
      technicalError,
      isFetchingAddressCheck: isFetchingAddressCheckResult,
    };
  }
);

export const getChangeContactPersonWithInfo = createSelector(
  getUpdateCommunicationAddressWithStates,
  getChangeContactPersonFormState,
  getCorrespondenceDetailsAndStates,
  getBackLink,
  getChangeContactPersonTechnicalError,
  (
    status,
    formState,
    correspondenceDetails,
    backLink,
    technicalError
  ): ChangeContactPersonWithInfo => {
    return {
      status,
      formState,
      correspondenceDetails: correspondenceDetails.correspondenceDetails,
      backLink,
      technicalError,
    };
  }
);
