import { Component, Input } from '@angular/core';
import { getFieldValue } from '@innogy/jss-utils';
import { InnogyComponentRendering } from '@innogy/jss-models';
import type {
  ComponentRendering,
  LinkField,
  RenderingField,
} from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-quick-feedback',
  templateUrl: './quick-feedback.component.html',
  styleUrls: [
    './quick-feedback.component.scss',
    './quick-feedback.component.ed.scss',
    './quick-feedback.component.essent.scss',
  ],
})
export class QuickFeedbackComponent {
  hasAffirmativeFeedback = false;
  hasDenyingFeedback = false;
  @Input() settings!: InnogyComponentRendering;
  @Input() question?: string;

  public affirm() {
    this.hasAffirmativeFeedback = true;
  }

  public deny() {
    this.hasDenyingFeedback = true;
  }

  get title(): string {
    return getFieldValue(this.settings, 'Title', '');
  }

  get affirmativeText(): string {
    return getFieldValue(this.settings, 'AffirmativeText', '');
  }

  get denyingText(): string {
    return getFieldValue(this.settings, 'DenyingText', '');
  }

  get contactText() {
    return getFieldValue(this.settings, 'ContactText');
  }

  get affirmativeFeedbackText() {
    return getFieldValue(this.settings, 'AffirmativeFeedbackText');
  }

  get denyingFeedbackText() {
    return getFieldValue<RenderingField<string>>(
      this.settings,
      'DenyingFeedbackText'
    );
  }

  get affirmativeIcon() {
    return this.settings?.fields
      ?.AffirmativeIcon as unknown as ComponentRendering;
  }

  get denyingIcon() {
    return this.settings?.fields?.DenyingIcon as unknown as ComponentRendering;
  }

  get contactIcon() {
    return this.settings?.fields?.ContactIcon as unknown as ComponentRendering;
  }

  get contactUrl() {
    const link = getFieldValue(
      this.settings?.fields,
      'ContactUrl',
      undefined
    ) as LinkField | undefined;
    return link?.href;
  }
}
