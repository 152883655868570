import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { Store } from '@ngrx/store';
import type { FormState } from 'ngrx-forms';
import { MarkAsDirtyAction, MarkAsTouchedAction } from 'ngrx-forms';

import type { RadioItem } from './radio.model';

@Component({
  selector: 'wl-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss', './radio.component.essent.scss'],
})
export class RadioComponent {
  @Input()
  formsControl?: FormState<boolean | undefined> | FormState<string | undefined>;
  @Input()
  radioItems: RadioItem[] = [];
  @Input()
  inline = false;
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  fieldname?: string;
  @Input()
  stepname?: string;
  @Input()
  sendValue = true;
  @Input()
  hashValue = false;
  @Input()
  step?: number;
  @Input()
  label?: string;
  @Output() radioChange = new EventEmitter<RadioItem>();
  active?: RadioItem;

  @ViewChild('trackingWrapper')
  public trackingWrapperRef?: ElementRef<HTMLDivElement>;

  constructor(readonly store$: Store) {}

  public getId(radio: RadioItem, index: number) {
    return radio.id ?? `${this.formsControl?.id}${index}`;
  }

  public trackRadio(_index: number, item: RadioItem) {
    return item.id ?? item.value;
  }

  public onFocus() {
    if (this.formsControl) {
      this.dispatchNativeEvent('focus');
    }
  }

  public onChange(e: any) {
    if (this.formsControl) {
      this.touchControl(this.formsControl);
      this.active = this.radioItems.find(
        (item, index) => this.getId(item, index) === e.target.id
      );
      this.radioChange.emit(this.active);
      this.dispatchNativeEvent('change');
    }
  }

  private touchControl(
    control: FormState<boolean | undefined> | FormState<string | undefined>
  ) {
    this.store$.dispatch(new MarkAsTouchedAction(control.id));
    this.store$.dispatch(new MarkAsDirtyAction(control.id));
  }

  private dispatchNativeEvent(eventName: string) {
    this.trackingWrapperRef?.nativeElement.dispatchEvent(new Event(eventName));
  }

  get radioTrackingValue(): string {
    const radioItem = this.radioItems?.find(
      (r) => r.value === this.formsControl?.value
    );
    return radioItem?.trackingLabel ?? radioItem?.label ?? '';
  }

  get firstEnabledRadioIndex() {
    return this.radioItems.map((v) => v.disabled || false).indexOf(false);
  }
}
