import type { OnDestroy } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import type { EventTrack } from 'angulartics2';
import { Angulartics2 } from 'angulartics2';
import { Subject, Subscription } from 'rxjs';
import { bufferWhen, debounceTime } from 'rxjs/operators';

import { PageInfoService } from '../shared/page-info.service';
import type { ProductEventType } from './track-product.model';

@Injectable({
  providedIn: 'root',
})
export class TrackProductService implements OnDestroy {
  private readonly subscription = new Subscription();
  private readonly _impressions = new Subject<any>();
  private readonly impressions$ = this._impressions.asObservable();

  constructor(
    private readonly pageInfoService: PageInfoService,
    private readonly injector: Injector,
    private readonly angulartics: Angulartics2
  ) {
    this.handleImpressionEvents();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public trackImpression<TCommerce>(subject: TCommerce | TCommerce[]) {
    if (Array.isArray(subject)) {
      subject.forEach((entry) => {
        this._impressions.next(entry);
      });
    } else {
      this._impressions.next(subject);
    }
  }

  public trackSelection<TCommerce>(products: TCommerce[]) {
    this.track('product-click', products);
  }

  public trackDetail<TCommerce>(subject: TCommerce | TCommerce[]) {
    if (Array.isArray(subject)) {
      this.track('product-detail', subject);
    } else {
      // Datatracking convention is that detail always is an array, even with single items,
      // hence the cast to an array.
      this.track('product-detail', [subject]);
    }
  }

  private handleImpressionEvents() {
    this.subscription.add(
      this.impressions$
        .pipe(bufferWhen(() => this.impressions$.pipe(debounceTime(300))))
        .subscribe((products) => {
          this.track('product-impression', products);
        })
    );
  }

  public contextualizeListName(listName: string) {
    if (!listName) {
      return '';
    }

    const config = this.injector.get(ENVIRONMENT_CONFIG);
    const siteAbbreviation =
      this.pageInfoService.getSiteContextAbbreviation(config);
    return `${siteAbbreviation} ${listName.toLowerCase()}`;
  }

  private track(eventType: ProductEventType, products: any) {
    const event: EventTrack = {
      action: eventType,
      properties: {
        commerce: products,
      },
    };

    return this.angulartics.eventTrack.next(event);
  }
}
