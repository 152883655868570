import { Component } from '@angular/core';
import {
  getShowMinimalNavigation,
  getShowNavigation,
} from '@innogy/jss-integration';
import { Store } from '@ngrx/store';

@Component({
  selector: 'wl-essent-header',
  templateUrl: './essent-header.component.html',
  styleUrls: ['./essent-header.component.scss'],
})
export class EssentHeaderComponent {
  get hasMinimalNavigation$() {
    return this.store$.select(getShowMinimalNavigation);
  }

  get showNavigation$() {
    return this.store$.select(getShowNavigation);
  }

  constructor(private readonly store$: Store) {}
}
