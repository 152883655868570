<div class="modal-header">
  <a href="/" ngbAutofocus>
    <img
      [src]="'assets/logo.svg?v=2' | deployUrl"
      class="brand-logo w-100"
      alt="logo"
    />
  </a>
</div>
<div class="modal-body">
  <div>
    <h3>Cookievoorkeuren</h3>
    <p>
      {{ brandName }} gebruikt functionele, analytische en tracking cookies om
      je gebruikerservaring te verbeteren. Zo kunnen we je zo goed mogelijk
      helpen met al je energiezaken.
    </p>
    <h5>Wij plaatsen altijd functionele en analytische cookies</h5>
    <p>
      Deze cookies zorgen voor een goed werkende website en maken een anonieme
      analyse van gebruikersgedrag.
    </p>
    <h5>Na toestemming plaatsen wij tracking cookies</h5>
    <p>
      Deze cookies zorgen voor een persoonlijke en relevante gebruikerservaring
      op onze eigen website, andere websites en social media. Zo zie je voor jou
      gepersonaliseerde berichten, acties en aanbiedingen en vragen we je
      persoonlijke gebruikerservaring uit om onze online diensten te verbeteren.
    </p>
    <h5>Jij bepaalt wat je met ons wilt delen</h5>
    <p>
      Als je op ‘cookies accepteren’ klikt, ga je akkoord met alle cookies. Klik
      je op ‘cookies weigeren’, dan plaatsen we alleen de functionele en
      analytische cookies.
      <a href="/cookies"
        >Via cookie-instellingen kun je op ieder moment je keuze wijzigen of je
        toestemming intrekken</a
      >.
    </p>
    <p>
      Lees meer in onze <a href="/privacyverklaring">privacyverklaring</a> en
      <a href="/cookies">cookieverklaring.</a>
    </p>
  </div>
</div>
<div class="p-2 modal-footer d-flex justify-content-start">
  <div class="d-flex gap-2 flex-column flex-md-row flex-grow-1">
    <button
      wlAutomationId="cookiewall-accept"
      class="btn btn-primary"
      (click)="accept()"
    >
      Cookies accepteren
    </button>
    <button
      wlAutomationId="cookiewall-reject"
      class="btn btn-primary"
      (click)="reject()"
    >
      Cookies weigeren
    </button>
  </div>
</div>
