import type { FormGroupControls } from 'ngrx-forms';
import { BAC_COOLDOWN_PERIOD_IN_DAYS } from '@innogy/become-a-customer/shared/interfaces';
import { getDateOffset, verifyDateInRange } from '@innogy/utils-deprecated';
import type { EnvConfigSegment } from '@innogy/core-config-models';

import type { OrderAddressFormValues } from './order-address.model';

export const startDateRange = (startDateRestrictions?: {
  minimumDaysAheadStart: number;
  maximumDaysAheadEnd: number;
}) => {
  return {
    start: getDateOffset(startDateRestrictions?.minimumDaysAheadStart, 0, 0),
    end: getDateOffset(startDateRestrictions?.maximumDaysAheadEnd, 0, 0),
  };
};

// Only applicable to switchin (inswitch).
export const earliestPossibleStartDate = (
  segment: EnvConfigSegment = 'consument'
): Date => {
  const customerIsSME = segment === 'zakelijk';
  return getDateOffset((customerIsSME ? 0 : BAC_COOLDOWN_PERIOD_IN_DAYS) + 1);
};

export function getStartDateConfirmationRequired(
  formControls?: FormGroupControls<OrderAddressFormValues>
): boolean {
  const start = getDateOffset(0, 0, 0);
  const end = getDateOffset(BAC_COOLDOWN_PERIOD_IN_DAYS, 0, 0);
  const inswitchInhouseValue = formControls?.inswitchInhouse?.value?.value;
  const cooldownApplicable = inswitchInhouseValue?.cooldownApplicable;
  const selectedStartDateValue = formControls?.selectedStartDate?.value;

  return (
    cooldownApplicable === true &&
    !!selectedStartDateValue &&
    verifyDateInRange(selectedStartDateValue, start, end, true)
  );
}
