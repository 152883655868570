import type { ModuleWithProviders, Type } from '@angular/core';
import { DYNAMIC_COMPONENT, JssModule } from '@innogy/jss-proxy';

export interface ComponentMap {
  [key: string]: Type<any>;
}

export interface JssRouteData {
  text: string;
  href: string;
}

export function provideLazyLoad(
  componentMap: ComponentMap
): ModuleWithProviders<JssModule> {
  return {
    ngModule: JssModule,
    providers: [
      {
        provide: DYNAMIC_COMPONENT,
        useValue: componentMap,
      },
    ],
  };
}
