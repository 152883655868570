import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  dateConverter,
  DateInputFormatterPipe,
} from '@innogy/utils-deprecated';
import type { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { FocusAction, FormState } from 'ngrx-forms';

import {
  CustomAdapter,
  CustomDateParserFormatter,
} from './datepicker.adapters';
import { toDateString, toNgbDate } from './datepicker.helper';

@Component({
  selector: 'wl-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: [
    './datepicker.component.ed.scss',
    './datepicker.component.essent.scss',
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DatepickerComponent {
  @Input() labelFloating = true;
  @Input()
  autocomplete = 'off';
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input()
  set startValidDate(date: NgbDateStruct | Date | string) {
    if (date) {
      this.minDate = toNgbDate(date);
    }
  }
  @Input()
  set endValidDate(date: NgbDateStruct | Date | string) {
    if (date) {
      this.maxDate = toNgbDate(date);
    }
  }
  @Input()
  fieldname?: string;
  @Input()
  formName?: string;
  @Input()
  formsControl?: FormState<string>;
  @Input()
  rendering?: ComponentRendering;
  @Input()
  toolName?: string;
  @Input()
  stepname?: string;
  @Input()
  step?: number;
  @Input()
  automationId?: string;
  @Input()
  tooltip = '';
  @Input()
  skipDirtyCheck = false;
  @Input()
  navigation: 'select' | 'arrows' | 'none' = 'arrows';
  @ViewChild('dateInput')
  dateInput!: ElementRef<HTMLInputElement>;
  @ViewChild('datePicker')
  datePicker!: NgbInputDatepicker;
  @ViewChild('trackingWrapper')
  trackingWrapperRef!: ElementRef<HTMLDivElement>;

  public value = '';
  public dateValueConverter = dateConverter;
  public maxDate?: NgbDateStruct;
  public minDate?: NgbDateStruct;

  constructor(
    private readonly store$: Store<any>,
    private readonly formatDateInputPipe: DateInputFormatterPipe,
    private readonly ref: ChangeDetectorRef
  ) {}

  onValueChange(value: string | undefined) {
    const formattedValue = this.formatDateInputPipe.transform(value);
    /**
     * There could be a scenario where the user input's a character which is removed again during formatting.
     * This will cause the old value and new value to be the same, in this case the changeDetection assumes
     * there are no changes in the input and does not set the value. This will have the side effect of the input
     * still showing the added character, while the value is different.
     *
     * example scenario:
     *   userInput = "01-0a"
     *   value before & after last keystroke = "01-0" (the "a" is removed in formatting)
     *
     * The following check will "reset the value" and run the changeDetector so it can update the input
     * with the new value.
     */
    if (formattedValue === this.value) {
      this.value = '';
      this.ref.detectChanges();
    }
    this.value = formattedValue;
  }

  openDatepicker(datepicker: NgbInputDatepicker) {
    if (this.formsControl != null) {
      this.store$.dispatch(new FocusAction(this.formsControl.id));
    }
    datepicker.open();
    this.dispatchNativeEvent('focus');
    setTimeout(() => this.dateInput.nativeElement.focus());
  }

  private dispatchNativeEvent(eventName: string) {
    this.trackingWrapperRef.nativeElement.dispatchEvent(new Event(eventName));
  }

  selectDate(date: NgbDateStruct | undefined) {
    this.value = date ? toDateString(date) : '';
    this.dispatchNativeEvent('change');
  }
}
