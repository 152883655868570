import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

import type { FooterAccordion } from '../multiple-links-list.model';

@Component({
  selector: 'wl-multiple-links-list-container',
  templateUrl: './multiple-links-list-container.component.html',
})
export class MultipleLinksListContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  accordions?: FooterAccordion[];

  collapsible?: boolean;

  ngOnInit() {
    this.accordions = this.mapItemsToModel(this.rendering);
    this.collapsible = getFieldValue<boolean>(
      this.rendering,
      'collapsible',
      true
    );
  }

  mapItemsToModel(rendering?: InnogyComponentRendering): FooterAccordion[] {
    const items = (rendering?.fields?.items || []) as Item[];

    return items.map(
      (item) =>
        ({
          title: getFieldValue<string>(item.fields, 'title', ''),
          collapsed: getFieldValue<boolean>(item.fields, 'collapsed', true),
          links: ((item.fields.items || []) as Item[]).map((linkItem) => ({
            ...getFieldValue<LinkField>(linkItem.fields, 'link', {
              href: '',
            }),
            text: getFieldValue<string>(linkItem.fields, 'name', ''),
          })),
        } as FooterAccordion)
    );
  }
}
