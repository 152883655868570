import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@innogy/utils-dom';

import type { IAdvizeInterface, IAdvize } from '../+state';

declare global {
  interface Window {
    iAdvize: IAdvize;
    iAdvizeInterface: IAdvizeInterface;
  }
}

@Injectable({
  providedIn: 'root',
})
export class IAdvizeService {
  constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

  RETRY_WAIT_MS = 50;
  MAX_CHATBOT_STABLE_RESOLVED_MS = 30000;
  readonly IADVIZE_UNSTABLE_MESSAGE = `iAdvize did not initialize within ${this.MAX_CHATBOT_STABLE_RESOLVED_MS} ms, aborting stability check.`;

  /**
   * returns a promise that resolves once iAdvize is stable (one if it's internal methods is found).
   * Promise rejects if chatbot has not been marked as stable within the max check time.
   *
   */
  async ensureStable() {
    this.windowRef.iAdvizeInterface = this.windowRef.iAdvizeInterface || [];

    return new Promise((resolve, reject) => {
      let totalTimeElapsed = 0;
      let backoffTime = this.RETRY_WAIT_MS;
      let resolved = false;

      this.windowRef.iAdvizeInterface.push(() => {
        resolved = true;
        resolve(true);
      });

      const checkIfStable = () => {
        // Update total time elapsed
        totalTimeElapsed += backoffTime;
        if (resolved) {
          return void 0;
        } else if (totalTimeElapsed >= this.MAX_CHATBOT_STABLE_RESOLVED_MS) {
          reject(this.IADVIZE_UNSTABLE_MESSAGE);
        } else {
          // Increase backoff time gradually
          backoffTime = Math.min(
            backoffTime * 1.5,
            this.MAX_CHATBOT_STABLE_RESOLVED_MS - totalTimeElapsed
          );
          // Retry after the calculated backoff
          setTimeout(checkIfStable, backoffTime);
        }
      };

      checkIfStable();
    });
  }

  // Ignore this implementation detail for coverage.
  /* istanbul ignore next */
  iAdvizeNavigate(path: string) {
    this.windowRef.iAdvizeInterface.push((iAdvize) => {
      iAdvize.navigate(path);
    });
  }

  async scanCurrentPage() {
    // Make sure that iAdvize is enabled and loaded.
    await this.ensureStable();
    this.iAdvizeNavigate(this.windowRef.location.href);
  }
}
