import { Injectable } from '@angular/core';
import { getSitecoreContextWithStatus } from '@innogy/jss-integration';
import type { AbTestData } from '@innogy/jss-models';
import { Store } from '@ngrx/store';
import type { PageTrack } from 'angulartics2';
import type { Observable } from 'rxjs';
import { from, merge } from 'rxjs';
import {
  concatMap,
  debounceTime,
  delay,
  map,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import type { PageInfo } from './page-info.model';

type SideEffectPageTrack = {
  pageTrack: Partial<PageTrack>;
  pageInfo: PageInfo;
};

type PageTrackObservable = Observable<SideEffectPageTrack>;

@Injectable({
  providedIn: 'root',
})
export class TrackingSideEffectsService {
  pageTrackSideEffects() {
    const trackPageVisit = () =>
      function (source$: PageTrackObservable) {
        return source$.pipe(
          debounceTime(15000),
          map((page) => ({
            event: {
              action: 'page-visit-duration',
              properties: {
                seconds: 15,
              },
            },
            page,
          }))
        );
      };

    const trackQualityVisit = () =>
      function (source$: PageTrackObservable) {
        return source$.pipe(
          take(1),
          delay(4 * 60000),
          map((page) => ({
            event: {
              action: 'quality-visit',
              properties: {},
            },
            page,
          }))
        );
      };

    const trackABtests = () => (source$: PageTrackObservable) => {
      return source$.pipe(
        withLatestFrom(this.store$.pipe(getSitecoreContextWithStatus)),
        concatMap(([page, contextStatus]) => {
          const testData = contextStatus.context.abTestData ?? [];
          const data: [SideEffectPageTrack, AbTestData][] = testData.map(
            (test) => [page, test]
          );
          return from(data);
        }),
        map(([page, abTestData]) => ({
          event: {
            action: 'ab-test',
            properties: {
              testVariantName: abTestData.testVariantName,
              testDataSourceName: abTestData.testDataSourceName,
              testDataSourceId: abTestData.testDataSourceId,
              testId: abTestData.testId,
              testName: abTestData.testName,
              variant: `${abTestData.testName} | ${abTestData.testVariantName}`,
            },
          },
          page,
        }))
      );
    };

    return function (source$: PageTrackObservable) {
      const pageVisit$ = source$.pipe(trackPageVisit());
      const qualityVisit$ = source$.pipe(trackQualityVisit());
      const abTests$ = source$.pipe(trackABtests());

      return merge(pageVisit$, qualityVisit$, abTests$);
    };
  }

  constructor(private readonly store$: Store<any>) {}
}
