import { Component, HostBinding, Inject, Input } from '@angular/core';
import { VERTICAL_SCROLL_OFFSET } from '@innogy/core-config-angular';

@Component({
  selector: 'wl-sticky-wrapper',
  templateUrl: './sticky-wrapper.component.html',
})
export class StickyWrapperComponent {
  @HostBinding('class.sticky-top')
  @Input()
  sticky = true;

  @HostBinding('style')
  get style() {
    if (!this.sticky) {
      return {};
    }
    return {
      top: `${this.headerHeight + 20}px`,
      'z-index': 'auto',
    };
  }

  constructor(
    @Inject(VERTICAL_SCROLL_OFFSET) private readonly headerHeight: string
  ) {}
}
