<ng-container
  *ngIf="trackLastStep"
  [wlTrackStep]="{
    rendering: rendering,
    type: 'tool-laststep',
    step: 5,
    stepname: stepName,
    extraProperties: {
      result: {
        e2e_tracking_id: this.e2eTrackingId,
      }
    }
  }"
></ng-container>
<wl-partial-card
  class="card--fixed-overlay"
  [divider]="true"
  [wlAutomationId]="automationId"
  wlDisableBodyScrolling
>
  <ng-container slot="header">
    <wl-modal-page-header
      [title]="title"
      [href]="backLink"
      [navigationExtras]="extras"
    ></wl-modal-page-header>
  </ng-container>
  <ng-container slot="content">
    <div class="d-block w-100 text-center my-2">
      <fa-icon
        class="icon icon--checkcircle text-success"
        size="3x"
        icon="circle-check"
      ></fa-icon>
    </div>
    <h2 class="fw-bold text-center mb-2" *scText="successTitle"></h2>
    <p class="text-center mb-1">
      <span *scText="successMessage"></span>
      <ng-content></ng-content>
    </p>
    <p class="text-center sidenote mb-2" *scText="successSubMessage"></p>

    <div class="text-center">
      <a
        [wlTrackLink]="successLink?.value?.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: successLink?.value
        }"
        *wlGenericLink="successLink; extras: extras"
        class="btn btn-primary"
        wlAutomationId="success-page-close-button"
      ></a>
    </div>
  </ng-container>
</wl-partial-card>
