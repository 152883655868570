import { Component, inject } from '@angular/core';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';

import { CONSENT_REASON } from '../consent.service';

@Component({
  selector: 'wl-cookiewall-variant-b',
  standalone: true,
  imports: [UtilsEnvironmentModule],
  templateUrl: './cookiewall-variant-b.component.html',
  styleUrls: ['./cookiewall-variant-b.component.scss'],
})
export class CookiewallVariantBComponent {
  modal = inject(NgbActiveModal);
  readonly #brand = inject(ENVIRONMENT_CONFIG).app.replace('mijn-', '');

  brandName = this.#brand[0].toUpperCase() + this.#brand.slice(1);

  accept() {
    this.modal.close(CONSENT_REASON.ACCEPT);
  }

  reject() {
    this.modal.close(CONSENT_REASON.REJECT);
  }
}
