import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@innogy/core/analytics';
import type { InnogyComponentRendering } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';
import { initialsConverter } from '@innogy/utils-deprecated';
import { FormGroupControls, FormGroupState } from 'ngrx-forms';

import type { NameFormFields, NameFormValues } from './model/name-form.model';

@Component({
  selector: 'wl-name-form',
  templateUrl: './name-form.component.html',
})
export class NameFormComponent {
  @Input()
  trackStep!: TrackToolStep;

  @Input()
  rendering?: InnogyComponentRendering & { fields: NameFormFields };
  @Input()
  controls?: FormGroupControls<NameFormValues>;
  @Input()
  formState!: FormGroupState<NameFormValues>;
  @Input()
  floatingLabels = false;

  get initialsLabel() {
    return getFieldValue(this.rendering, 'InitialsLabel', '');
  }

  get initialsPlaceholder() {
    return getFieldValue(this.rendering, 'InitialsPlaceholder', '');
  }

  get initialsNotValidError() {
    return this.rendering?.fields.InitialsNotValidError;
  }

  get initialsRequiredError() {
    return this.rendering?.fields.InitialsRequiredError;
  }

  get insertionLabel() {
    return getFieldValue(this.rendering, 'InsertionLabel', '');
  }

  get insertionPlaceholder() {
    return getFieldValue(this.rendering, 'InsertionPlaceholder', '');
  }

  get lastNameLabel() {
    return getFieldValue(this.rendering, 'LastNameLabel', '');
  }

  get lastNamePlaceholder() {
    return getFieldValue(this.rendering, 'LastNamePlaceholder', '');
  }

  get lastNameRequiredError() {
    return this.rendering?.fields.LastNameRequiredError;
  }

  get lastNameInvalidError() {
    return this.rendering?.fields.LastNameNotValidError;
  }

  public readonly _initialsConverter = initialsConverter();

  get automationId(): string {
    return this.formState.id;
  }
}
