import { Injectable } from '@angular/core';
import type { InnogyComponentRendering } from '@innogy/jss-models';
import { BehaviorSubject } from 'rxjs';

import type { Slide, Topic } from './how-to-read-bill.model';
import {
  castRenderingToSlides,
  castRenderingToTopics,
} from './how-to-read-bill.model';

@Injectable()
export class HowToReadBillService {
  private readonly _slides$ = new BehaviorSubject<Slide[]>([]);
  private readonly _topics$ = new BehaviorSubject<Topic[]>([]);

  public topics$() {
    return this._topics$;
  }

  public slides$() {
    return this._slides$;
  }

  public connectRendering(rendering: InnogyComponentRendering | undefined) {
    if (rendering) {
      const topics = castRenderingToTopics(rendering);
      const slides = castRenderingToSlides(rendering);

      this._topics$.next(topics);
      this._slides$.next(slides);
    }
  }
}
