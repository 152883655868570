<!-- This ngSwitch is a temporary solution to simplify the Energiedirect
jumbotron without affecting Essent. When we migrate Essent to the new
component set, this switch will be disabled and all brands will use the new
variants currently used for ED only -->
<ng-container [ngSwitch]="brand">
  <ng-container *ngSwitchCase="'ed'">
    <wl-partial-jumbotron
      [heading]="content?.heading?.text?.value"
      [subheading]="content?.subheading?.text?.value"
      [showButtonsInHeading]="jumbotronSettings?.showButtonsInHeading"
      [backgroundSettings]="backgroundSettings"
      [theme]="jumbotronSettings?.theme || 'default'"
      [inlineHeadings]="jumbotronSettings?.inlineHeadings"
      [mobileTopImage]="jumbotronSettings?.showTopImageOnMobile"
    >
      <wl-partial-button
        slot="button-primary"
        *ngIf="content?.buttons?.primary?.link?.href"
        role="link"
        context="sales"
        theme="primary"
        [ngClass]="content.buttons.primary.link.class"
        [fullWidth]="isMobile$ | async"
        [href]="content.buttons.primary.link.href"
        [target]="content.buttons.primary.link.target || '_self'"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="content.buttons.primary.link.text"
        [trackImpressionLinkurl]="content.buttons.primary.link.href"
        [trackImpressionInfo]="{
          componentName: 'jumbotron'
        }"
        [wlTrackLink]="content.buttons.primary.link.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: content.buttons.primary.link.text,
          eventType: 'button-click'
        }"
      >
        {{ content.buttons.primary.link.text }}
      </wl-partial-button>
      <wl-partial-button
        slot="button-secondary"
        *ngIf="content?.buttons?.secondary?.link?.href"
        role="link"
        context="default"
        theme="secondary"
        [fullWidth]="isMobile$ | async"
        [ngClass]="content.buttons.secondary.link.class"
        [href]="content.buttons.secondary.link.href"
        [target]="content.buttons.secondary.link.target || '_self'"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="content.buttons.secondary.link.text"
        [trackImpressionLinkurl]="content.buttons.secondary.link.href"
        [trackImpressionInfo]="{
          componentName: 'jumbotron'
        }"
        [wlTrackLink]="content.buttons.secondary.link.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: content.buttons.secondary.link.text,
          eventType: 'button-click'
        }"
      >
        {{ content.buttons.secondary.link.text }}
      </wl-partial-button>
      <div slot="content-left" *ngIf="content?.text?.text?.value">
        <wl-rich-text-wrapper [field]="content.text.text">
          <div></div>
        </wl-rich-text-wrapper>
      </div>
      <sc-placeholder
        slot="content-right"
        name="jumbotron"
        [rendering]="rendering"
      ></sc-placeholder>
    </wl-partial-jumbotron>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div
      *ngIf="variant"
      class="d-flex justify-content-center position-relative jumbotron-inner"
      [wlBackground]="backgroundSettings"
      [ngClass]="'jumbotron-' + height"
      wlTrackImpression="contentblock-impression"
      [trackImpressionInfo]="rendering"
      [trackImpressionContext]="context"
    >
      <div class="container">
        <div class="row" [ngClass]="verticalPadding">
          <div
            class="col-12 py-md-8"
            [class.col-md-6]="
              variant === '6-6 with placeholder' ||
              variant === '6-6 without placeholder'
            "
            [class.col-md-4]="variant === '4-2-6'"
          >
            <wl-basic-content-variant-jumbotron
              [showMarketingHeader]="showMarketingHeader"
              [height]="height"
              [content]="content"
              [rendering]="rendering"
              [timerWidgetShown]="jumbotronSettings.timerWidgetShown"
            ></wl-basic-content-variant-jumbotron>
          </div>
          <div *ngIf="variant === '4-2-6'" class="col-12 col-md-2"></div>
          <sc-placeholder
            class="col-12 col-md-6"
            name="jumbotron"
            [rendering]="rendering"
            *ngIf="variant === '6-6 with placeholder' || variant === '4-2-6'"
          ></sc-placeholder>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
