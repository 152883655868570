import type { ScCheckboxField } from '@innogy/jss-models';
import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export interface BaseOfferFields {
  showMoreInfoBlock: ScCheckboxField;
  moreInfoTitle: TextField;
  moreInfoMessage: RichTextField;
  moreInfoButtonText: TextField;
}

export const mockBaseOfferFields: BaseOfferFields = {
  showMoreInfoBlock: { value: true },
  moreInfoTitle: { value: 'moreInfoTitle' },
  moreInfoMessage: { value: 'moreInfoMessage' },
  moreInfoButtonText: { value: 'moreInfoButtonText' },
};
