import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@innogy/core-ngrx';
import { Store } from '@ngrx/store';
import {
  initMarketingProfile,
  selectMarketingProfileIsLoading,
} from '@innogy/customer-profile/store';

@Injectable({ providedIn: 'root' })
export class GoToDynamicPricesGuard extends BaseGuard {
  constructor(
    protected readonly injector: Injector,
    protected readonly store$: Store
  ) {
    super(injector);
  }

  protected isStateLoading() {
    return this.store$.select(selectMarketingProfileIsLoading);
  }

  public getDispatchActions() {
    return [initMarketingProfile()];
  }
}
