import { NgModule } from '@angular/core';
import {
  MarketingHeadingComponent,
  MarketingHeadingModule,
} from '@innogy/marketing-heading';
import { provideLazyLoad } from '@innogy/jss-utils';

@NgModule({
  imports: [
    MarketingHeadingModule,
    provideLazyLoad({
      MarketingHeading: MarketingHeadingComponent,
    }),
  ],
})
export class MarketingHeadingLazyModule {}
