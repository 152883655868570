import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { snakeCase, toHttpParams } from '@essent/common';
import { API_PREFIX } from '@innogy/core-config-angular';
import type { Observable } from 'rxjs';

import type {
  SolarPanelsNewCustomerAddressCheckRequestQuery,
  SolarPanelsNewCustomerAddressCheckResponseV1,
} from '../../models';

@Injectable()
export class GetSolarPanelsAddressCheckService
  implements
    BaseService<
      SolarPanelsNewCustomerAddressCheckRequestQuery,
      SolarPanelsNewCustomerAddressCheckResponseV1
    >
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${
    this.apiUrl || ''
  }/eplus/solarpanels/new-customer/address-check/v${this.apiVersion}`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: SolarPanelsNewCustomerAddressCheckRequestQuery
  ): Observable<SolarPanelsNewCustomerAddressCheckResponseV1> {
    const headers = { Accept: 'application/json' };
    const params = toHttpParams(snakeCase(payload));

    return this.httpClient.get<SolarPanelsNewCustomerAddressCheckResponseV1>(
      this.endpoint,
      { headers, params }
    );
  }
}
