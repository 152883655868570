import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injector, type StateKey } from '@angular/core';
import { TransferState, Injectable, makeStateKey } from '@angular/core';
import type { FunnelSettings } from '@innogy/become-a-customer/shared/interfaces';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvConfigSegment } from '@innogy/core-config-models';
import type { SitecoreItemByIdResponse } from '@innogy/jss-graphql';
import {
  sitecoreFieldsAsObject,
  sitecoreItemByIdQuery,
} from '@innogy/jss-graphql';
import isEmpty from 'lodash/isEmpty';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const funnelSettingsKey = makeStateKey<FunnelSettings>('funnel-settings');
@Injectable({
  providedIn: 'root',
})
export class FunnelSettingsService {
  constructor(
    private readonly transferState: TransferState,
    private readonly httpClient: HttpClient,
    private readonly injector: Injector
  ) {}

  getByGuid(funnelSettingsGUID: string): Observable<FunnelSettings> {
    const config = this.injector.get(ENVIRONMENT_CONFIG);
    const isSme = config.segment === EnvConfigSegment.Zakelijk;
    const transferState = this.getTransferState(funnelSettingsKey);

    if (transferState) {
      return of(transferState);
    }

    return this.httpClient
      .post<SitecoreItemByIdResponse>(
        `${isSme ? '/zakelijk' : ''}/graphql`,
        JSON.stringify({
          query: sitecoreItemByIdQuery,
          variables: { templateId: funnelSettingsGUID },
        }),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      )
      .pipe(
        map((res) => {
          if (!res.data.item || isEmpty(res.data.item)) {
            throw new Error(
              `FunnelSettings with GUID ${funnelSettingsGUID} could not be found`
            );
          }

          return {
            ...sitecoreFieldsAsObject<FunnelSettings>(res.data.item.fields),
            id: res.data.item.id,
          };
        }),
        tap((settings) => this.transferState.set(funnelSettingsKey, settings))
      );
  }

  private getTransferState<T>(key: StateKey<T>): T | null {
    if (this.transferState.hasKey(key)) {
      const value = this.transferState.get(key, {} as any);
      this.transferState.remove(key);

      return value;
    }

    return null;
  }
}
