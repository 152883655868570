import { createSelector } from '@ngrx/store';

import { eplusHeatpumpsFeature } from '../heatpumps.state';
import { heatpumpsQuestionnaireKey } from './heatpumps-questionnaire.reducer';

export const selectHeatpumpsQuestionnaireState = createSelector(
  eplusHeatpumpsFeature,
  (state) => state[heatpumpsQuestionnaireKey]
);

export const selectHeatpumpsCurrentQuestion = createSelector(
  selectHeatpumpsQuestionnaireState,
  (state) => state.currentQuestion
);

export const selectHeatpumpsAnswers = createSelector(
  selectHeatpumpsQuestionnaireState,
  (state) => state.submittedAnswers
);

export const selectHeatpumpsHasPreviousQuestion = createSelector(
  selectHeatpumpsQuestionnaireState,
  (state) => state.history.length > 1
);
