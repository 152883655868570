import { Inject, Injectable } from '@angular/core';
import { isEditorActive } from '@innogy/jss-proxy';

import { AppConfig } from './app-config';
import { CONFIGMODULE_CONFIG } from './injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(
    @Inject(CONFIGMODULE_CONFIG) private readonly appConfig: AppConfig
  ) {}

  public basePath = !isEditorActive() ? this.appConfig.basePath : '';
  public appName = this.appConfig.appName;
}
