<div class="d-flex flex-column gap-4">
  <wl-commodity-offer-filter
    *ngIf="
      (isEnergiedirectConsumerRetention || isEssentConsumentRetention) &&
      availableContractTypes.length > 1
    "
    class="d-block w-100"
    [class.mb-2]="isEnergiedirectConsumerRetention"
    [offers]="offers"
    [selectedContractType]="selectedContractType"
    [availableContractTypes]="availableContractTypes"
    (changeContractType)="changeContractType($event)"
  ></wl-commodity-offer-filter>

  <div
    class="d-flex offer-list justify-content-center"
    [class.offer-list--vertical]="computedVariant === 'accordion'"
    [class.offer-list--has-floating-label]="listContainsHighlights"
    [class.mt-xl-6]="
      isEnergiedirect && alignment === 'horizontal' && listContainsHighlights
    "
  >
    <ng-container *ngIf="isEssentConsumerOpen">
      <ng-container
        *ngIf="
          offers && offers.length > 0;
          else loadingEssentConsumerAcquisition
        "
      >
        <essent-consumer-acquisition-commodity-offer
          *ngFor="let offer of filteredOffers"
          [offer]="offer"
          [variant]="computedVariant"
          [content]="content"
          [active]="isActive(offer)"
          [showMemberGetMember]="showMemberGetMember"
          [ensureHorizontalAlignment]="listContainsHighlights"
          (selectOffer)="selectOffer.emit(offer)"
          (openMoreInfo)="openMoreInfo.emit(offer)"
          (viewTariffs)="viewTariffs.emit(offer)"
        ></essent-consumer-acquisition-commodity-offer>
      </ng-container>
      <ng-template #loadingEssentConsumerAcquisition>
        <essent-consumer-acquisition-commodity-offer
          *ngFor="let _ of [1, 2, 3]"
          [showLoadingState]="true"
          [variant]="computedVariant"
          [active]="false"
        ></essent-consumer-acquisition-commodity-offer>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="isEssentSMEOpen">
      <ng-container
        *ngIf="offers && offers.length > 0; else loadingEssentSMEAcquisition"
      >
        <essent-sme-acquisition-commodity-offer
          *ngFor="let offer of filteredOffers"
          [offer]="offer"
          [variant]="computedVariant"
          [content]="content"
          [active]="isActive(offer)"
          [showMemberGetMember]="showMemberGetMember"
          [ensureHorizontalAlignment]="listContainsHighlights"
          (selectOffer)="selectOffer.emit(offer)"
          (openMoreInfo)="openMoreInfo.emit(offer)"
          (viewTariffs)="viewTariffs.emit(offer)"
        ></essent-sme-acquisition-commodity-offer>
      </ng-container>
      <ng-template #loadingEssentSMEAcquisition>
        <essent-sme-acquisition-commodity-offer
          *ngFor="let _ of [1, 2, 3]"
          [showLoadingState]="true"
          [variant]="computedVariant"
          [active]="false"
        ></essent-sme-acquisition-commodity-offer>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isEssentConsumentRetention">
      <ng-container *ngIf="!content?.useMinimalOfferCards">
        <essent-consumer-retention-commodity-offer
          *ngFor="let offer of filteredOffers"
          [offer]="offer"
          [variant]="computedVariant"
          [content]="content"
          [active]="isActive(offer)"
          [ensureHorizontalAlignment]="listContainsHighlights"
          (selectOffer)="selectOffer.emit(offer)"
          (viewTariffs)="viewTariffs.emit(offer)"
        >
        </essent-consumer-retention-commodity-offer>
      </ng-container>

      <ng-container *ngIf="content?.useMinimalOfferCards === true">
        <essent-consumer-retention-minimal-commodity-offer
          *ngFor="let offer of filteredOffers"
          [offer]="offer"
          [variant]="computedVariant"
          [content]="content"
          [active]="isActive(offer)"
          [ensureHorizontalAlignment]="
            listContainsHighlights || listContainsIncentives
          "
          (selectOffer)="selectOffer.emit(offer)"
          (viewTariffs)="viewTariffs.emit(offer)"
          (openMoreInfo)="openMoreInfo.emit(offer)"
        >
        </essent-consumer-retention-minimal-commodity-offer>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isEnergiedirectConsumerOpen">
      <ng-container
        *ngIf="offers && offers.length > 0; else loadingEdConsumerAcquisition"
      >
        <ed-consumer-acquisition-commodity-offer
          *ngFor="let offer of filteredOffers"
          [offer]="offer"
          [variant]="computedVariant"
          [content]="content"
          [active]="isActive(offer)"
          [showMemberGetMember]="showMemberGetMember"
          [ensureHorizontalAlignment]="listContainsHighlights"
          [showTariffs]="showTariffsForAllOffers"
          (selectOffer)="selectOffer.emit(offer)"
          (openMoreInfo)="openMoreInfo.emit(offer)"
          (viewTariffModal)="viewTariffs.emit(offer)"
          (openTariffDropdown)="_showTariffsForAllOffers = true"
          (closeTariffDropdown)="_showTariffsForAllOffers = false"
        ></ed-consumer-acquisition-commodity-offer>
      </ng-container>
      <ng-template #loadingEdConsumerAcquisition>
        <ed-consumer-acquisition-commodity-offer
          *ngFor="let _ of [1, 2, 3]"
          [showLoadingState]="true"
          [variant]="computedVariant"
          [active]="false"
        ></ed-consumer-acquisition-commodity-offer>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isEnergiedirectConsumerRetention">
      <ng-container
        *ngIf="offers && offers.length > 0; else loadingEdConsumerRetention"
      >
        <ng-container *ngIf="!content?.useMinimalOfferCards">
          <ed-consumer-retention-commodity-offer
            *ngFor="let offer of filteredOffers"
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [active]="isActive(offer)"
            [showTariffs]="showTariffsForAllOffers"
            (selectOffer)="selectOffer.emit(offer)"
            (openMoreInfo)="openMoreInfo.emit(offer)"
            (viewTariffModal)="viewTariffs.emit(offer)"
            (openTariffDropdown)="_showTariffsForAllOffers = true"
            (closeTariffDropdown)="_showTariffsForAllOffers = false"
          ></ed-consumer-retention-commodity-offer>
        </ng-container>
        <ng-container *ngIf="content?.useMinimalOfferCards">
          <ed-consumer-retention-minimal-commodity-offer
            *ngFor="let offer of filteredOffers"
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [active]="isActive(offer)"
            [ensureHorizontalAlignment]="
              listContainsHighlights || listContainsIncentives
            "
            (selectOffer)="selectOffer.emit(offer)"
            (viewTariffs)="viewTariffs.emit(offer)"
            (openMoreInfo)="openMoreInfo.emit(offer)"
          >
          </ed-consumer-retention-minimal-commodity-offer>
        </ng-container>
      </ng-container>
      <ng-template #loadingEdConsumerRetention>
        <ed-consumer-retention-commodity-offer
          *ngFor="let _ of [1, 2, 3]"
          [showLoadingState]="true"
          [variant]="computedVariant"
          [active]="false"
        ></ed-consumer-retention-commodity-offer>
      </ng-template>
    </ng-container>
  </div>
</div>
