import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileInputEffects } from '@innogy/common-ui/forms';
import { EmobilityLeadServicesModule } from '@innogy/emobility-lead/services';
import { ProgressiveFormModule } from '@innogy/shared/progressive-form';
import { EffectsModule } from '@ngrx/effects';
import { combineReducers, StoreModule } from '@ngrx/store';
import { provideBootstrapEffects } from '@innogy/utils-state';

import {
  addressEffects,
  addressProviders,
} from './installation-address-form.effects';
import { LeadFormSubmitEffects } from './lead-form/lead-form-submit.effects';
import { LeadFormProspectEffects } from './lead-form/lead-form-prospect.effects';

import { LeadFormEffects } from './lead-form/lead-form.effects';
import {
  leadFormReducers,
  leadFormSelectorKey,
} from './lead-form/lead-form.state';
@NgModule({
  imports: [
    CommonModule,
    EmobilityLeadServicesModule,
    StoreModule.forFeature(
      leadFormSelectorKey,
      combineReducers(leadFormReducers)
    ),
    EffectsModule.forFeature([
      ...addressEffects,
      LeadFormEffects,
      LeadFormProspectEffects,
      FileInputEffects,
    ]),
    ProgressiveFormModule,
  ],
  providers: [
    ...addressProviders,
    provideBootstrapEffects([LeadFormSubmitEffects]),
  ],
})
export class EmobilityLeadStoreModule {}
