import { Component, Input } from '@angular/core';
import { getFunnelSettings } from '@innogy/become-a-customer/state';
import { InnogyComponentRendering } from '@innogy/jss-models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
  selector: 'wl-calculate-modal',
  templateUrl: './calculate-modal.component.html',
  styleUrls: [],
})
export class CalculateModalComponent {
  // The expected rendering of this component is the rendering of the offer-card
  @Input() rendering?: InnogyComponentRendering;

  readonly calculateContent =
    this.store$.selectSignal(getFunnelSettings)().changeCalculationComponent;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly store$: Store<any>
  ) {}

  dismissModal() {
    this.modal.dismiss('dismiss');
  }
}
