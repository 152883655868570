import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { SupplyAddress } from '@essent/new-customer';
import { ProductDuration } from '@essent/new-customer';
import type {
  ConsumptionValuesVM,
  FunnelSettingsAddOn,
  GetOfferDuration,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import {
  getFunnelSettings,
  loadOffersetAction,
  readyToLoadOffers,
  setBACFunnelStepAction,
  showOfferAction,
} from '@innogy/become-a-customer/state';
import { getIsExperienceEditorActive } from '@innogy/jss-integration';
import type { InnogyComponentRendering } from '@innogy/jss-models';
import { getFieldValue } from '@innogy/jss-utils';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
  getMockOffer,
  mockConsumptionValues,
  mockSupplyAddress,
} from './base-offer.mock';

@Component({
  selector: 'wl-base-offer-container',
  template: '',
})
export class BaseOfferContainerComponent implements OnInit, OnDestroy {
  @Input()
  public rendering?: InnogyComponentRendering | any;

  readyToLoadOffers$ = this.store$.select(readyToLoadOffers);
  /**
   * This observable is also used in the HorizontalOfferContainerComponent and VerticalOfferContainerComponent
   */
  xpEditorActive$ = this.store$.select(getIsExperienceEditorActive);
  funnelSettings$ = this.store$.select(getFunnelSettings);

  readonly onDestroy$ = new Subject();

  constructor(protected readonly store$: Store<any>) {}

  ngOnInit() {
    this.dispatchCommonOfferLoadingSequence();
    this.initializeStep();
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  initializeStep() {
    this.dispatchSetFunnelStep();
  }

  /**
   * @returns mocked offer data, used when loading this component via the XP editor in Sitecore
   */
  get xpMock(): {
    addOn: FunnelSettingsAddOn;
    activeDuration: ProductDuration;
    retrievedDurations: ProductDuration[];
    offerSet: {
      offerset: string;
      offers: Offer[];
      durations: {
        present: GetOfferDuration[];
        retrieved: ProductDuration[];
      };
      customer: boolean;
    };
    offers: Offer[];
    durations: GetOfferDuration[];
    offer: Offer;
    consumptionValues: ConsumptionValuesVM;
    supplyAddress: SupplyAddress;
  } {
    const offers = [
      getMockOffer({ offerId: 'goat' }),
      getMockOffer({ isHighlighted: true }),
      getMockOffer(),
    ];
    const durations = [
      {
        duration: ProductDuration.ONE,
        durationTitle: '1 jaar',
      },
      {
        duration: ProductDuration.TWO,
        durationTitle: '2 jaar',
      },
      {
        duration: ProductDuration.FLEX,
        durationTitle: 'Flex',
      },
    ];
    const retrievedDurations = [
      ProductDuration.ONE,
      ProductDuration.TWO,
      ProductDuration.FLEX,
    ];

    return {
      addOn: {
        title: 'Thuisvoordeel',
        description: 'Meer voordeel als vaste klant',
        offerSet: 'goat-2',
        tooltip: '',
      },
      activeDuration: ProductDuration.FLEX,
      retrievedDurations,
      offerSet: {
        offerset: 'goat',
        offers,
        durations: {
          present: durations,
          retrieved: retrievedDurations,
        },
        customer: true,
      },
      offers,
      durations,
      offer: getMockOffer({ isHighlighted: true }),
      consumptionValues: mockConsumptionValues,
      supplyAddress: mockSupplyAddress,
    };
  }

  private dispatchCommonOfferLoadingSequence() {
    this.store$.dispatch(showOfferAction());
    this.readyToLoadOffers$
      .pipe(
        filter((status) => !!status),
        withLatestFrom(this.regularOfferset$, this.alternativeOfferset$),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([, regularOfferset, alternativeOfferset]) => {
        this.store$.dispatch(
          loadOffersetAction({
            offerset: regularOfferset,
            setAsActive: true,
          })
        );

        if (alternativeOfferset && this.showProductAddon) {
          this.store$.dispatch(
            loadOffersetAction({
              offerset: alternativeOfferset,
            })
          );
        }
      });
  }

  private dispatchSetFunnelStep() {
    this.store$.dispatch(setBACFunnelStepAction({ step: 'Offer' }));
  }

  get fields() {
    return this.rendering?.fields;
  }

  get regularOfferset$() {
    return this.funnelSettings$.pipe(map((settings) => settings.offerset));
  }

  get alternativeOfferset$() {
    return this.funnelSettings$.pipe(
      map((settings) => settings?.addOn?.offerSet)
    );
  }

  get showProductAddon(): boolean {
    return getFieldValue<boolean>(this.rendering, 'ShowProductAddons', false);
  }
}
