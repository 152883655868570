import { Component, Input } from '@angular/core';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  type CalculateComponentRendering,
  CalculateComponentContent,
} from '@innogy/become-a-customer/shared/interfaces';
import type { TypedComponentRendering } from '@innogy/jss-models';
import { FormGroupControls, FormGroupState } from 'ngrx-forms';
import { JssPlatformService } from '@innogy/jss-platform';

@Component({
  selector: 'wl-calculate-form-solar',
  templateUrl: './calculate-form-solar.component.html',
  styleUrls: [
    './calculate-form-solar.component.ed.scss',
    './calculate-form-solar.component.essent.scss',
  ],
})
export class CalculateFormSolarComponent {
  @Input()
  rendering?: TypedComponentRendering<CalculateComponentRendering>;
  @Input({ required: true })
  content!: CalculateComponentContent;
  @Input()
  controls?: FormGroupControls<FullCalculateFormValues>;
  @Input()
  formState?: FormGroupState<FullCalculateFormValues>;

  constructor(private readonly jssPlatformservice: JssPlatformService) {}

  get isXpEditorActive() {
    return this.jssPlatformservice.isEditorActive();
  }
}
