<wl-partial-media-card
  [grow]="cardConfig?.stretchVertical"
  [shadow]="cardConfig?.shadow"
  [theme]="settings?.theme"
>
  <wl-partial-image
    *ngIf="displayImage(settings?.variant, image)"
    [src]="image?.image?.src"
    [alt]="image?.image?.alt"
    [ratio]="'4/3'"
    [objectPosition]="image?.position"
    [objectFit]="image?.objectFit"
  >
  </wl-partial-image>
  <wl-partial-structured-content
    [class.p-md-4]="context?.colWidth >= 6"
    [class.p-md-3]="context?.colWidth === 4"
    [class.p-2]="true"
    [layoutButtonsInline]="context?.colWidth > 6"
  >
    <fa-icon
      slot="icon"
      *ngIf="settings?.icon?.name"
      [icon]="settings?.icon?.name"
      [class.icon-lg-md]="context?.colWidth >= 6"
      [class.icon-md]="true"
    >
    </fa-icon>
    <ng-container slot="heading" *ngIf="content?.heading?.text?.value">
      <h3
        class="mb-0"
        [class.h2-md]="context?.colWidth >= 6"
        [class.h3-md]="context?.colWidth === 4"
        [class.h4]="true"
        *scText="content.heading.text"
      ></h3>
    </ng-container>
    <ng-container slot="subheading" *ngIf="content?.subheading?.text?.value">
      <h4 class="intro" *scText="content.subheading.text"></h4>
    </ng-container>
    <ng-container slot="paragraph" *ngIf="content?.text?.text?.value">
      <ng-container *wlGenericRichText="content.text.text">
        <p class="mb-0"></p>
      </ng-container>
    </ng-container>
    <ng-container
      slot="button-primary"
      *ngIf="content?.buttons?.primary?.link?.href"
    >
      <wl-partial-button
        role="link"
        [theme]="primaryButtonTheme"
        [fullWidth]="true"
        [context]="settings.context"
        [ngClass]="content.buttons.primary.link.class"
        [href]="content.buttons.primary.link.href"
        [target]="content.buttons.primary.link.target || '_self'"
        [wlTrackLink]="content.buttons.primary.link.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: content.buttons.primary.link.text,
          eventType: 'button-click'
        }"
      >
        {{ content.buttons.primary.link.text }}
      </wl-partial-button>
    </ng-container>
    <ng-container
      slot="button-secondary"
      *ngIf="content?.buttons?.secondary?.link?.href"
    >
      <wl-partial-button
        role="link"
        theme="tertiary"
        size="small"
        [fullWidth]="true"
        [ngClass]="content.buttons.secondary.link.class"
        [href]="content.buttons.secondary.link.href"
        [target]="content.buttons.secondary.link.target || '_self'"
        [wlTrackLink]="content.buttons.secondary.link.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: content.buttons.secondary.link.text,
          eventType: 'button-click'
        }"
      >
        {{ content.buttons.secondary.link.text }}
      </wl-partial-button>
    </ng-container>
  </wl-partial-structured-content>
</wl-partial-media-card>
