import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ROOT_PLACEHOLDER_KEY } from '@innogy/core-config-angular';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { EMPTY as empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import type { State } from '../+state';
import {
  JssStateFatalError,
  JssStatePlaceholderLoaded,
} from '../+state/jss-route.actions';
import { getRenderingData } from '../+state/jss.selectors';

@Component({
  selector: 'wl-jss-route',
  templateUrl: './jss-route.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JssRouteComponent {
  // TODO fix typing
  rendering$: Observable<any> = this.store$.pipe(
    getRenderingData,
    catchError((error: unknown) => {
      this.store$.dispatch(
        new JssStateFatalError({ route: 'ServerError', error: error as Error })
      );
      return empty;
    })
  );

  triggerLoaded(placeholderName?: string) {
    this.store$.dispatch(new JssStatePlaceholderLoaded(placeholderName));
  }

  constructor(
    private readonly store$: Store<State>,
    @Inject(ROOT_PLACEHOLDER_KEY) public rootPlaceholderKey: string
  ) {}
}
