import type { InnogyComponentRendering } from '@innogy/jss-models';
import { getDroplinkValue, getFieldValue } from '@innogy/jss-utils';

export interface ComposableCardConfig {
  backgroundColorClass: string;
  borderClass: string;
  borderRoundingClass?: string;
  stretchVertical: boolean;
  shadow: boolean;
}

export const getComposableCardConfigFromRendering = (
  rendering: InnogyComponentRendering
): ComposableCardConfig => ({
  backgroundColorClass: getDroplinkValue(
    rendering.fields,
    'BackgroundColor',
    ''
  ),
  borderClass: getDroplinkValue(rendering.fields, 'BorderStyle', ''),
  stretchVertical: getFieldValue(rendering.fields, 'StretchVertical', false),
  shadow: getDroplinkValue(rendering.fields, 'BorderStyle', '').includes(
    'shadow'
  ),
});
